<template>
  <v-container fluid style="background: #F4F4F4">
    <v-layout>
      <v-flex lg2></v-flex>
      <v-flex xs12 lg8 class=" pa-0">

        <v-card
            v-if="this.$store.state.country !== 'Singapore'"
            class="ma-xs-0 ma-lg-8 pa-lg-12 py-6 py-sm-10 content-item" elevation="0"
            justify="start">
          <v-card-title class="pb-8 font-weight-bold text-h5 black--text">Declaration & Authorisation</v-card-title>
          <v-card-subtitle class="text-xs-center py-2 declaration-item">I declare that to the best of my knowledge and
            belief the above statements and particulars contained are in all respects true and complete and are made
            without
            reservation of any kind. I hereby authorize any physician, medical practitioner, hospital or clinic by whom
            or where I have been observed or treated to give full particulars about my health to Chubb Insurance Hong
            Kong Limited. A photocopy of this authorisation shall be considered as effective and valid as the original.
          </v-card-subtitle>
          <v-card-subtitle class="text-xs-center py-2  declaration-item">
            I further hereby declare and agree, that the personal information collected or held by Chubb Insurance Hong
            Kong Limited, whether contained in this claim
            form or otherwise obtained, may be used by Chubb Insurance Hong Kong Limited or disclosed to any individual
            or organization such as legal firms, accountants,
            actuaries, loss adjudicators and claims investigators, doctors and other medical service provider within or
            outside Hong Kong and as more particularly set out in
            the Chubb Privacy Information Collection Statement for the following purposes: (1) to assess and process
            this application, (2) to provide insurance and customers
            services, (3) to conduct insurance claims or analysis. I understand that if I do not provide such consent,
            or revoke my consent, Chubb Insurance Hong Kong
            Limited may not be able to process or assess my claim. A copy of the Chubb Privacy Information Collection
            Statement can be found at
            <a :href="chubPage" target="_blank">www.chubb.com/hk</a>
          </v-card-subtitle>

          <v-card-subtitle class="text-xs-center py-2  declaration-item">Any persons from whom Chubb Insurance Hong Kong
            Limited has collected information as aforesaid shall have the right of access to and to request correction
            of any personal information concerning themselves held by Chubb Insurance Hong Kong Limited. A request for
            such access may be made to the Personal Data
            Privacy Officer of Chubb Insurance Hong Kong Limited at 39/F, One Taikoo Place, 979 King's Road, Quarry Bay,
            Hong Kong.
          </v-card-subtitle>
        </v-card>

        <v-card
            v-if="this.$store.state.country === 'Singapore'"
            class="ma-xs-0 ma-lg-8 pa-lg-12 py-6 py-sm-10 content-item" elevation="0"
            justify="start">
          <v-card-title class="pb-8 font-weight-bold text-h5 black--text">Declaration & Authorisation</v-card-title>
          <v-card-subtitle class="text-xs-center py-2 declaration-item">Please read this statement carefully and check
            the box below before proceeding:
          </v-card-subtitle>
          <v-card-subtitle class="text-xs-center py-2  declaration-item">
            I understand and accept that my personal information will be collected, used and disclosed by Chubb in
            accordance with the Personal Data Protection Act 2012 and Chubb's Privacy Policy, for the provision of all
            services related to, and protection under, my insurance policy, including proper servicing, underwriting,
            claims administration and fraud analysis and prevention. Chubb may disclose my personal information to its
            business partners and third-party service providers for these purposes. Chubb may also send me marketing
            mailers and customer satisfaction surveys by post or email. I agree that if I am submitting information
            relating to another individual, I conﬁrm they have consented to Chubb's collection, use and disclosure of
            their personal information as described above. The full Chubb Privacy Policy can be found
            <a href="https://www.chubb.com/sg-privacy" target="_blank">https://www.chubb.com/sg-privacy</a>.
          </v-card-subtitle>

          <v-card-subtitle class="text-xs-center py-2  declaration-item">I authorise any person or entity (including any
            hospital, physician, and/or any other person or entity who has attended to or examined me/the Insured) to
            provide to Chubb, or its authorised representatives, any and all information requested in respect of any
            loss or claim, including police records, medical history, prescriptions, treatment records, and any other
            such information as Chubb in its absolute discretion considers relevant for its assessment of this claim.
          </v-card-subtitle>
          <v-card-subtitle class="text-xs-center py-2  declaration-item">I warrant and declare that the information and
            documentation I have provided in respect of this claim is true and correct. I understand and agree that if I
            have made or, in any further declaration or representation, make any false or fraudulent statements or
            suppress, conceal or falsely state any fact, my Policy shall be void and all rights to recover under my
            Policy in respect of any claims will be forfeited.
          </v-card-subtitle>

        </v-card>
        <v-checkbox v-model="isAgreed" label="I/We accept the contents in the Declaration & Authorisation statement." color="#00CCBC " class="black--text mx-lg-10"></v-checkbox>

        <v-btn x-large depressed block @click="goNext"
               color="#00CCBC"
               class="ma-0 pa-0 white--text d-md-flex d-lg-none"
               :disabled='!isAgreed'
               style="text-transform: none !important;text-align: right"
               :tile="true"
        >Next
          <v-icon>{{ 'mdi-chevron-right' }}</v-icon>
        </v-btn>
      </v-flex>

    </v-layout>
    <v-layout class="d-none d-lg-flex mb-8">
      <v-flex lg2></v-flex>
      <v-flex xs12 lg8>
        <v-row>
          <v-col offset="3" cols="3" class=" pr-2">
            <v-btn
                x-large
                depressed
                :tile="true"
                block
                color="#93A1B4"
                class="white--text"
                style="text-transform: none !important;font-size: 20px"
                @click="goPrevious()"
            >
              <v-icon>{{ 'mdi-chevron-left' }}</v-icon>
              Previous
            </v-btn>
          </v-col>
          <v-col cols="3" class="pl-2">

            <v-btn
                x-large
                depressed
                :tile="true"
                block
                color="#00CCBC"
                class="white--text"
                :disabled='!isAgreed'
                style="text-transform: none !important;font-size: 20px"
                @click="goNext"
            >Next
              <v-icon>{{ 'mdi-chevron-right' }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "claim-declaration",
  data() {
    return {
      isAgreed: false,
      chubPage: this.$store.state.selectedLanguageCode === 'hk' ? 'https://www.chubb.com/hk' : 'https://www.chubb.com/hk-en',
    }
  },
  computed: {
    getLanguageCode() {
      return this.$store.state.selectedLanguageCode
    },
  },
  methods: {
    initPageTitle: function () {
      this.$store.commit('changeMenuTitle', 'Make a Claim')
    },
    getDeclaration() {
      let declaration = this.$store.getters.declaration;
      this.isAgreed = declaration === 'Y' ? true : false;
    },
    goPrevious() {
      this.$router.back()
    },
    goNext() {
      this.$store.dispatch('saveDeclaration', this.isAgreed == true ? 'Y' : 'N')
      this.$router.push('/claims-confirmation')
    },
    initTopProgressStep: function () {
      this.$store.commit('changeStep', 5)
    },
  },
  mounted() {
    this.initPageTitle()
    this.getDeclaration()
    this.initTopProgressStep()
    this.$vuetify.goTo(0, {container: document.documentElement, duration: 0})
  }
}
</script>

<style scoped>
.content-item {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 2px 3px 3px #0000001A;
  border: 1px solid #E1E1E1;
  border-radius: 5px;
  opacity: 1;
}

.declaration-item {
  color: #37424A;
  font-size: 17px;
}

</style>
