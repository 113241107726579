<template>
  <v-container id="document-upload" style="background: #ffffff" fluid justify-center class="ma-0 pa-0">
    <v-layout>
      <v-flex sm2></v-flex>
      <v-flex xs12 sm8>
        <v-form ref="form" v-model="valid" lazy-validation>

          <!--Support Document-->
          <v-card
              :tile=true
              class="elevation-0 " style="border-bottom: #47494E 1px solid">
            <v-col class="d-flex justify-space-between align-center pa-0 pr-2">
              <v-toolbar-title class="pa-4 font-weight-bold d-inline-flex justify-space-between section-title">Supporting Documents
              </v-toolbar-title>
              <v-btn
                  v-if="$vuetify.breakpoint.smAndDown"
                  icon
                  @click="show_supporting_document = !show_supporting_document"
                  style="right: auto">
                <v-icon>{{ show_supporting_document ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-col>
          </v-card>
          <div>
            <v-card class="elevation-0 mb-4 px-2">
              <v-expand-transition name="support">
                <div v-if="show_supporting_document">
                  <div v-if="IsAMERCoverageType||IsPersonalAccidentCoverageType">
                    <v-layout row wrap justify-left px-1>

                      <v-flex xs12 sm4 px-4>
                        <v-list-item-title class="my-4">
                          {{ getTitleForTotalClaimAmount }} <strong class="red--text">*</strong>
                        </v-list-item-title>
                        <v-text-field
                            v-model="documentInfo.totalclaimamount"
                            color="primary"
                            solo
                            outlined
                            flat
                            dense
                            @keypress="isNumber($event)"
                            :rules="[rules.required,rules.numberrule,rules.totalClaimAmountRule]"
                            :validate-on-blur=true
                            maxlength="20"
                        />
                      </v-flex>
                    </v-layout>
                  </div>
                  <div v-if="IsPersonalDisabilityCoverageType">
                    <v-layout row wrap justify-left px-1>

                      <v-flex xs12 sm12 md4 px-4>
                        <v-list-item-title class="my-4 content-title" style="font-size: 15px;">
                          Please describe the injury/permanent disablement sustained <strong class="red--text">*</strong>
                        </v-list-item-title>
                        <v-text-field
                            v-model="documentInfo.describeinjury"
                            color="primary"
                            solo
                            outlined
                            flat
                            dense
                            :rules="[rules.required,rules.describeinjuryRule]"
                            :validate-on-blur=true
                            maxlength="400"/>
                      </v-flex>
                    </v-layout>
                  </div>


                  <div v-if="IsAccidentalDeathCoverageType">
                    <v-card class="elevation-0 align-stretch">
                      <div>
                        <v-layout row wrap justify-left px-1>

                          <v-flex xs12 sm6 md4 px-4>
                            <v-list-item-title class="my-4">
                              Date of Death <strong class="red--text">*</strong>
                            </v-list-item-title>

                            <v-menu
                                v-model="fromDateMenu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                            >
                              <template
                                  v-slot:activator="{ on }">
                                <v-text-field
                                    v-on="on"
                                    outlined
                                    readonly
                                    dense
                                    prepend-icon="event"
                                    color="primary"
                                    placeholder="DD \ MM \ YY"
                                    :rules="[rules.required]"
                                    :value="documentInfo.dateofdeath|moment"/>
                              </template>
                              <v-date-picker
                                  v-model="documentInfo.dateofdeath"
                                  no-title
                                  locale="en-in"
                                  color="primary"
                                  :max="new Date().toISOString().substr(0, 10)"
                                  @input="fromDateMenu = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-flex>

                          <v-flex xs12 sm6 md4 px-4>
                            <v-list-item-title class="my-4">
                              Where did the Death occur? <strong class="red--text">*</strong>
                            </v-list-item-title>
                            <v-text-field
                                v-model="documentInfo.wheredeathoccur"
                                color="primary"
                                solo
                                outlined
                                flat
                                dense
                                maxlength="100"
                                :rules="[rules.required]"/>
                          </v-flex>
                          <v-flex xs12 sm12 md4 px-4>
                            <v-list-item-title class="my-4">
                              Cause of Death<strong class="red--text">*</strong>
                            </v-list-item-title>
                            <v-text-field
                                v-model="documentInfo.causeofdeath"
                                color="primary"
                                solo
                                outlined
                                flat
                                dense
                                maxlength="100"
                                :rules="[rules.required]"/>
                          </v-flex>
                          <v-flex
                              xs12
                              sm6 md4
                              px-4>
                            <v-list-item-title
                                class="my-4"
                                style="white-space: normal;"
                                :style="'height:'+this.deathtitleHeight+'px'">
                              Do you want to claim for Funeral Expenses?<strong class="red--text">*</strong>
                            </v-list-item-title>
                            <v-radio-group
                                v-model="documentInfo.claimforfuneralexpenses"
                                row
                                class="ma-0 pa-0"
                                :rules="[rules.required]">
                              <v-radio
                                  v-for="radio in radioButtonOptions"
                                  v-bind:key="radio.code"
                                  :label="radio.value"
                                  :value="radio.code"
                                  color="#00CCBC"/>
                            </v-radio-group>
                          </v-flex>
                          <v-flex
                              xs12
                              sm6 md4
                              px-4>
                            <v-list-item-title class="my-4" style="white-space: normal;" ref="deathTitle">
                              Does the insured person have any dependant children under education?<strong class="red--text">*</strong>
                            </v-list-item-title>
                            <v-radio-group
                                v-model="documentInfo.anydependentchildren"
                                row
                                class="ma-0 pa-0"
                                :rules="[rules.required]">
                              <v-radio
                                  v-for="radio in radioButtonOptions"
                                  v-bind:key="radio.code"
                                  :label="radio.value"
                                  :value="radio.code"
                                  color="#00CCBC"/>
                            </v-radio-group>
                          </v-flex>
                        </v-layout>
                      </div>
                    </v-card>
                  </div>


                  <div v-if="IsPersonalAccidentCoverageType">
                    <div v-for="(item,index) in lossOrDamageItemInfo" :key="index">
                      <!-- Assault Only -->
                      <v-card
                          :tile=true
                          class="elevation-0" style="border-bottom: #00CCBC 1px solid">
                        <v-col class="d-flex justify-space-between align-center pa-0 pr-2">
                          <v-toolbar-title class="pa-4 font-weight-bold d-inline-flex justify-space-between">
<!--                            <span class="mr-2 orderIcon" style="color: #00CCBC">{{ index + 1 }}</span>-->
                            Item
                          </v-toolbar-title>
                          <span v-if="index>0" class="red--text" @click="$delete(lossOrDamageItemInfo,index)">Remove this item
                        <img
                            class="deleteButton"
                            :src="require('@/assets/icons/trash.png')"/>
                      </span>
                        </v-col>
                      </v-card>
                      <v-card class="elevation-0 align-stretch">
                        <div>
                          <v-layout row wrap justify-left px-1>

                            <v-flex xs12 sm6 md4 px-4>
                              <v-list-item-title class="my-4">
                                Was your item lost or damaged? <strong class="red--text">*</strong>
                              </v-list-item-title>
                              <v-select
                                  name="relationship"
                                  v-model="item.wasyouritemlostdamaged"
                                  outlined
                                  dense
                                  color="primary"
                                  item-value="code"
                                  item-text="value"
                                  :items="lostOrDamageOptions"
                                  :label="'Select'"
                                  :single-line=true
                                  :rules="[rules.required]"/>
                            </v-flex>

                            <v-flex xs12 sm6 md4 px-4>
                              <v-list-item-title class="my-4">
                                What item was lost or damaged <strong class="red--text">*</strong>
                              </v-list-item-title>
                              <v-text-field
                                  v-model="item.whatitemlostdamaged"
                                  color="primary"
                                  solo
                                  outlined
                                  flat
                                  dense
                                  :rules="[rules.required]"
                                  :validate-on-blur=true
                                  maxlength="100"
                              />
                            </v-flex>
                            <v-flex xs12 sm6 md4 px-4>
                              <v-list-item-title class="my-4">
                                When was the item purchased? <strong class="red--text">*</strong>
                              </v-list-item-title>
                              <v-menu
                                  v-model="fromDateItem[index].fromDate"
                                  :close-on-content-click="false"
                                  :nudge-right="40"
                                  transition="scale-transition"
                                  offset-y
                                  max-width="290px"
                                  min-width="290px"
                              >
                                <template
                                    v-slot:activator="{ on }">
                                  <v-text-field
                                      v-on="on"
                                      outlined
                                      readonly
                                      dense
                                      prepend-icon="event"
                                      color="primary"
                                      placeholder="DD \ MM \ YY"
                                      :value="item.whenitempurchased|moment"
                                      :rules="[rules.required]"/>
                                </template>
                                <v-date-picker
                                    v-model="item.whenitempurchased"
                                    no-title
                                    locale="en-in"
                                    color="primary"
                                    :max="new Date().toISOString().substr(0, 10)&&incidentInfo.incidentdate"
                                    @input="fromDateItem[index].fromDate = false"
                                ></v-date-picker>
                              </v-menu>

                            </v-flex>
                            <v-flex xs12 sm6 md4 px-4>
                              <v-list-item-title class="my-4">
                                How much was the item({{getCurrency}}) <strong class="red--text">*</strong>
                              </v-list-item-title>
                              <v-text-field
                                  v-model="item.howmuchitem"
                                  color="primary"
                                  solo
                                  outlined
                                  flat
                                  dense
                                  @keypress="isNumber($event)"
                                  :rules="[rules.required,rules.numberrule,rules.lossItemPriceeRule]"
                                  :validate-on-blur=true
                                  maxlength="20"/>
                            </v-flex>
                            <v-flex
                                xs12
                                sm6 md4
                                px-4>
                              <v-list-item-title class="my-4" style="white-space: normal">
                                Have you purchased a replacement for the item? <strong class="red--text">*</strong>
                              </v-list-item-title>
                              <v-radio-group
                                  v-model="item.havepurchasedreplaceitem"
                                  row
                                  :rules="[rules.required]"
                                  class="ma-0 pa-0">
                                <v-radio
                                    v-for="radio in radioButtonOptions"
                                    v-bind:key="radio.code"
                                    :label="radio.value"
                                    :value="radio.code"
                                    color="#00CCBC"
                                />
                              </v-radio-group>
                            </v-flex>
                          </v-layout>
                        </div>
                      </v-card>
<!--                      <v-btn block outlined color="#00CCBC" style="background-color: white"-->
<!--                             v-if="lossOrDamageItemInfo.length-1===index && maxlossOrDamageItemCount>lossOrDamageItemInfo.length"-->
<!--                             @click="lossOrDamageItemInfo.push({-->
<!--                                wasyouritemlostdamaged:'',-->
<!--                                whatitemlostdamaged:'',-->
<!--                                whenitempurchased:'',-->
<!--                                howmuchitem: '',-->
<!--                                havepurchasedreplaceitem:''})">-->
<!--                        <v-icon class="ma-2" style="color: black">{{ 'mdi-plus' }}</v-icon>-->
<!--                        Add 1 more item(you have {{ maxlossOrDamageItemCount - lossOrDamageItemInfo.length }} item left)-->
<!--                      </v-btn>-->
                    </div>
                  </div>

                  <v-layout row wrap justify-left px-1>

                    <v-flex xs12 sm12 px-4>
                      <v-list-item-title class="my-4 " style="color: #00CCBC;text-overflow: inherit;white-space: inherit">
                        {{ getSupportDocumentMessage }}
                      </v-list-item-title>
                      <v-card elevation="0" outlined>
                        <v-layout row wrap justify-left px-1 class="align-stretch">
                          <v-flex xs12 sm12 md4 px-4 py-2 v-for=" (item,index) in this.supportDocumentAttachments"
                                  :key="item.code+index">
                            <div class="documentUploadFields"
                                :style="$vuetify.breakpoint.smAndDown?'':$vuetify.breakpoint.lgAndUp?'min-height:43px':'min-height:60px'"
                            >
                            <span class="mr-2 blueIcon">{{ (index + 10).toString(36).toLowerCase() }}</span>
                            {{ item.value }}
                            <strong v-if="
                                (IsPersonalAccidentCoverageType&&lossOrDamageItemInfo.filter(function(e) { return e.havepurchasedreplaceitem === 'Y'; }).length > 0&& item.code ==='LDPA-02')
                                ||
                                (IsAccidentalDeathCoverageType&&
                                incidentInfo.policyreportnumber!=='' && item.code ==='AD-01')
                                ||
                                (IsAccidentalDeathCoverageType&&
                                documentInfo.anydependentchildren==='Y' && item.code ==='AD-D-01')
                                ? true:item.required==='Y'" class="red--text">*</strong>
                              <v-input v-if="(IsPersonalAccidentCoverageType&&lossOrDamageItemInfo.filter(function(e) { return e.havepurchasedreplaceitem === 'Y'; }).length > 0&& item.code ==='LDPA-02')
                                ||
                                (IsAccidentalDeathCoverageType&&
                                incidentInfo.policyreportnumber!=='' && item.code ==='AD-01')
                                ||
                                (IsAccidentalDeathCoverageType&&
                                documentInfo.anydependentchildren==='Y' && item.code ==='AD-D-01')
                                ? true:item.required==='Y'" :rules="[rules.requiredFile]" v-model="item.documents" class="ma-0 pa-0"></v-input>
                            </div>
                            <file-upload
                                :accept="acceptDocuments"
                                :post-action="postAction"
                                :put-action="putAction"
                                :multiple="multiple"
                                :directory="directory"
                                :size="size || 0"
                                :thread="thread < 1 ? 1 : (thread > 5 ? 5 : thread)"
                                :headers="headers"
                                :data="data"
                                :drop="drop"
                                :drop-directory="dropDirectory"
                                :add-index="addIndex"
                                v-model="item.documents"
                                :maximum="maximum"
                                @input-filter="inputFilter"
                                @input-file="inputFile"
                                :input-id="item.code"
                                :ref="item.code" class="add-photo-button">
                              <v-btn block outlined color="#00CCBC" style="background-color: white"
                                     class="pa-2 my-4">
                                <v-icon class="ma-2" style="color: black">{{ 'mdi-plus' }}</v-icon>
                                Add documents
                              </v-btn>
                            </file-upload>
                            <v-row
                                v-if="typeof item.documents!= 'undefined' && item.documents!==undefined && item.documents.length !==0"
                                class="ma-0"
                                style="background-color: #F4F4F4;border: 1px solid #EBEBEB;border-radius: 3px; width: 100%;padding-left: 20px"
                            >

                              <ul style="padding: 0px; list-style-type: none;width: 100%;">

                                <v-row v-for="fileItem in item.documents" v-bind:key="fileItem.id"
                                       class="justify-space-between align-center ma-0"
                                       style="width: 100%; padding: 3px">
                                  <li style="width: 100%;padding-right: 7%">
                                    <div >
                                  <span class="file-text-span"
                                        style="color: #37424A; vertical-align: middle;">&#9679; {{ fileItem.name }}</span>
                                      <img :src="require('@/assets/icons/delete.png')"
                                           style="width: 20px; height: 20px; background-color: #93A1B4; padding: 5px;border-radius: 50%;vertical-align: middle;"
                                           @click="showDeletePopup(supportDocumentAttachments,item.documents,fileItem)"/>
                                    </div>
                                  </li>
                                </v-row>

                              </ul>

                            </v-row>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>

                  </v-layout>

                </div>
              </v-expand-transition>
            </v-card>
          </div>

          <v-card
              :tile=true
              class="elevation-0 " style="border-bottom: #47494E 1px solid">
            <v-col class="d-flex justify-space-between align-center pa-0 pr-2">
              <v-toolbar-title class="pa-4 font-weight-bold d-inline-flex justify-space-between section-title">Other Insurance
                Coverage
              </v-toolbar-title>
              <v-btn
                  v-if="$vuetify.breakpoint.smAndDown"
                  icon
                  @click="show_other_insurance = !show_other_insurance"
                  style="right: auto">
                <v-icon>{{ show_other_insurance ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-col>
          </v-card>
          <div>
            <v-card class="elevation-0 mb-4 px-2">
              <v-expand-transition name="otherInsurance">
                <div v-if="show_other_insurance">
                  <v-layout row wrap justify-left px-1>
                    <v-flex
                        xs12
                        sm12
                        px-4>
                      <v-list-item-title class="my-4 content-title">
                        Do you have any other insurance that might cover this loss?
                      </v-list-item-title>
                      <v-radio-group
                          v-model="otherInsuranceCover"
                          row
                          class="ma-0 pa-0">
                        <v-radio
                            v-for="radio in radioButtonOptions"
                            v-bind:key="radio.code"
                            :label="radio.value"
                            :value="radio.code"
                            color="#00CCBC"/>
                      </v-radio-group>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap justify-left px-1 v-if="otherInsuranceCover==='Y'">

                    <v-flex xs12 sm6 md4 px-4>
                      <v-list-item-title class="my-4">
                        Name of Insurance Company <strong class="red--text">*</strong>
                      </v-list-item-title>
                      <v-text-field
                          v-model="otherInsuranceCoverageInfo.nameofinsurer"
                          color="primary"
                          solo
                          outlined
                          flat
                          dense
                          :rules="[rules.required,rules.otherInsuranceName]"
                          :validate-on-blur=true
                          maxlength="100"/>
                    </v-flex>

                    <v-flex xs12 sm6 md4 px-4>
                      <v-list-item-title class="my-4">
                        Policy number <strong class="red--text">*</strong>
                      </v-list-item-title>
                      <v-text-field
                          v-model="otherInsuranceCoverageInfo.policynumber"
                          color="primary"
                          solo
                          outlined
                          flat
                          dense
                          @keypress="isAlphaNumeric($event)"
                          :rules="[rules.required,rules.otherInsurancePolicyNumber]"
                          :validate-on-blur=true
                          maxlength="20"/>
                    </v-flex>

                    <v-flex xs12 sm6 md4 px-4>
                      <v-list-item-title class="my-4">
                        {{ getTitleForOtherInsuranceBenefitAmount }} <strong class="red--text">*</strong>
                      </v-list-item-title>
                      <v-text-field
                          v-model="otherInsuranceCoverageInfo.benefitamount"
                          color="primary"
                          solo
                          outlined
                          flat
                          dense
                          @keypress="isNumber($event)"
                          :rules="[rules.required, rules.numberrule,rules.otherInsuranceBenefitAmount]"
                          :validate-on-blur=true
                          maxlength="20"/>
                    </v-flex>

                  </v-layout>
                </div>
              </v-expand-transition>
            </v-card>
          </div>

          <v-card
              v-if="IsAMERCoverageType"
              :tile=true
              class="elevation-0 ">
            <v-col class="d-flex justify-space-between align-center pa-0 pr-2" style="border-bottom: #47494E 1px solid">
              <v-toolbar-title class="pa-4 font-weight-bold d-inline-flex justify-space-between section-title">Medical Leave
              </v-toolbar-title>
              <v-btn
                  icon
                  v-if="$vuetify.breakpoint.smAndDown"
                  @click="show_medical_leave = !show_medical_leave"
                  style="right: auto">
                <v-icon>{{ show_medical_leave ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-col>
            <div v-if="show_medical_leave">
              <v-card class="elevation-0 mb-4 px-2">
                <v-expand-transition name="otherInsurance">
                  <div>
                    <v-layout row wrap justify-left px-1>
                      <v-flex
                          xs12
                          sm12
                          px-4>
                        <v-list-item-title class="my-4">
                          Did you require medical leave?
                        </v-list-item-title>
                        <v-radio-group
                            v-model="pleaseTickMedicalLeaveInfo"
                            row
                            class="ma-0 pa-0">
                          <v-radio
                              v-for="radio in radioButtonOptions"
                              v-bind:key="radio.code"
                              :label="radio.value"
                              :value="radio.code"
                              color="#00CCBC"/>
                        </v-radio-group>
                      </v-flex>
                    </v-layout>
                    <v-layout row wrap justify-left px-1 v-if="pleaseTickMedicalLeaveInfo==='Y'">

                      <v-flex xs12 sm6 md4 px-4>
                        <v-list-item-title class="my-4 content-title">
                          When did your medical leave start?(DD/ MM/ YY) <strong class="red--text">*</strong>
                        </v-list-item-title>
                        <v-menu
                            v-model="fromDateMenuMedicalStart"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                        >
                          <template
                              v-slot:activator="{ on }">
                            <v-text-field
                                v-on="on"
                                outlined
                                readonly
                                dense
                                prepend-icon="event"
                                color="primary"
                                placeholder="DD \ MM \ YY"
                                :value="medicalLeaveInfo.medicalleavestart|moment"
                                :rules="[rules.required]"/>
                          </template>
                          <v-date-picker
                              v-model="medicalLeaveInfo.medicalleavestart"
                              no-title
                              locale="en-in"
                              color="primary"
                              :min="incidentInfo.incidentdate"
                              :max="new Date().toISOString().substr(0, 10)"
                              @input="fromDateMenuMedicalStart = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-flex>

                      <v-flex xs12 sm6 md4 px-4>
                        <v-list-item-title class="my-4 content-title">
                          When did your medical leave end?(DD/ MM/ YY) <strong class="red--text">*</strong>
                        </v-list-item-title>
                        <v-menu
                            v-model="fromDateMenuMedicalEnd"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                        >
                          <template
                              v-slot:activator="{ on }">
                            <v-text-field
                                v-on="on"
                                outlined
                                readonly
                                dense
                                prepend-icon="event"
                                color="primary"
                                placeholder="DD \ MM \ YY"
                                :value="medicalLeaveInfo.medicalleavelend|moment"
                                :rules="[rules.required]"/>
                          </template>
                          <v-date-picker
                              v-model="medicalLeaveInfo.medicalleavelend"
                              no-title
                              locale="en-in"
                              color="primary"
                              :min="incidentInfo.incidentdate &&medicalLeaveInfo.medicalleavestart"
                              @input="fromDateMenuMedicalEnd = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-flex>

                      <v-flex
                          xs12
                          sm12
                          px-4>
                        <v-list-item-title class="my-4 content-title">
                          Were you able to perform light duties, during your medical leave, according to a Doctor’s advice?
                        </v-list-item-title>
                        <v-radio-group
                            v-model="medicalLeaveInfo.performlightduty"
                            row
                            class="ma-0 pa-0">
                          <v-radio
                              v-for="radio in radioButtonOptions"
                              v-bind:key="radio.code"
                              :label="radio.value"
                              :value="radio.code"
                              color="#00CCBC"/>
                        </v-radio-group>
                      </v-flex>
                    </v-layout>
                    <!--                <v-layout row wrap justify-left px-1 v-if="pleaseTickMedicalLeaveInfo==='Y'">-->

                    <!--                </v-layout>-->
                    <v-layout row wrap justify-left px-1 v-if="pleaseTickMedicalLeaveInfo==='Y' && medicalLeaveInfo.performlightduty==='Y'" align-start>

                      <v-flex xs12 sm6 md4 px-4>
                        <v-list-item-title class="my-4 content-title">
                          When were you able start performing light duty during the medical leaves? <strong
                            class="red--text">*</strong>
                        </v-list-item-title>
                        <v-menu
                            v-model="fromDateMenuLightDutyStart"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                        >
                          <template
                              v-slot:activator="{ on }">
                            <v-text-field
                                v-on="on"
                                outlined
                                readonly
                                dense
                                prepend-icon="event"
                                color="primary"
                                placeholder="DD \ MM \ YY"
                                :value="medicalLeaveInfo.whenperformlightduty|moment"
                                :rules="[rules.required]"/>
                          </template>
                          <v-date-picker
                              v-model="medicalLeaveInfo.whenperformlightduty"
                              no-title
                              locale="en-in"
                              color="primary"
                              :max="new Date().toISOString().substr(0, 10)"
                              @input="fromDateMenuLightDutyStart = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-flex>

                      <v-flex xs12 sm6 md4 px-4 >
                        <v-list-item-title class="my-4 content-title"  :style="$vuetify.breakpoint.smAndDown?'':$vuetify.breakpoint.md?'min-height:72px':'min-height:46px'">
                          When did you fully recover? <strong class="red--text">*</strong>
                        </v-list-item-title>
                        <v-menu
                            v-model="fromDateMenuFullyRecover"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                        >
                          <template
                              v-slot:activator="{ on }">
                            <v-text-field
                                v-on="on"
                                outlined
                                readonly
                                dense
                                prepend-icon="event"
                                color="primary"
                                placeholder="DD \ MM \ YY"
                                :value="medicalLeaveInfo.whenfullyrecover|moment"
                                :rules="[rules.required]"/>
                          </template>
                          <v-date-picker
                              v-model="medicalLeaveInfo.whenfullyrecover"
                              no-title
                              locale="en-in"
                              color="primary"
                              :max="new Date().toISOString().substr(0, 10)"
                              @input="fromDateMenuFullyRecover = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-flex>

                    </v-layout>
                    <v-layout row wrap justify-left px-1 v-if="pleaseTickMedicalLeaveInfo==='Y'">

                      <v-flex
                          xs12 sm12 px-4>
                        <v-list-item-title class="my-4 " style="color: #00CCBC">
                          ▶ Following documents are required to verify your medical leave:
                        </v-list-item-title>
                        <v-card elevation="0" outlined>
                          <v-layout row wrap justify-left px-1 class="align-stretch">
                            <v-flex xs12 sm12 md4 px-4 py-2 v-for=" (item,index) in medicalDocumentAttachments"
                                    :key="item.code">
                              <div :style="$vuetify.breakpoint.smAndDown?'':$vuetify.breakpoint.md?'min-height:72px':'min-height:46px'">
                              <span class="mr-2 blueIcon">{{ (index + 10).toString(36).toLowerCase() }}</span>
                              {{ item.value }}
                              <strong v-if="item.required==='Y'" class="red--text">*</strong>

                              <v-input v-if="item.required==='Y'" :rules="[rules.requiredFile]" v-model="item.medicaldocuments" class="ma-0 pa-0"></v-input>
                              </div>
                              <file-upload
                                  :accept="acceptDocuments"
                                  :post-action="postAction"
                                  :put-action="putAction"
                                  :multiple="multiple"
                                  :directory="directory"
                                  :size="size || 0"
                                  :thread="thread < 1 ? 1 : (thread > 5 ? 5 : thread)"
                                  :headers="headers"
                                  :data="data"
                                  :drop="drop"
                                  :drop-directory="dropDirectory"
                                  :add-index="addIndex"
                                  v-model="item.medicaldocuments"
                                  :maximum="maximum"
                                  @input-filter="inputFilter"
                                  @input-file="inputFile"
                                  :input-id="item.code"
                                  :ref="item.code" class="add-photo-button">
                                <v-btn block outlined color="#00CCBC" style="background-color: white"
                                       class="pa-2 my-4">
                                  <v-icon class="ma-2" style="color: black">{{ 'mdi-plus' }}</v-icon>
                                  Add documents
                                </v-btn>
                              </file-upload>
                              <v-row
                                  v-if="typeof item.medicaldocuments!= 'undefined' && item.medicaldocuments!==undefined && item.medicaldocuments.length !==0"
                                  class="ma-0"
                                  style="background-color: #F4F4F4;border: 1px solid #EBEBEB;border-radius: 3px; width: 100%;padding-left: 20px"
                              >

                                <ul style="padding: 0px; width: 100%;">

                                  <v-row v-for="fileItem in item.medicaldocuments" v-bind:key="fileItem.id"
                                         class="justify-space-between align-center ma-0"
                                         style="width: 100%; padding: 3px">
                                    <li style="width: 100%;padding-right: 7%">
                                      <div>
                                  <span class="file-text-span"
                                        style="color: #37424A; vertical-align: middle;">&#9679; {{ fileItem.name }}</span>
                                        <img :src="require('@/assets/icons/delete.png')"
                                             style="width: 20px; height: 20px; background-color: #93A1B4; padding: 5px;border-radius: 50%;vertical-align: middle; "
                                             @click="showDeletePopup(medicalDocumentAttachments,item.medicaldocuments,fileItem)"/>
                                      </div>
                                    </li>
                                  </v-row>

                                </ul>

                              </v-row>

                            </v-flex>
                          </v-layout>
                        </v-card>
                      </v-flex>

                    </v-layout>
                  </div>


                </v-expand-transition>
              </v-card>
            </div>
          </v-card>

          <v-card
              :tile=true
              class="elevation-0 " style="border-bottom: #47494E 1px solid">
            <v-col class="d-flex justify-space-between align-center pa-0 pr-2">
              <v-toolbar-title class="pa-4 font-weight-bold d-inline-flex justify-space-between section-title">Claims Payment Transfer Details
              </v-toolbar-title>
              <v-btn
                  v-if="$vuetify.breakpoint.smAndDown"
                  icon
                  @click="show_transfer_detail = !show_transfer_detail"
                  style="right: auto">
                <v-icon>{{ show_transfer_detail ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-col>
          </v-card>
          <div>
            <v-card class="elevation-0 mb-4 px-2">
              <v-expand-transition name="otherInsurance">
                <div v-if="show_transfer_detail">
                  <v-layout row wrap justify-left px-1>
                    <v-flex
                        xs12
                        sm12
                        px-4>
                      <v-list-item-title class="my-4 font-weight-bold body-2">Payee Information</v-list-item-title>
                      <p class="caption" style="letter-spacing: 0.15px; color: #00CCBC;font-weight: 300;">Please provide us with your bank account information where you intend to receive payment as a result of this claim.  Providing this information will help Chubb make any approved payments quickly.</p>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap justify-left px-1>

                    <v-flex xs12 sm6 md4 px-4>
                      <v-list-item-title class="my-4">
                        Payee name / Account holder <strong class="red--text">*</strong>
                      </v-list-item-title>
                      <v-text-field
                          v-model="transferDetailInfo.accountholder"
                          color="primary"
                          solo
                          outlined
                          flat
                          dense
                          :rules="[rules.required,rules.payee]"
                          :validate-on-blur=true
                          maxlength="100"/>
                    </v-flex>

                    <v-flex xs12 sm6 md4 px-4>
                      <v-list-item-title class="my-4">
                        Bank name <strong class="red--text">*</strong>
                      </v-list-item-title>
                      <v-select
                          name="relationship"
                          v-model="transferDetailInfo.bankname"
                          outlined
                          dense
                          color="primary"
                          item-value="code"
                          item-text="value"
                          :items="getBankFilteredByCountry"
                          :label="'Select'"
                          :single-line=true
                          :rules="[rules.required]"
                      @change="checkBankName"/>
                    </v-flex>
                    <v-flex xs12 sm4 md4 px-4 class="d-none d-md-none"></v-flex>
                    <v-flex xs12 sm6 md4 px-4 v-if="transferDetailInfo.bankname.includes('OTH')">
                      <v-list-item-title class="my-4" >
                        Please Provide Bank Name <strong class="red--text">*</strong>
                      </v-list-item-title>
                      <v-text-field
                          v-model="transferDetailInfo.otherbankname"
                          color="primary"
                          solo
                          outlined
                          flat
                          dense
                          :rules="[rules.required]"
                          :validate-on-blur=true
                          maxlength="100"/>
                    </v-flex>

                    <v-flex  xs12 sm12 md4 px-4 v-if="!transferDetailInfo.bankname.includes('OTH')"></v-flex>
                    <v-flex  xs0 sm12 md0 px-4 v-if="transferDetailInfo.bankname.includes('OTH')"></v-flex>
                    <v-flex xs12 sm6 md4 px-4>
                      <v-list-item-title class="my-4">
                        Account number <strong class="red--text">*</strong>
                      </v-list-item-title>
                      <v-text-field
                          v-model="transferDetailInfo.accountnumber"
                          color="primary"
                          solo
                          outlined
                          flat
                          dense
                          @keypress="isBankCodeOrBranchCode($event)"
                          :rules="[rules.required,rules.numberrule,rules.accountNumber]"
                          :validate-on-blur=true
                          maxlength="13"/>
                    </v-flex>
                    <v-flex xs12 sm6 md4 px-4>
                      <v-list-item-title class="my-4">
                        Bank / branch code <strong v-if="!(this.$store.state.country === 'Singapore' && !transferDetailInfo.bankname.includes('OTH'))" class="red--text">*</strong>
                      </v-list-item-title>
                      <v-text-field
                          v-model="transferDetailInfo.branchcode"
                          color="primary"
                          solo
                          outlined
                          flat
                          dense
                          @keypress="isBankCodeOrBranchCode($event)"
                          :rules="!(this.$store.state.country === 'Singapore' && !transferDetailInfo.bankname.includes('OTH'))?[rules.required,rules.numberrule,rules.branchCode]:[rules.numberrule,rules.branchCode]"
                          :validate-on-blur=true
                          :maxlength="this.$store.state.country === 'Singapore'?7:6"/>
                    </v-flex>

                  </v-layout>
                </div>
              </v-expand-transition>
            </v-card>
          </div>
        </v-form>

        <v-flex xs12 sm12 v-if="!this.$route.query.modify">
          <v-btn
              x-large
              depressed
              block
              :tile=true
              class="ma-0 pa-0 white--text d-md-flex d-lg-none action-button"
              color="#00CCBC"
              @click="validateAndGoNext">Next
          </v-btn>
        </v-flex>

        <v-flex xs12 sm12 v-if="this.$route.query.modify">
          <v-btn
              x-large
              depressed
              block
              :tile=true
              class="ma-0 pa-0 white--text d-md-flex d-lg-none action-button"
              color="#00CCBC"
              @click="validateAndApplyChange">
            Apply Change
            <v-icon>{{ 'mdi-chevron-right' }}</v-icon>
          </v-btn>
        </v-flex>
        <v-flex xs12 sm12 v-if="this.$route.query.modify">
          <v-btn
              x-large
              depressed
              block
              :tile=true
              class="ma-0 pa-0 white--text d-md-flex d-lg-none action-button"
              color="#93A1B4"
              @click="goPrevious">Cancel Change
            <v-icon>{{ 'mdi-chevron-right' }}</v-icon>
          </v-btn>
        </v-flex>
      </v-flex>
      <v-flex sm2>

      </v-flex>
    </v-layout>
    <v-layout class="d-none d-lg-flex mb-8 " :tile="true">
      <v-flex lg2></v-flex>
      <v-flex xs12 lg8 :class="$vuetify.breakpoint.mdAndUp?'menu-divider-top':''" v-if="this.$route.query.modify">
        <v-row>
          <v-col offset="3" cols="3" class="pr-2">
            <v-btn
                x-large
                depressed
                :tile="true"
                block
                color="#93A1B4"
                class="white--text action-button"
                @click="goPrevious()"
            >
              <v-icon>{{ 'mdi-chevron-left' }}</v-icon>
              Cancel Change
            </v-btn>
          </v-col>
          <v-col cols="3" class="pl-2">
            <v-btn
                x-large
                depressed
                :tile="true"
                block
                color="#00CCBC"
                class="white--text action-button"
                style="text-align: right;"
                @click="validateAndApplyChange"
            >Apply Change
              <v-icon>{{ 'mdi-chevron-right' }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-flex>
      <v-flex xs12 lg8 :class="$vuetify.breakpoint.mdAndUp?'menu-divider-top':''" v-if="!this.$route.query.modify">
        <v-row>
          <v-col offset="3" cols="3" class=" pr-2">
            <v-btn
                x-large
                depressed
                :tile="true"
                block
                color="#93A1B4"
                class="white--text action-button"
                @click="goPrevious()"
            >
              <v-icon>{{ 'mdi-chevron-left' }}</v-icon>
              Previous

            </v-btn>
          </v-col>
          <v-col cols="3" class="pl-2">

            <v-btn
                x-large
                depressed
                :tile="true"
                block
                color="#00CCBC"
                class="white--text font-size: 20px"
                style="text-align: right;"
                @click="validateAndGoNext"
            >Next
              <v-icon>{{ 'mdi-chevron-right' }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
    <!-- fab icon -->
    <v-btn
        v-scroll="onScroll"
        v-show="is_show_fav_icon && $vuetify.breakpoint.smAndDown"
        elevation="1"
        fab
        fixed
        bottom
        right
        color="blue darken-3"
        style="margin-bottom: 60px;margin-right: 16px;"
        @click="toTop">
      <img :src="icons.bottom_top_arrow" @click="toTop" style="max-width: 20px; max-height: 20px; ">
    </v-btn>
    <v-dialog
        v-model="dialog"
        persistent
        :width="$vuetify.breakpoint.smAndDown?'90%':429"
    >
      <v-card
          color="white"
          class="pt-4 ma-0"
          style="box-shadow: 3px 4px 5px #00000033;border: 2px solid #006D9E !important;border-radius: 10px; padding: 2px"
      >
        <v-icon style="float: right;padding-right: 16px" @click="dialog=!dialog">clear</v-icon>
        <v-card-text class="px-4 py-8 text-body-1 black--text" style="text-align: center">
          Are you sure you want to<br/> delete this photo?
        </v-card-text>
        <div style="background: #F2FBFC 0% 0% no-repeat padding-box; height: 77px; padding-top: 5px !important;">
          <v-row justify="center" align-content="center" class="ma-0 pa-0">
            <v-col cols="6" align-self="center">
              <v-btn
                  color="#93A1B4"
                  class="ma-0  white--text text-body-1"
                  large
                  block
                  @click="dialog=!dialog">
                Cancel
              </v-btn>
            </v-col>
            <v-col cols="6" align-self="center">
              <v-btn
                  color="#00CCBC"
                  class="ma-0 white--text text-body-1"
                  large
                  block
                  @click="removeItem()">
                Ok
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from 'moment';
import {mapGetters, mapMutations} from "vuex";

import FileUpload from "vue-upload-component";
import Compressor from "compressorjs";

export default {
  components: {
    FileUpload,
  },
  data() {
    return {
      deathtitleHeight : 0,
      dialog: false,
      toRemoveItem: null,
      toRemoveParent: null,
      supportDocumentAttachments: [],
      medicalDocumentAttachments: [],
      valid: false,
      show_supporting_document: true,
      show_other_insurance: true,
      show_medical_leave: true,
      show_transfer_detail: true,
      is_show_fav_icon: true,
      icons: {
        personal_accident_icon: require('@/assets/icons/icon_Image_00.png'),
        public_liability_icon: require('@/assets/icons/icon_Image_01.png'),
        right_arrow_icon: require('@/assets/icons/icon_select_arrow_01.png'),
        bottom_top_arrow: require('@/assets/icons/bottom_top_arrow.png'),
        date_icon: require('@/assets/icons/icon_Image_09.png'),
      },
      fromDateItem:[
        {fromDate: false},
        {fromDate: false},
        {fromDate: false},
        {fromDate: false},
        {fromDate: false},
      ],
      fromDateMenu : false,
      fromDateMenuMedicalStart: false,
      fromDateMenuMedicalEnd: false,
      fromDateMenuLightDutyStart: false,
      fromDateMenuFullyRecover: false,
      minDate: "2018-01-01",
      pickerDate: moment().format('YYYY[-]MM[-]DD'),
      maxDate: moment().format('YYYY[-]MM[-]DD'),
      maxlossOrDamageItemCount: 5,
      acceptDocuments: 'image/png,image/jpeg,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      minSize: 1024,
      size: 1024 * 1024 * 10,
      maximum: 5,
      multiple: true,
      directory: false,
      drop: false,
      dropDirectory: false,
      addIndex: false,
      thread: 3,
      name: 'file',
      postAction: '/upload/post',
      putAction: '/upload/put',
      headers: {
        'X-Csrf-Token': 'xxxx',
      },
      data: {
        '_csrf_token': 'xxxxxx',
      },
      autoCompress: 1024 * 1024,
      uploadAuto: false,
      addData: {
        show: false,
        name: '',
        type: '',
        content: '',
      },
      editFile: {
        show: false,
        name: '',
      },
      incidentInfo: this.getIncident(),
      documentInfo: this.getDocumentDetails(),
      lossOrDamageItemInfo: this.getLossOrDamageItem(),
      otherInsuranceCover: this.getOtherInsuranceCovering(),
      otherInsuranceCoverageInfo: this.getOtherInsuranceCoverage(),
      pleaseTickMedicalLeaveInfo: this.getPleaseTickMedicalLeave(),
      medicalLeaveInfo: this.getMedicalLeave(),
      transferDetailInfo: this.getTransferDetail(),

      rules: {
        required: value => (!!value && value.trim().length!==0) || "This field is mandatory",
        requiredFile :value => !!value || "This field is mandatory",
        numberrule: value => {
          const pattern = /^\d*\.?\d*$/;
          if (value == null || value == undefined) {
            return true;
          }
          return (
              pattern.test(
                  String(value)
                      .replace(/\,/g, "")
                      .replace(/\-/g, "")
              ) || "This field must have numeric value"
          );
        },
        totalClaimAmountRule: v => (v.length<=20 && v>0) || 'Please enter valid Total claim amount',
        lossItemNameRule: v => v.length<=20 || 'Please enter valid Total claim amount',
        lossItemPriceeRule: v => v.length<=20 || 'Please enter valid Total claim amount',
        describeinjuryRule: v => v.length<=400 || 'Please enter valid the injury/permanent disablement sustained',
        otherInsuranceName: v => v.length<=100 || 'Please enter valid Name of Insurance Company',
        otherInsurancePolicyNumber: v => (v.length<=20 && /^[a-zA-Z0-9]*$/.test(v) ) || 'Please enter valid Policy number',
        otherInsuranceBenefitAmount: v => (v.length<=20 && v>0) || 'Please enter valid '+this.getTitleForOtherInsuranceBenefitAmount,
        payee: v => v.length<=100 || 'Please enter valid Paynee Name / Account Holder',
        accountNumber: v => v.length<=13 || 'Please enter valid account number',
        branchCode: v => (this.$store.state.country === 'Singapore'? (v.length===7||v.length===0):v.length<=6) || 'Please enter valid branch code',
        emailValidation: value => {
          var pattern = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
          if (value == null || value === undefined || value==='') {
            return true;
          }
          return (
              pattern.test(
                  value
              ) || "E-mail must be valid"
          );
        },
      }
    }
  },
  computed: {
    IsAMERCoverageType() {
      return this.$store.state.incidenttype2 == "AMER";
    },
    IsPersonalDisabilityCoverageType() {
      return this.$store.state.incidenttype2 == "PD";
    },
    IsPersonalAccidentCoverageType() {
      return this.$store.state.incidenttype2 == "LDPA";
    },
    IsAccidentalDeathCoverageType() {
      return this.$store.state.incidenttype2 == "AD";
    },
    getBankFilteredByCountry() {
      return this.$store.getters.bankOptions.filter(bank => bank.country === this.$store.state.countryCode)
    },
    // getBranchFilteredByCountry() {
    //   console.log(this.$store.getters.branchCodeOptions)
    //   return this.$store.getters.branchCodeOptions
    //       .filter(branch=>branch.bankcode === this.transferDetailInfo.bankname)
    // },

    GetCountryName() {
      return this.$store.state.country;
    },
    getSupportDocumentMessage() {
      return '▶ The following documents are required to support your claim.  Maximum upload file size: 5MB per file and a total size of all files up to 25MB'
      // if (this.IsAMERCoverageType){
      //     return '▶ Following documents are required to process your accidental medical expenses reimbursement';
      // }else if (this.IsPersonalDisabilityCoverageType){
      //   return '▶ Following documents are required to process your claim';
      // }
      // else {
      //   return '▶ Following documents are required to process your claim';
      // }
    },
    getTitleForTotalClaimAmount() {
      if (this.$store.state.country === 'Singapore') {
        return 'Total claim amount (SGD)'
      }else {
        return 'Total claim amount (HKD)'
      }
    },
    getTitleForOtherInsuranceBenefitAmount() {
      if (this.$store.state.country === 'Singapore') {
        return 'Benefit amount (SGD)'
      }else {
        return 'Benefit amount (HKD)'
      }
    },
    getCurrency (){
      if (this.$store.state.country === 'Singapore') {
        return 'SGD'
      }else {
        return 'HKD'
      }
    },
    ...mapGetters([
      'incidentDetails',
      'injuryLocationOptions',
      'injuryTypeOptions',
      'genderOptions',
      'prefferedContactOptions',
      'radioButtonOptions',
      'transportationModeOptions',
      'supportDocumentOptions',
      'bankOptions',
      'lostOrDamageOptions',

      'supportDocumentDetails',
      'lossOrDamageItem',
      'otherInsuranceCovering',
      'otherInsuranceCoverage',
      'pleaseTickMedicalLeave',
      'medicalLeave',
      'transferDetail',
    ]),
  },
  methods: {

    ...mapMutations(
        [
          'saveSupportDocumentDetails',
          'saveLossOrDamageItem'
        ]
    ),
    getIncident() {
      return _.cloneDeep(this.$store.getters.incidentDetails)
    },
    getDocumentDetails() {
      return _.cloneDeep(this.$store.getters.supportDocumentDetails)
    },
    getLossOrDamageItem() {
      return _.cloneDeep(this.$store.getters.lossOrDamageItem)
    },
    getDefaultSupportDocumentList() {
      return _.cloneDeep(this.$store.getters.supportDocumentOptions.filter(i => i.type === this.$store.state.incidenttype2))
    },
    getDefaultMedicalDocumentList() {
      return _.cloneDeep(this.$store.getters.medicalDocumentOptions.filter(i => i.type === this.$store.state.incidenttype2))
    },
    getOtherInsuranceCovering() {
      return _.cloneDeep(this.$store.getters.otherInsuranceCovering)
    },
    getOtherInsuranceCoverage() {
      return _.cloneDeep(this.$store.getters.otherInsuranceCoverage)
    },
    getPleaseTickMedicalLeave() {
      return _.cloneDeep(this.$store.getters.pleaseTickMedicalLeave)
    },
    getMedicalLeave() {
      return _.cloneDeep(this.$store.getters.medicalLeave)
    },
    getTransferDetail() {
      return _.cloneDeep(this.$store.getters.transferDetail)
    },
    validateAndApplyChange: function () {
      this.checkMedicalStartDateAndEndDate();
      if (this.$refs.form.validate()) {

        this.documentInfo.attachments = _.cloneDeep(this.supportDocumentAttachments)
        this.medicalLeaveInfo.medicaldocuments = _.cloneDeep(this.medicalDocumentAttachments)
        this.$store.commit('saveSupportDocumentDetails', this.documentInfo)
        this.$store.commit('saveLossOrDamageItem', this.lossOrDamageItemInfo)
        this.$store.commit('saveOtherInsuranceCovering', this.otherInsuranceCover)
        this.$store.commit('saveOtherInsuranceCoverage', this.otherInsuranceCoverageInfo)
        this.$store.commit('savePleaseTickMedicalLeave', this.pleaseTickMedicalLeaveInfo)
        this.$store.commit('saveMedicalLeave', this.medicalLeaveInfo)
        this.$store.commit('saveTransferDetail', this.transferDetailInfo)
        this.$router.back()
      }
      else {
        let context = this;
        setTimeout(function () {
          let test = context.$el.querySelector('.v-messages__message')
          if (test){

            context.$vuetify.goTo(context.$el.querySelector('.v-messages__message').parentNode.parentNode.parentNode.parentNode, {container: document.documentElement,duration:100})
            context.$el.querySelector('.v-messages__message').parentNode.parentNode.parentNode.parentNode.focus()
          }
        },100)
      }
    },
    validateAndGoNext: function () {
      this.checkMedicalStartDateAndEndDate();
      if (this.$refs.form.validate()){

        this.documentInfo.attachments = _.cloneDeep(this.supportDocumentAttachments)
        this.medicalLeaveInfo.medicaldocuments = _.cloneDeep(this.medicalDocumentAttachments)
        this.$store.commit('saveSupportDocumentDetails', this.documentInfo)
        this.$store.commit('saveLossOrDamageItem', this.lossOrDamageItemInfo)
        this.$store.commit('saveOtherInsuranceCovering', this.otherInsuranceCover)
        this.$store.commit('saveOtherInsuranceCoverage', this.otherInsuranceCoverageInfo)
        this.$store.commit('savePleaseTickMedicalLeave', this.pleaseTickMedicalLeaveInfo)
        this.$store.commit('saveMedicalLeave', this.medicalLeaveInfo)
        this.$store.commit('saveTransferDetail', this.transferDetailInfo)
      this.$router.push('/claims-declaration');
      }
      else {
        let context = this;
        setTimeout(function () {
          let test = context.$el.querySelector('.v-messages__message')
          if (test){
            context.$vuetify.goTo(context.$el.querySelector('.v-messages__message').parentNode.parentNode.parentNode.parentNode, {container: document.documentElement,duration:100})
            context.$el.querySelector('.v-messages__message').parentNode.parentNode.parentNode.parentNode.focus()
          }
        },100)
      }
    },
    checkMedicalStartDateAndEndDate(){
      if (this.medicalLeaveInfo.medicalleavestart &&
          this.medicalLeaveInfo.medicalleavelend
      ){
        var end = moment(this.medicalLeaveInfo.medicalleavelend)
        var start = moment(this.medicalLeaveInfo.medicalleavestart);

        if (start > end) {
          // date is past
          this.medicalLeaveInfo.medicalleavelend='';
          return;
        }
      }
    },
    checkRule: function (isRequiredField, value) {
      if (isRequiredField && value.length === 0) {
        return ['Required.'];
      }
    },
    initPageTitle: function () {
      if (this.$route.query.modify) {
        this.$store.commit('changeMenuTitle', 'Modify Claim')
      } else {
        this.$store.commit('changeMenuTitle', 'Make a Claim')
      }
    },
    initSubMenuTitle: function () {
      this.$store.commit('changeSubMenuTitle', 'Fill Out Claim Form')
    },
    initTopProgressStep: function () {
      this.$store.commit('changeStep', 4)
    },
    moment: function (e) {
      return moment();
    },
    toTop() {
      this.$vuetify.goTo(0, {container: document.documentElement})
    },
    onScroll(e) {
      if (typeof window === 'undefined') {
        return
      }
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.is_show_fav_icon = top > 10
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {

        // Before adding a file
        // Filter system files or hide files
        if(newFile.size ===0){
          alert('You can\'t add empty file')
          return prevent()
        }else if (newFile.size>5000000){
          alert('You can\'t add file exceeds 5MB')
          return prevent()
        }

        if (!(newFile.name.indexOf('pdf')!==-1||
            newFile.name.indexOf('doc')!==-1||
            newFile.name.indexOf('docx')!==-1||
            newFile.name.indexOf('txt')!==-1||
            newFile.name.indexOf('jpg')!==-1||
            newFile.name.indexOf('jpeg')!==-1||
            newFile.name.indexOf('msg')!==-1||
            newFile.name.indexOf('png')!==-1)) {
          alert('You can\'t attach unsupported file. \nSupport File : pdf,doc,docx,txt,jpg,jpeg,msg,png')
          return prevent()
        }

        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent()
        }
        // Filter php html js file
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent()
        }
        // Automatic compression
        // if (newFile.file && newFile.type.substr(0, 6) === 'image/' && this.autoCompress > 0 && this.autoCompress < newFile.size) {
        //   newFile.error = 'compressing'
        //   const imageCompressor = new ImageCompressor(null, {
        //     convertSize: Infinity,
        //     maxWidth: 512,
        //     maxHeight: 512,
        //   })
        //   imageCompressor.compress(newFile.file)
        //       .then((file) => {
        //         this.$refs.upload.update(newFile, {error: '', file, size: file.size, type: file.type})
        //       })
        //       .catch((err) => {
        //         this.$refs.upload.update(newFile, {error: err.message || 'compress'})
        //       })
        // }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        // Create a blob field
        newFile.blob = ''
        let URL = window.URL || window.webkitURL
        if (URL && URL.createObjectURL) {
          newFile.blob = URL.createObjectURL(newFile.file)
        }
        // Thumbnails
        newFile.thumb = ''
        if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
          newFile.thumb = newFile.blob
        }
      }
    },
    // add, update, remove File Event
    inputFile(newFile, oldFile) {
      function readFile(file, cb) {
        let FR = new FileReader();
        FR.readAsDataURL(file);
        FR.onloadend = () => {
          cb(FR.result);
        };
      }
      try{
        const file = newFile.file;


        if (file) {
          new Compressor(file, {
            success(result) {
              readFile(result, cb => {
                newFile.base64 = cb;
              });
            },
            error(err) {
              readFile(file, cb => {
                newFile.base64 = cb;
              });
            },
          });
        }
      }
      catch (e){
        console.log(e)
      }
    },
    alert(message) {
      alert(message)
    },
    showDeletePopup(parent,list,item) {
      this.dialog = true
      this.toRemoveItem = item
      this.toRemoveList = list
      this.toRemoveParent = parent
    },
    removeItem(){
      this.removeItemFromList(this.toRemoveParent,this.toRemoveList)
      this.dialog = false
      this.toRemoveItem = null
      this.toRemoveList= null
      this.toRemoveParent= null
    },
    removeItemFromList(parent,list){
      let self = this;
      const idx = list.findIndex(function(item) {return item.id === self.toRemoveItem.id})
      if (idx > -1) list.splice(idx, 1)
      if (list.length ===0 ) {

        for (var i = 0, len = parent.length; i < len; i++) {
          if (parent[i].documents && parent[i].documents.length===0){
            delete parent[i].documents;
          }
          if (parent[i].medicaldocuments && parent[i].medicaldocuments.length===0){
            delete parent[i].medicaldocuments;
          }
        }

      }
    },
    deleteItem(list, item) {
      list.splice(list.findIndex(e => e.id === item.id), 1);
    },
    goPrevious() {
      this.$router.back()
    },
    handleResizeDeathContent(){
      if (this.IsAccidentalDeathCoverageType)
      this.deathtitleHeight = this.$refs.deathTitle.clientHeight
    },
    handleResizeSupportDocumentContent(){
      // console.log(this.supportDocumentAttachments.length)
      // var parent = this.$refs.supportDocumentsRef
      // console.log(parent.)
      // console.log(this.$refs)
      //this.$refs.
    },
    handleFlexibleContentSize(){
      window.addEventListener('resize', this.handleResizeDeathContent);
      this.handleResizeDeathContent();
      this.handleResizeSupportDocumentContent();
    },
    checkBankName(){
      if (!this.transferDetailInfo.bankname.includes('OTH')){
        this.transferDetailInfo.otherbankname=''
      }
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    isBankCodeOrBranchCode: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    isAlphaNumeric:function (evt){
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (!(charCode > 47 && charCode < 58) && // numeric (0-9)
          !(charCode > 64 && charCode < 91) && // upper alpha (A-Z)
          !(charCode > 96 && charCode < 123)) {
        evt.preventDefault();
      } else {
        return true;
      }
    }
  },
  filters: {
    moment: function (date) {
      var result = moment(date).format('DD/MM/YY')
      if (result === 'Invalid date') {
        return ''
      } else {
        return moment(date).format('DD/MM/YY')
      }
    }
  },
  mounted() {
    this.initPageTitle()
    this.initSubMenuTitle()
    this.initTopProgressStep()

    this.handleFlexibleContentSize()
    this.$vuetify.goTo(0, {container: document.documentElement,duration:0})
  },
  created() {
    if (this.documentInfo.attachments.length > 0) {
      this.supportDocumentAttachments = JSON.parse(JSON.stringify(this.documentInfo.attachments))
    } else {
      this.supportDocumentAttachments = JSON.parse(JSON.stringify(this.getDefaultSupportDocumentList()))
    }

    if (this.medicalLeaveInfo.medicaldocuments.length > 0) {
      this.medicalDocumentAttachments = JSON.parse(JSON.stringify(this.medicalLeaveInfo.medicaldocuments))
    } else {
      this.medicalDocumentAttachments = JSON.parse(JSON.stringify(this.getDefaultMedicalDocumentList()))
    }

  },
  destroyed() {
    window.removeEventListener('resize', this.handleResizeDeathContent);
  },


}
</script>
<style scoped>
.fileItemHolder {
  position: relative;
  text-align: center;
}

.file-remove-icon {
  position: absolute;
  top: 25px;
  margin-left: -25px;
  z-index: 1;
  text-align: center;
  background: #000000 no-repeat padding-box;
  zoom: 1;
  filter: alpha(opacity=50);
  opacity: 0.45;
  border-radius: 50%;
  padding: 4px;
}

* {
  text-transform: none !important;
}

.add-photo-button {
  display: flex !important;
}

.file-text-span {
  white-space: nowrap;
  min-width: 140px
}

.hide-element {
  visibility: hidden;
}

li div {
  position: relative;
  display: block;
  line-height: 27px;
  padding-right: 80px;
  color: #333;
}

li div span {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

li div img {
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 3px
}

.orderIcon {
  background: #00CCBC;
  border-radius: 3px;
  width: 26px;
  height: 26px;
  opacity: 1;
  text-align: center;
  color: #FFFFFF;
}

.blueIcon {
  background: #006D9E !important;
  border-radius: 3px;
  width: 26px;
  height: 26px;
  opacity: 1;
  text-align: center;
  color: #FFFFFF;
  padding: 5px;
}

.deleteButton {
  width: 20px;
  height: 20px;
  background-color: #FFFFFF;
  padding: 3px;
  vertical-align: middle;
}

.menu-divider-top {
  border-top: #47494E 1px solid;
  padding-top: 20px;
}
.action-button {
  text-transform: none !important;
  font-size: 20px;
}
.section-title{
  color: #37424A;
  font-size: 24px;
}
.content-title{
  word-break:break-all;
  white-space:pre-line;
}
</style>

