<template>
  <div/>
</template>

<script>
export default {
  data: () => ({
    valid: true
  }),

  methods: {
    confirmBoxTest() {
      this.$root.$confirm
        .open(
          "Confirm",
          "Please confirm that you would like to complete",
          "Confirm"
        )
        .then(confirm => {
          if (confirm) {
            // do Okay activity
          } else {
            //do cancel activity
          }
        });
    }
  }
};
</script>
