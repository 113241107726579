<template>

  <v-container fluid style="background: #F4F4F4">
    <v-layout>
      <v-flex sm2></v-flex>
      <v-flex xs12 sm8 class="px-3">
        <v-layout row pa-2 pb-1 style="display: flex !important; flex-wrap: wrap !important">
          <v-card-title class="font-weight-bold pt-1 pb-2">What would you like to claim for?</v-card-title>
          <v-card-text class="font-weight-bold py-2" style="color:#00A8C8;font-size: 18px">Please note:</v-card-text>
          <v-flex xs12 pa-2 py-0 px-2 ma-1 my-0>
            <v-card pa-2 color="#F2FBFC" style="border: solid 1px #00A8C8;">
              <v-card-text class="mb-0 pb-0 note-content" style="color: red !important; font-size: 18px;white-space:pre-line;">
                {{openBannerMsg}}
              </v-card-text>
              <v-card-text class="mb-0 pb-0 note-content">Please note, if you have multiple claims to submit at one time please select the claim type that best suits your claim.</v-card-text>
              <v-card-text class="pt-0 note-content">We will review all available evidence (supporting documents) to determine if you qualify for other benefits.</v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout row pa-2 pt-0 style="display:flex !important; flex-wrap: wrap !important">
          <v-flex xs12 sm12 md6 pa-2
                  v-for="incident in incident_items"
                  v-bind:key="incident.id">
            <v-card
                class="px-6 py-4 ma-1 "
                @click="saveCoverageType(incident)"
                v-bind:class="{ active: incident.isActive }"
                active-class="default-class"
                height="100%"
                min-height="280"
            >
              <v-row align="center"
                     style="min-height: 100px;">
                <v-col class="pa-0" cols=9>
                  <v-card-title class="font-weight-bold">{{ incident.title }}</v-card-title>
                </v-col>
                <v-col cols="3" align="right" class="pr-8 ">
                  <v-img :src="icons.right_arrow_icon" aspect-ratio="1" height="26" width="14"></v-img>
                </v-col>

              </v-row>
              <v-row class="pa-0 ma-0">
                <v-col class="pa-1">
                  <v-divider class="my-n2"></v-divider>
                </v-col>
              </v-row>

              <v-row
                  no-gutters
                  class="pa-1 pt-4 "
              >
                <v-col class="pa-0 pa-0"
                       cols="8"
                >
                  <v-card-subtitle
                      class="ma-0 pa-0"
                  >{{ incident.content }}
                  </v-card-subtitle>
                </v-col>
                <v-col
                    cols="4"
                >
                  <v-row justify="end" class="mr-0 mr-md-n1 mr-lg-4">

                    <v-card
                        elevation="0"
                        :style="{'background-color': incident.background_color_code}"
                        :class="$vuetify.breakpoint.lgAndUp?'desktop-circle':'mobile-circle'"
                        class="d-flex align-center flex-column">
                      <v-img :src="incident.imageUrl" width=50
                             contain style="border-radius:0px"/>
                    </v-card>
                  </v-row>
                </v-col>
              </v-row>
              <v-row v-if="incident.isContainsExtraContent">
                <v-col cols="12">
                  <v-divider class="ma-0 pa-0"></v-divider>
                </v-col>
                <v-col cols="12" class="my-0 py-0">
                  <v-card-text class="red--text pa-0 pb-1 font-weight-bold">
                    <img src="@/assets/icons/required.png" style="max-width: 12px"/> Note :
                  </v-card-text>
                </v-col>
                <v-col cols="12" class="pa-0 ma-0">
                  <v-card-text class="my-0 pt-0 pb-3 primary--text" style="font-size: 14px">
                    The loss of or damage to personal property must have been caused by an assault. Report to police
                    and medical consultation are required.
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>

</template>

<script>
export default {
  components: {},
  data() {
    return {
      icons: {
        personal_accident_icon: require("@/assets/icons/icon_Image_00.png"),
        public_liability_icon: require("@/assets/icons/icon_Image_01.png"),
        right_arrow_icon: require("@/assets/icons/icon_select_arrow_01.png")
      },
      incident_items: [
        {
          id: "0",
          title: "Accidental Medical Expenses Reimbursement",
          content:
              "Medical expenses incurred (cost of medical, surgical or other remedial attention or treatment given or prescribed by a doctor and or hospital connected with a valid claim)",
          imageUrl: require("@/assets/icons/icon_Image_02.png"),
          background_color_code: "#EDF7FF",
          incidenttype: "AMER",
          next_route:
              "/claims-rider",
          isActive: false
        },
        {
          id: "1",
          title: "Permanent Disablement",
          content:
              "Total disablement due to accident",
          imageUrl: require("@/assets/icons/icon_Image_03.png"),
          background_color_code: "#E5FAF8 ",
          incidenttype: "PD",
          next_route: "/claims-rider",
          isActive: false,
        },
        {
          id: "2",
          title: "Loss of or Damage to Personal Property Due to Assault",
          content:
              "An unprovoked assault which results in lost or damaged personal items",
          imageUrl: require("@/assets/icons/icon_Image_04.png"),
          background_color_code: "#F6FBEB ",
          incidenttype: "LDPA",
          next_route: "/claims-rider",
          isActive: false,
          isContainsExtraContent: true,
        },
        {
          id: "3",
          title: "Accidental Death",
          content:
              "Death due to accident",
          imageUrl: require("@/assets/icons/icon_Image_05.png"),
          background_color_code: "#F7F1F9 ",
          incidenttype: "AD",
          next_route: "/claims-rider",
          isActive: false,
        }
      ]
    };
  },
  methods: {
    initPageTitle: function () {
      this.$store.commit("changeMenuTitle", "Make a Claim");
    },
    initSubMenuTitle: function () {
      this.$store.commit("changeSubMenuTitle", "Incident Type");
    },
    changeIsActiveState: function (e) {
      e.isActive = !e.isActive;
    },
    initTopProgressStep: function () {
      this.$store.commit("changeStep", 1);
    },
    saveCoverageType(item) {
      this.$store.commit("saveCoverageType", item.incidenttype);
      this.$router.push(item.next_route);
    },
    ShowClaimProgressBar: function () {
      this.$store.commit("changeShowClaimProgressBarStatus", true);
    },
    resetFormData: function () {
      this.$store.commit("resetFormData");
    }
  },
  computed: {
    openBannerMsg () {
      let country = this.$store.state.country
      let language = this.$store.state.selectedLanguageCode
      switch (country) {
        case 'Hong Kong':

          if (language === 'hk') {
            // eslint-disable-next-line
            return  'Please note that effective from 18 October 2024, substitute riders will no longer be covered under both Deliveroo\'\s Personal Accident and Public Liability Insurance. \n 由 2024 年 10 月 18 日起，代送專員將不再涵蓋於由戶戶送所提供的個人意外和公眾責任保險之保障範圍中。'
          } else {
            // eslint-disable-next-line
            return  'Please note that effective from 18 October 2024, substitute riders will no longer be covered under both Deliveroo\'\s Personal Accident and Public Liability Insurance.'
          }
        default:
          // eslint-disable-next-line
          return  'Please note that effective from 17 October 2024, substitute riders will no longer be covered under both Deliveroo\'\s Personal Accident and Public Liability Insurance.'
      }
  }
  },
  mounted() {
    this.initPageTitle();
    this.initSubMenuTitle();
    this.initTopProgressStep();
    this.ShowClaimProgressBar();
    this.resetFormData();
    this.$vuetify.goTo(0, {container: document.documentElement, duration: 0})
  }
};
</script>
<style scoped>

* {
  text-transform: none !important;
}

.v-card__title {
  line-height: 25px;
  letter-spacing: 0px;
  word-break: break-word;
  color: #37424A;
  font-size: 23px;
}

.v-card__subtitle {
  line-height: 20px;
  letter-spacing: 0px;
  color: #37424A;
  font-size: 17px
}

.mobile-circle {
  border-radius: 100%;
  max-height: 82px;
  max-width: 82px;
  min-height: 82px;
  min-width: 82px;
}

.desktop-circle {
  border-radius: 50%;
  max-height: 94px;
  max-width: 94px;
  min-height: 94px;
  min-width: 94px;
}

.note-content {
  font-size: 16px;
  color: #37424A !important;
}
</style>
