<template>
  <div>
    <v-app>
      <country-selector-popup v-if="!$store.state.countrySelected"></country-selector-popup>
      <header-component v-show="hideHeaderFooter"></header-component>
      <topBar v-if="!hideHeaderFooter && hideTopBar"/>
      <v-main v-bind:style="[!hideHeaderFooter?{background:'#ffffff'}:{background: '#F7F7F7' }]">
        <top-progress v-show="(!hideHeaderFooter)&& this.$store.state.isShowClaimProgressBar" class="pa-0 ma-0"/>
      </v-main>
      <router-view style="height: 100%"/>
      <footer-component v-if="hideHeaderFooter || this.$vuetify.breakpoint.lgAndUp"></footer-component>
    </v-app>
    <v-dialog
        v-model="noClaimDataErrorDialog"
        persistent
        :width="$vuetify.breakpoint.smAndDown?'90%':429"
    >
      <v-card
          color="white"
          class="pt-4 ma-0"
          style="box-shadow: 3px 4px 5px #00000033;border: 2px solid #006D9E !important;border-radius: 10px; padding: 2px"
      >
        <v-card-text class="px-4 py-8 text-body-1 black--text" style="text-align: center">
          {{ this.noClaimDataErrorMessage }}
        </v-card-text>
        <div style="background: #F2FBFC 0% 0% no-repeat padding-box; height: 77px; padding-top: 5px !important;">
          <v-row justify="center" align-content="center" class="ma-0 pa-0">
            <v-col cols="4" align-self="center">
              <v-btn
                  color="#00CCBC"
                  class="ma-0 white--text text-body-1"
                  large
                  block
                  @click="returnToClaimProcessPage()">
                Ok
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import countrySelectorPopup from "@/components/general/shared/country.selector.popup.vue";
import headerComponent from "@/components/general/header/header.component.vue";
import footerComponent from "@/components/general/footer/footer.component.vue";
import topBar from "@/components/common/topBar.vue";
import topProgress from "@/components/common/topProgress.vue";
import axios from 'axios';
import PremiumApi from '@/plugins/axios'

export default {
  name: "App",
  data() {
    return {
      noClaimDataErrorDialog: false,
      noClaimDataErrorMessage: 'Something went wrong and your claim submission data has been lost. Please click below button and claim again',
      response: null,
      ip: null,
      sessionId: null,
    }
  },
  components: {
    countrySelectorPopup,
    headerComponent,
    footerComponent,
    topBar,
    topProgress
  },
  mounted() {

    this.$root.$confirm = this.$refs.confirm;
    PremiumApi.getMasterData(function (data) {
      vm.$store.dispatch("saveMasterData", data)
    });
    this.isNoClaimDataState()

    this.generateAccessLogSessionID();
    axios
        .get("https://www.cloudflare.com/cdn-cgi/trace")
        .then(response => (this.response = response.data))
        .then(response => {
              if (!this.$store.getters.firstAccessLogSent && this.$store.state.country!==''){
                let ip = this.$store.getters.ip.replace(/(\r\n\t|\n|\r\t)/gm,"");
                let sessionId = this.$store.getters.sessionId;
                let accesssLog = {
                  countrycode: this.$store.state.countryCode,
                  url: window.location.href.replace('#',''),
                  uniqueid_user:'',
                  uniqueid_session: sessionId,
                  ip: ip
                }

                this.$store.dispatch("updateFirstAccessLogSent",true);
                PremiumApi.postAccessLog(accesssLog,
                    function (response) {
                    }
                );
              }
            }
        )

  },
  computed: {
    hideTopBar() {
      return this.$route.name !== "ClaimsNotification";
    },
    hideHeaderFooter() {
      if (this.isClaimComponent()) {
        return false;
      }
      return this.$route.name !== "DownloadZip";
    },
    scrollToTop() {
      if (this.$route.name !== "") {
        window.scrollTo(0, 0);
      }
      return true;
    }
  },
  watch: {
    response: function () {
      this.ip = this.response.substring(this.response.search('ip=') + 3, this.response.search('ts='));

      this.$store.dispatch('updateIp', this.ip)
      this.$store.dispatch('updateSessionId', this.sessionId)
    }
  },
  methods: {
    isClaimComponent() {
      return (
          this.$route.name === "ClaimsConfirmation" ||
          this.$route.name === "ClaimsCoverage" ||
          this.$route.name === "ClaimsDeclaration" ||
          this.$route.name === "ClaimsDocumentUpload" ||
          this.$route.name === "ClaimsIncidentDetails" ||
          this.$route.name === "ClaimsIncidentType" ||
          this.$route.name === "ClaimsNotification" ||
          this.$route.name === "ClaimsRegistration" ||
          this.$route.name === "ClaimsRider" ||
          this.$route.name === 'ClaimsAgreement' ||
          this.$route.name === 'Claim_public_liability'

      );
    },
    isNoClaimDataState() {
      if (this.isClaimComponent() && this.$store.state.country === '') {
        if (this.$route.name !== "ClaimsRegistration") {
          //this.noClaimDataErrorDialog = true
          this.$router.push('/claims-registration')
        }
      }
    },
    returnToClaimProcessPage() {
      this.noClaimDataErrorDialog = !this.noClaimDataErrorDialog
      this.$router.push('/claims-registration')
    },

    generateAccessLogSessionID() {

      // Math.random should be unique because of its seeding algorithm.
      // Convert it to base 36 (numbers + letters), and grab the first 9 characters after the decimal.
      // Ref : https://gist.github.com/gordonbrander/2230317

      this.sessionId = '_' + Math.random().toString(36).substr(2, 9);
    }
  },
};
</script>

<style lang="scss">
@import "@/assets/css/brain.scss";
</style>
