<template>
  <div class="my-body">
    <section class="banner-top hidden-xs">
      <div class="container">
        <div class="row">
          <div class="col-xs-6 banner-left">
            <br>
            <br>
            <h1 :lang="getLang" v-html="$t('lang.contact-us.topBanner')"></h1>
          </div>

          <div class="col-xs-6 banner-right contactus-banner">

          </div>
        </div>
      </div>
    </section>

    <section class="banner-top-mobile hidden-lg hidden-sm hidden-md">
      <div class="">
        <div class="">
          <div class="bann-top-mobile-top faq-bann">

          </div>
          <div class="bann-bottom-mobile-bt col-md-12">
            <h1 :lang="getLang" v-html="$t('lang.contact-us.topBanner_M')"></h1>

          </div>
        </div>
      </div>
    </section>
    <!-- for mobile -->

    <!-- contact -->
    <section class="contact_us_wrap">
      <div class="container">
        <h1 :lang="getLang">{{$t('lang.contact-us.forSupportOrQuestions')}}</h1>
        <ul class="contact_us_details">
          <li>
            <span class="contact_call"></span>
            <div class="contact_text">
              <h2 :lang="getLang">{{$t('lang.contact-us.phone')}}</h2>
              <h2>{{$t('lang.contact-us.phoneNumber')}}</h2>
            </div>
          </li>
          <li>
            <span class="contact_mail"></span>
            <div class="contact_text">
              <h2 :lang="getLang">{{$t('lang.contact-us.email')}}</h2>
              <h2>{{$t('lang.contact-us.emailAddress')}}</h2>
            </div>
          </li>
        </ul>
        <br/>
        <h2 :lang="getLang" v-html="$t('lang.contact-us.operatingHours')"></h2>
        <br/><br/>
      </div>
    </section>
    <section class="footer-section hidden-lg">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-sm-7" :lang="getLang" v-html="$t('lang.common.arrangedInPartnership')">
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  components: {
  },
  data () {
    return {
    }
  },
  props: [
  ],
  methods: {
  },
  computed: {
    getLang () {
      return this.$store.state.selectedLanguageCode === 'hk' || this.$store.state.selectedLanguageCode === 'tw' ? 'zh' : 'en'
    }
  }
  ,mounted(){
    this.$vuetify.goTo(0, {container: document.documentElement,duration:0})
  }
}
</script>

<style>
</style>
