<template>
  <v-container id="incident" style="background: #ffffff" fluid justify-center class="ma-0 pa-0">
    <v-layout>
      <v-flex sm2></v-flex>
      <v-flex xs12 sm8>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card
              :tile=true
              class="elevation-0 " style="border-bottom: #47494E 1px solid">
            <v-col class="d-flex justify-space-between align-center pa-0 pr-2">
              <v-toolbar-title class="pa-4 font-weight-bold d-inline-flex justify-space-between section-title">{{ page_title }}
              </v-toolbar-title>
              <v-btn
                  v-if="$vuetify.breakpoint.smAndDown"
                  icon
                  @click="show_incident = !show_incident"
                  style="right: auto">
                <v-icon>{{ show_incident ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-col>
          </v-card>
          <v-card class="elevation-0 px-2">
            <v-expand-transition>
              <div v-if="show_incident">
                <v-layout row wrap justify-left px-1 v-if="show_incident">
                  <v-flex
                      v-if="!IsAccidentalDeathCoverageType"
                      xs12
                      sm12
                      px-4>
                    <v-list-item-title class="my-4">
                      Were you at fault for causing the accident?
                    </v-list-item-title>
                    <v-radio-group
                        v-model="incidentInfo.wasyourfault"
                        row
                        class="ma-0 pa-0">
                      <v-radio
                          v-for="radio in radioButtonOptions"
                          v-bind:key="radio.code"
                          :label="radio.value"
                          :value="radio.code"
                          color="#00CCBC"/>
                    </v-radio-group>
                  </v-flex>

                  <!-- fab icon -->
                  <v-btn
                      v-scroll="onScroll"
                      v-show="is_show_fav_icon && $vuetify.breakpoint.smAndDown"
                      elevation="1"
                      fab
                      fixed
                      bottom
                      right
                      color="blue darken-3"
                      style="margin-bottom: 60px;margin-right: 16px;"
                      @click="toTop">
                    <img :src="icons.bottom_top_arrow" @click="toTop" style="max-width: 20px; max-height: 20px; ">
                  </v-btn>
                </v-layout>
                <v-layout row wrap justify-left px-1 v-if="show_incident">
                  <v-flex xs12 sm6 md4 px-4>
                    <v-list-item-title class="my-4">
                      Incident date (DD/ MM/ YY) <strong class="red--text">*</strong>
                    </v-list-item-title>
                    <v-menu
                        v-model="fromDateMenuIncident"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                    >
                      <template
                          v-slot:activator="{ on }">
                        <v-text-field
                            v-on="on"
                            outlined
                            readonly
                            dense
                            prepend-icon="event"
                            color="primary"
                            placeholder="DD \ MM \ YY"
                            :value="incidentInfo.incidentdate|moment"
                            :rules="[rules.required]"/>
                      </template>
                      <v-date-picker
                          v-model="incidentInfo.incidentdate"
                          no-title
                          locale="en-in"
                          color="primary"
                          :min="minDate"
                          :max="new Date().toISOString().substr(0, 10)"
                          @input="fromDateMenuIncident = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs12 sm6 md4 px-4>
                    <v-list-item-title class="my-4">
                      Incident time (Hours / Minutes) <strong class="red--text">*</strong>
                    </v-list-item-title>
                    <v-item-group >
                      <v-container grid-list-md pa-0 >
                        <v-layout >
                          <v-flex xs6>
                            <v-autocomplete class="ma-0 pa-0"
                                dense
                                outlined
                                v-model="incidentHour"
                                :items="hours"
                                placeholder="Hour"
                                @change="changeHour('hour',incidentHour)"
                                :rules="[rules.required]"
                                :validate-on-blur=true
                            >
                            </v-autocomplete>
                          </v-flex>
                          <v-flex shrink align-self-center class="ma-0  px-2 mt-n8">
                            <p class="ma-0 pa-0" style="font-size: 20px">:</p>
                          </v-flex>
                          <v-flex xs6>
                            <v-autocomplete
                                dense
                                outlined
                                class="ma-0 pa-0"
                                placeholder="Minutes"
                                v-model="incidentMinutes" :items="minutes" @change="changeHour('minutes',incidentMinutes)"
                                :rules="[rules.required]"
                                :validate-on-blur=true> </v-autocomplete>
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-item-group>
                  </v-flex>
                  <v-flex xs12 sm6 md4 px-4>
                    <v-list-item-title class="my-4">
                      Incident location
                      <strong class="red--text">*</strong>
                    </v-list-item-title>
                    <v-text-field
                        v-model="incidentInfo.incidentlocation"
                        color="primary"
                        solo
                        outlined
                        flat
                        dense
                        prepend-icon="home_work"
                        :rules="[rules.required,rules.incidentLocationRule]"
                        :validate-on-blur=true
                        maxlength="100"
                    />
                  </v-flex>
                  <v-flex xs12 sm6 md4 px-4>
                    <v-list-item-title class="my-4">
                      Incident description
                      <strong class="red--text">*</strong>
                    </v-list-item-title>
                    <v-textarea
                        v-model="incidentInfo.incidentdescription"
                        color="primary"
                        solo
                        outlined
                        flat
                        dense
                        :rules="[rules.required,rules.incidentdescriptionRule]"
                        :validate-on-blur=true
                        maxlength="400"/>
                  </v-flex>
                  <v-flex xs12 sm6 md4 px-4>
                    <v-list-item-title class="my-4">
                      Police report number <strong v-if="IsPoliceReportNumberNeeded" class="red--text">*</strong>
                    </v-list-item-title>
                    <v-text-field
                        v-model="incidentInfo.policyreportnumber"
                        color="primary"
                        solo
                        outlined
                        flat
                        dense
                        @keypress="isAlphaNumeric($event)"
                        :rules="IsPoliceReportNumberNeeded?[rules.required,rules.policyReportNumberRule]:[]"
                        :validate-on-blur=true
                        maxlength="20"/>
                  </v-flex>
                  <v-flex xs12 sm6 md4 px-4>
                    <v-list-item-title class="my-4 content-title">
                      Can you provide photos of the incident? <strong class="red--text">*</strong>
                    </v-list-item-title>
                    <v-radio-group
                        v-model="incidentInfo.canprovidephoto"
                        row
                        class="ma-0 pa-0"
                        :rules="[rules.required]">
                      <v-radio
                          v-for="radio in radioButtonOptions"
                          v-bind:key="radio.code"
                          :label="radio.value"
                          :value="radio.code"
                          color="#00CCBC"
                          @change="checkPhotos()"
                          />
                    </v-radio-group>


                    <file-upload
                        v-if="incidentInfo.canprovidephoto==='Y'"
                        :post-action="postAction"
                        :put-action="putAction"
                        :extensions="extensions"
                        :accept="accept"
                        :multiple="multiple"
                        :directory="directory"
                        :size="size || 0"
                        :thread="thread < 1 ? 1 : (thread > 5 ? 5 : thread)"
                        :headers="headers"
                        :data="data"
                        :drop="drop"
                        :drop-directory="dropDirectory"
                        :add-index="addIndex"
                        v-model="incidentInfo.photos"
                        :maximum="maximum"
                        @input-filter="inputFilter"
                        @input-file="inputFile"
                        ref="uploadPhotos"
                        input-id="uploadPhoto"
                        class="add-photo-button">
                      <v-btn block outlined color="#00CCBC" style="background-color: white">
                        <v-icon class="ma-2" style="color: black">{{ 'mdi-plus' }}</v-icon>
                        Add Photos
                      </v-btn>
                    </file-upload>
                    <v-input v-if="incidentInfo.canprovidephoto==='Y' && incidentInfo.photos.length===0" :rules="[rules.fileSize]"
                             v-model="incidentInfo.photos"></v-input>
                  </v-flex>
                  <v-flex xs12 sm4 px-4>
                    <div class="upload" v-if="$vuetify.breakpoint.xs">
                      <p class="mt-4" v-if="incidentInfo.photos.length>0"> Total: <span
                          class="red--text">{{ incidentInfo.photos.length }}</span></p>
                      <v-row v-if="incidentInfo.photos.length>0">
                        <v-col style="overflow-x: auto;background: #F3F3F3;"
                               class="d-inline-flex xs-12 md-12 py-2 ma-4">
                          <div v-for="item in incidentInfo.photos.slice(0,5)" v-bind:key="item.name"
                               class="fileItemHolder"
                               style="padding: 20px;">
                            <img :src="item.thumb" style="width: 100px; height: 100px; max-width: 100px;"/>
                            <img :src="require('@/assets/icons/delete.png')" style="width: 20px; height: 20px;"
                                 class="file-remove-icon" @click="showDeletePopup(incidentInfo.photos,item)"/>
                            <div class="ma-0 caption" style="max-width: 100px;max-height: 30px;">{{ item.name }}</div>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row v-if="incidentInfo.photos.length>6">
                        <v-col style="overflow-x: auto;background: #F3F3F3;"
                               class="d-inline-flex xs-12 md-12 py-2 ma-4">
                          <div v-for="item in incidentInfo.photos.slice(5,10)" v-bind:key="item.name"
                               class="fileItemHolder"
                               style="padding: 20px;">
                            <img :src="item.thumb" style="width: 100px; height: 100px; max-width: 100px;"/>
                            <img :src="require('@/assets/icons/delete.png')" style="width: 20px; height: 20px;"
                                 class="file-remove-icon" @click="showDeletePopup(incidentInfo.photos,item)"/>
                            <div class="ma-0 caption" style="max-width: 100px; max-height: 30px;">{{ item.name }}</div>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </v-flex>
                </v-layout>
                <v-row>
                  <v-col>
                    <div class="upload" v-if="$vuetify.breakpoint.smAndUp">

                      <v-row v-if="incidentInfo.photos.length>0">
                        <v-col style="overflow-x: auto;background: #F3F3F3;" class="d-inline-flex xs-12 md-12 py-2">
                          <div v-for="item in incidentInfo.photos" v-bind:key="item.name"
                               class="fileItemHolder"
                               style="padding: 20px;">
                            <img :src="item.thumb" style="width: 100px; height: 100px; max-width: 100px;"/>
                            <img :src="require('@/assets/icons/delete.png')" style="width: 20px; height: 20px;"
                                 class="file-remove-icon" @click="showDeletePopup(incidentInfo.photos,item)"/>
                            <div class="ma-0 caption" style="max-width: 100px">{{ item.name }}</div>
                          </div>
                        </v-col>
                      </v-row>
                      <p class="mt-4" v-if="incidentInfo.photos.length>0" style="text-align: right"> Total: <span
                          class="red--text">{{ incidentInfo.photos.length }}</span></p>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-expand-transition>
          </v-card>

          <!-- ThirdParty Details -->
          <v-card
              :tile=true
              class="elevation-0 " style="border-bottom: #47494E 1px solid">
            <v-col class="d-flex justify-space-between align-center pa-0 pr-2">
              <v-toolbar-title class="pa-4 font-weight-bold d-inline-flex justify-space-between section-title">Third Party Details
              </v-toolbar-title>
              <v-btn
                  v-if="$vuetify.breakpoint.smAndDown"
                  icon
                  @click="show_third_party = !show_third_party"
                  style="right: auto">
                <v-icon>{{ show_third_party ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-col>
          </v-card>
          <v-card class="elevation-0 px-2">
            <v-expand-transition>
              <div v-if="show_third_party">
                <v-layout row wrap justify-left px-1 v-if="show_third_party">
                  <v-flex
                      xs12
                      sm12
                      px-4>
                    <v-list-item-title class="my-4">
                      Was any third party liable for the incident?
                    </v-list-item-title>
                    <v-radio-group
                        v-model="wasAnyThirdPartyLiableInfo"
                        row
                        class="ma-0 pa-0">
                      <v-radio
                          v-for="radio in radioButtonOptions"
                          v-bind:key="radio.code"
                          :label="radio.value"
                          :value="radio.code"
                          color="#00CCBC"/>

                    </v-radio-group>
                  </v-flex>
                </v-layout>
                <v-layout row wrap justify-left px-1 v-if="wasAnyThirdPartyLiableInfo==='Y'">

                  <v-flex xs12 sm6 md4 px-4>
                    <v-list-item-title class="my-4">
                      First name <strong class="red--text">*</strong>
                    </v-list-item-title>
                    <v-text-field
                        v-model="thirdPartyInfo.firstname"
                        color="primary"
                        solo
                        outlined
                        flat
                        dense
                        :rules="[rules.required]"
                        :validate-on-blur=true
                        maxlength="30"/>
                  </v-flex>

                  <v-flex xs12 sm6 md4 px-4>
                    <v-list-item-title class="my-4">
                      Middle name
                    </v-list-item-title>
                    <v-text-field
                        v-model="thirdPartyInfo.middlename"
                        color="primary"
                        solo
                        outlined
                        flat
                        dense
                        maxlength="30"/>
                  </v-flex>

                  <v-flex xs12 sm6 md4 px-4>
                    <v-list-item-title class="my-4">
                      Last name <strong class="red--text">*</strong>
                    </v-list-item-title>
                    <v-text-field
                        v-model="thirdPartyInfo.lastname"
                        color="primary"
                        solo
                        outlined
                        flat
                        dense
                        :rules="[rules.required]"
                        :validate-on-blur=true
                        maxlength="100"/>
                  </v-flex>
                  <v-flex xs12 sm6 md4 px-4>
                    <v-list-item-title class="my-4">
                      Contact Number
                    </v-list-item-title>
                    <v-text-field
                        v-model="thirdPartyInfo.contactnumber"
                        color="primary"
                        solo
                        outlined
                        flat
                        dense
                        @keypress="isNumber($event)"
                        :rules="['Hong Kong'===$store.state.country? rules.hongKongNumberRule:rules.singaporeNumberRule,rules.numberrule]"
                        :prefix="('Hong Kong'===$store.state.country?'(+852)':'(+65)')"
                        :validate-on-blur=true
                        maxlength="8"/>
                  </v-flex>
                  <v-flex xs12 sm6 md4 px-4>
                    <v-list-item-title class="my-4">
                      Email
                    </v-list-item-title>
                    <v-text-field
                        v-model="thirdPartyInfo.email"
                        color="primary"
                        solo
                        outlined
                        flat
                        dense
                        :rules="[rules.emailValidation]"
                        :validate-on-blur=true
                        maxlength="140"/>
                  </v-flex>
                  <v-flex xs12 sm0 md4 px-4 class="d-none d-md-flex"/>
                  <v-flex xs12 sm6 md4 px-4>
                    <v-list-item-title class="my-4">
                      Country
                    </v-list-item-title>
                    <v-text-field
                        v-model="GetCountryName"
                        readonly
                        color="primary"
                        solo
                        outlined
                        flat
                        dense></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm6 md4 px-4>
                    <v-list-item-title class="my-4">
                      District
                    </v-list-item-title>
                    <v-text-field
                        v-model="thirdPartyInfo.district"
                        color="primary"
                        solo
                        outlined
                        flat
                        dense/>
                  </v-flex>
                  <v-flex xs12 sm6 md4 px-4>
                    <v-list-item-title class="my-4">
                      Address 1
                    </v-list-item-title>
                    <v-text-field
                        v-model="thirdPartyInfo.address1"
                        color="primary"
                        solo
                        outlined
                        flat
                        dense
                        :rules="[rules.address1Rule]"
                        :validate-on-blur=true
                        maxlength="140"/>
                  </v-flex>
                  <v-flex xs12 sm6 md4 px-4>
                    <v-list-item-title class="my-4">
                      Address 2
                    </v-list-item-title>
                    <v-text-field
                        v-model="thirdPartyInfo.address2"
                        color="primary"
                        solo
                        outlined
                        flat
                        dense
                        :rules="[rules.address2Rule]"
                        :validate-on-blur=true
                        maxlength="140"/>
                  </v-flex>
                  <v-flex xs12 sm6 md4 px-4>
                    <v-list-item-title class="my-4">
                      Address 3
                    </v-list-item-title>
                    <v-text-field
                        v-model="thirdPartyInfo.address3"
                        color="primary"
                        solo
                        outlined
                        flat
                        dense
                        :rules="[rules.address3Rule]"
                        :validate-on-blur=true
                        maxlength="140"/>
                  </v-flex>
                  <v-flex xs12 sm6 md4 px-4>
                    <v-list-item-title class="my-4" v-if="GetCountryName==='Singapore'">
                      Postal Code
                    </v-list-item-title>
                    <v-list-item-title class="my-4" v-if="GetCountryName=='Hong Kong'">
                      Address 4
                    </v-list-item-title>
                    <v-text-field
                        v-model="thirdPartyInfo.address4"
                        color="primary"
                        solo
                        outlined
                        flat
                        dense
                        @keypress="GetCountryName==='Singapore'?isNumber($event):''"
                        :rules="GetCountryName==='Singapore'?[rules.postalCodeRule,rules.numberrule]:[rules.address4Rule]"
                        :validate-on-blur=true
                        :maxlength="GetCountryName==='Singapore'?6:140"/>
                  </v-flex>
                </v-layout>
              </div>
            </v-expand-transition>
          </v-card>

          <!-- Witness Details -->
          <v-card
              :tile=true
              class="elevation-0 " style="border-bottom: #47494E 1px solid">
            <v-col class="d-flex justify-space-between align-center pa-0 pr-2">
              <v-toolbar-title class="pa-4 font-weight-bold d-inline-flex justify-space-between section-title">Witness Details
              </v-toolbar-title>
              <v-btn
                  v-if="$vuetify.breakpoint.smAndDown"
                  icon
                  @click="show_witness = !show_witness"
                  style="right: auto">
                <v-icon>{{ show_witness ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-col>
          </v-card>
          <v-card class="elevation-0 px-2">
            <v-expand-transition>
              <div v-if="show_witness">
                <v-layout row wrap justify-left px-1 v-if="show_witness">
                  <v-flex
                      xs12
                      sm12
                      px-4>
                    <v-list-item-title class="my-4">
                      Was the accident witnessed by anyone?
                    </v-list-item-title>
                    <v-radio-group
                        name="wereThereAnyWitnessInfo"
                        v-model="wereThereAnyWitnessInfo"
                        row
                        class="ma-0 pa-0">
                      <v-radio
                          v-for="radio in radioButtonOptions"
                          v-bind:key="radio.code"
                          :label="radio.value"
                          :value="radio.code"
                          color="#00CCBC"/>
                    </v-radio-group>
                  </v-flex>
                </v-layout>
                <v-layout row wrap justify-left px-1 v-if="wereThereAnyWitnessInfo==='Y'">

                  <v-flex xs12 sm6 md4 px-4>
                    <v-list-item-title class="my-4">
                      First name <strong class="red--text">*</strong>
                    </v-list-item-title>
                    <v-text-field
                        name="witnessInfo.firstname"
                        v-model="witnessInfo.firstname"
                        color="primary"
                        solo
                        outlined
                        flat
                        dense
                        :rules="[rules.required]"
                        maxlength="30"/>
                  </v-flex>

                  <v-flex xs12 sm6 md4 px-4>
                    <v-list-item-title class="my-4">
                      Middle name
                    </v-list-item-title>
                    <v-text-field
                        v-model="witnessInfo.middlename"
                        color="primary"
                        solo
                        outlined
                        flat
                        dense
                        maxlength="30"/>
                  </v-flex>

                  <v-flex xs12 sm6 md4 px-4>
                    <v-list-item-title class="my-4">
                      Last name <strong class="red--text">*</strong>
                    </v-list-item-title>
                    <v-text-field
                        v-model="witnessInfo.lastname"
                        color="primary"
                        solo
                        outlined
                        flat
                        dense
                        :rules="[rules.required]"
                        maxlength="100"/>
                  </v-flex>
                  <v-flex xs12 sm6 md4 px-4>
                    <v-list-item-title class="my-4">
                      Contact Number
                    </v-list-item-title>
                    <v-text-field
                        v-model="witnessInfo.contactnumber"
                        color="primary"
                        solo
                        outlined
                        flat
                        dense
                        @keypress="isNumber($event)"
                        :rules="['Hong Kong'===$store.state.country? rules.hongKongNumberRule:rules.singaporeNumberRule,rules.numberrule]"
                        :prefix="('Hong Kong'===$store.state.country?'(+852)':'(+65)')"
                        :validate-on-blur=true
                        maxlength="8"/>
                  </v-flex>
                  <v-flex xs12 sm6 md4 px-4>
                    <v-list-item-title class="my-4">
                      Email
                    </v-list-item-title>
                    <v-text-field
                        v-model="witnessInfo.email"
                        color="primary"
                        solo
                        outlined
                        flat
                        dense
                        :rules="[rules.emailValidation]"
                        :validate-on-blur=true
                        maxlength="140"/>
                  </v-flex>
                  <v-flex
                      xs12
                      sm6 md4
                      px-4>
                    <v-list-item-title class="my-4 content-title">
                      Can you provide a witness statement?
                    </v-list-item-title>
                    <v-radio-group
                        v-model="witnessInfo.canprovidewitness"
                        row
                        class="ma-0 pa-0">
                      <v-radio
                          v-for="radio in radioButtonOptions"
                          v-bind:key="radio.code"
                          :label="radio.value"
                          :value="radio.code"
                          color="#00CCBC"
                      @change="checkStatement"/>
                    </v-radio-group>
                    <v-card elevation="0" class="ma-0 pa-0 d-md-none" v-if="witnessInfo.canprovidewitness==='Y'">
                      <file-upload
                          :post-action="postAction"
                          :put-action="putAction"
                          :multiple="multiple"
                          :directory="directory"
                          :size="size || 0"
                          :thread="thread < 1 ? 1 : (thread > 5 ? 5 : thread)"
                          :headers="headers"
                          :accept="acceptDocuments"
                          :data="data"
                          :drop="drop"
                          :drop-directory="dropDirectory"
                          :add-index="addIndex"
                          v-model="witnessInfo.statementproof"
                          :maximum="maximum"
                          @input-filter="inputFilter"
                          @input-file="inputFile"
                          ref="uploadProofMobile"
                          input-id="uploadProofMobile"
                          style="width: 100%;">
                        <v-btn block outlined color="#00CCBC" style="background-color: white">
                          <v-icon class="pa-2" style="color: black">{{ 'mdi-plus' }}</v-icon>
                          Add statement
                        </v-btn>
                        <v-input v-if="witnessInfo.canprovidewitness==='Y' && witnessInfo.statementproof.length===0" :rules="[rules.statementSize]"
                                 v-model="incidentInfo.photos"></v-input>
                      </file-upload>
                      <p class="mt-4" v-if="witnessInfo.canprovidewitness==='Y' && witnessInfo.statementproof.length>0"> Total: <span
                          class="red--text">{{ witnessInfo.statementproof.length }}</span></p>

                      <v-row class="justify-center">
                        <div v-if="witnessInfo.canprovidewitness==='Y' && witnessInfo.statementproof.length>0"
                             style="background-color: #F4F4F4;border: 1px solid #EBEBEB;border-radius: 3px; width: 90%;">
                          <ul style="padding: 0px; list-style-type: disc">

                            <v-row v-for="item in witnessInfo.statementproof" v-bind:key="item.value"
                                   class="justify-center align-center ma-0 px-2" style="width: 100%;">
                              <li style="width: 90%">
                                <div>
                                <span class="file-text-span"
                                      style="color: #37424A; vertical-align: middle;">{{ item.name }}</span>
                                  <img :src="require('@/assets/icons/delete.png')"
                                       style="width: 20px; height: 20px; background-color: #93A1B4; padding: 5px;border-radius: 50%;vertical-align: middle; "
                                       @click="showDeletePopup(witnessInfo.statementproof,item)"/>
                                </div>
                              </li>
                            </v-row>

                          </ul>
                        </div>
                      </v-row>
                    </v-card>

                  </v-flex>

                  <v-flex xs12 sm6 md4 px-4>
                    <v-list-item-title class="my-4">
                      Country
                    </v-list-item-title>
                    <v-text-field
                        v-model="GetCountryName"
                        color="primary"
                        solo
                        outlined
                        flat
                        readonly
                        dense></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm6 md4 px-4>
                    <v-list-item-title class="my-4">
                      District
                    </v-list-item-title>
                    <v-text-field
                        v-model="witnessInfo.district"
                        color="primary"
                        solo
                        outlined
                        flat
                        dense/>
                  </v-flex>
                  <v-flex xs12 sm6 md4 px-4 class="d-none d-md-inline-block" style="max-height: 00px" v-if="witnessInfo.canprovidewitness==='N'">
                  </v-flex>
                  <v-flex xs12 sm6 md4 px-4 class="d-none d-md-inline-block" style="max-height: 00px" v-if="witnessInfo.canprovidewitness==='Y'">

                    <v-list-item-title class="my-4">
                      Attach statement proof
                    </v-list-item-title>
                    <file-upload
                        :accept="acceptDocuments"
                        :post-action="postAction"
                        :put-action="putAction"
                        :multiple="multiple"
                        :directory="directory"
                        :size="size || 0"
                        :thread="thread < 1 ? 1 : (thread > 5 ? 5 : thread)"
                        :headers="headers"
                        :data="data"
                        :drop="drop"
                        :drop-directory="dropDirectory"
                        :add-index="addIndex"
                        v-model="witnessInfo.statementproof"
                        :maximum="5"
                        @input-filter="inputFilter"
                        @input-file="inputFile"
                        ref="uploadProofDesktop"
                        input-id="uploadProofDesktop"
                        style="width: 100%;">
                      <v-btn block outlined color="#00CCBC" style="background-color: white">
                        <v-icon class="ma-2" style="color: black">{{ 'mdi-plus' }}</v-icon>
                        Add statement
                      </v-btn>
                    </file-upload>
                    <v-input v-if="witnessInfo.canprovidewitness==='Y' && witnessInfo.statementproof.length===0" :rules="[rules.statementSize]"
                             v-model="incidentInfo.photos"></v-input>
                    <p class="mt-4" v-if="witnessInfo.canprovidewitness==='Y'&& witnessInfo.statementproof.length>0"> Total: <span
                        class="red--text">{{ witnessInfo.statementproof.length }}</span></p>

                    <div v-if="witnessInfo.canprovidewitness==='Y' && witnessInfo.statementproof.length>0"
                         style="background-color: #F4F4F4;border: 1px solid #EBEBEB;border-radius: 3px;">
                      <ul style="padding: 0px; list-style-type: none">

                        <v-row v-for="item in witnessInfo.statementproof" v-bind:key="item.name"
                               class="justify-space-between align-center" style="width: 100%; padding-left: 20px">
                          <li style="width: 100%;padding-right: 7%">
                            <div>
                              <span class="file-text-span"
                                    style="color: #37424A; vertical-align: middle;">&#9679;  {{ item.name }}</span>
                              <img :src="require('@/assets/icons/delete.png')"
                                   style="width: 20px; height: 20px; background-color: #93A1B4; padding: 5px;border-radius: 50%;vertical-align: middle; "
                                   @click="showDeletePopup(witnessInfo.statementproof,item)"/>
                            </div>
                          </li>
                        </v-row>

                      </ul>
                    </div>
                  </v-flex>
                  <v-flex xs12 sm6 md4 px-4>
                    <v-list-item-title class="my-4">
                      Address 1
                    </v-list-item-title>
                    <v-text-field
                        v-model="witnessInfo.address1"
                        color="primary"
                        solo
                        outlined
                        flat
                        dense
                        :rules="[rules.address1Rule]"
                        :validate-on-blur=true
                        maxlength="140"/>
                  </v-flex>
                  <v-flex xs12 sm6 md4 px-4>
                    <v-list-item-title class="my-4">
                      Address 2
                    </v-list-item-title>
                    <v-text-field
                        v-model="witnessInfo.address2"
                        color="primary"
                        solo
                        outlined
                        flat
                        dense
                        :rules="[rules.address2Rule]"
                        :validate-on-blur=true
                        maxlength="140"/>
                  </v-flex>
                  <v-flex xs12 sm4 px-4 class="d-none d-md-flex">
                  </v-flex>
                  <v-flex xs12 sm6 md4 px-4>
                    <v-list-item-title class="my-4">
                      Address 3
                    </v-list-item-title>
                    <v-text-field
                        v-model="witnessInfo.address3"
                        color="primary"
                        solo
                        outlined
                        flat
                        dense
                        :rules="[rules.address3Rule]"
                        :validate-on-blur=true
                        maxlength="140"/>
                  </v-flex>
                  <v-flex xs12 sm6 md4 px-4>
                    <v-list-item-title class="my-4" v-if="GetCountryName==='Singapore'">
                      Postal Code
                    </v-list-item-title>
                    <v-list-item-title class="my-4" v-if="GetCountryName=='Hong Kong'">
                      Address 4
                    </v-list-item-title>
                    <v-text-field
                        v-model="witnessInfo.address4"
                        color="primary"
                        solo
                        outlined
                        flat
                        dense
                        @keypress="GetCountryName==='Singapore'?isNumber($event):''"
                        :rules="GetCountryName==='Singapore'?[rules.postalCodeRule,rules.numberrule]:[rules.address4Rule]"
                        :validate-on-blur=true
                        :maxlength="GetCountryName==='Singapore'?6:140"/>
                  </v-flex>
                </v-layout>
              </div>

            </v-expand-transition>
          </v-card>
          <div v-if="IsAMERCoverageType||IsPersonalDisabilityCoverageType">
            <v-card
                :tile=true
                class="elevation-0 " style="border-bottom: #47494E 1px solid">
              <v-col class="d-flex justify-space-between align-center pa-0 pr-2">
                <v-toolbar-title class="pa-4 font-weight-bold d-inline-flex justify-space-between section-title">Injury details
                </v-toolbar-title>
                <v-btn
                    v-if="$vuetify.breakpoint.smAndDown"
                    class="d-sm-none"
                    icon
                    @click="show_injury = !show_injury"
                    style="right: auto">
                  <v-icon>{{ show_injury ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </v-btn>
              </v-col>
            </v-card>
            <v-card class="elevation-0 px-2">
              <v-expand-transition>
                <div v-if="show_injury">
                  <v-layout row wrap justify-left px-1>

                    <v-flex xs12 sm6 md4 px-4>
                      <v-list-item-title class="my-4">
                        Injury Category <strong class="red--text">*</strong>
                      </v-list-item-title>
                      <v-select
                          name="location"
                          v-model="injuryInfo.location"
                          outlined
                          dense
                          color="primary"
                          item-value="code"
                          item-text="value"
                          :items="injuryLocationOptions"
                          :label="'Select'"
                          :single-line=true
                          :rules="[rules.required]"
                      />
                    </v-flex>

                    <v-flex xs12 sm6 md4 px-4>
                      <v-list-item-title class="my-4">Injury Type <strong class="red--text">*</strong></v-list-item-title>
                      <v-select
                          name="injurytype"
                          v-model="injuryInfo.injurytype"
                          outlined
                          dense
                          color="primary"
                          item-value="code"
                          item-text="value"
                          :items="injuryTypeOptionsFiltered"
                          :label="'Select'"
                          :single-line=true
                          :rules="[rules.required]"
                      />
                    </v-flex>

                    <v-flex xs12 sm6 md4 px-4>
                      <v-list-item-title class="my-4">
                        Others (please describe)
                      </v-list-item-title>
                      <v-text-field
                          v-model="injuryInfo.other"
                          color="primary"
                          solo
                          outlined
                          flat
                          dense
                          maxlength="1000"/>
                    </v-flex>
                  </v-layout>
                </div>
              </v-expand-transition>
            </v-card>
          </div>
        </v-form>

        <v-flex xs12 sm12 v-if="!this.$route.query.modify">
          <v-btn
              x-large
              depressed
              block
              :tile=true
              class="ma-0 pa-0 white--text d-md-flex d-lg-none action-button"
              color="#00CCBC"
              @click="validateAndGoNext">Next
          </v-btn>
        </v-flex>

        <v-flex xs12 sm12 v-if="this.$route.query.modify">
          <v-btn
              x-large
              depressed
              block
              :tile=true
              class="ma-0 pa-0 white--text d-md-flex d-lg-none action-button"
              color="#00CCBC"
              @click="validateAndApplyChange">
            Apply Change
            <v-icon>{{ 'mdi-chevron-right' }}</v-icon>
          </v-btn>
        </v-flex>
        <v-flex xs12 sm12 v-if="this.$route.query.modify">
          <v-btn
              x-large
              depressed
              block
              :tile=true
              class="ma-0 pa-0 white--text d-md-flex d-lg-none action-button"
              color="#93A1B4"
              @click="goPrevious">Cancel Change
            <v-icon>{{ 'mdi-chevron-right' }}</v-icon>
          </v-btn>
        </v-flex>
      </v-flex>
      <v-flex sm2>

      </v-flex>
    </v-layout>
    <v-layout class="d-none d-lg-flex mb-8 " :tile="true">
      <v-flex lg2></v-flex>
      <v-flex xs12 lg8 :class="$vuetify.breakpoint.mdAndUp?'menu-divider-top':''" v-if="this.$route.query.modify">
        <v-row>
          <v-col offset="3" cols="3" class=" pr-2">
            <v-btn
                x-large
                depressed
                :tile="true"
                block
                color="#93A1B4"
                class="white--text"
                style="text-transform: none !important;font-size: 20px"
                @click="goPrevious()"
            >
              <v-icon>{{ 'mdi-chevron-left' }}</v-icon>
              Cancel Change
            </v-btn>
          </v-col>
          <v-col cols="3" class="pl-2">
            <v-btn
                x-large
                depressed
                :tile="true"
                block
                color="#00CCBC"
                class="white--text"
                style="text-transform: none !important;text-align: right;font-size: 20px;"
                @click="validateAndApplyChange"
            >Apply Change
              <v-icon>{{ 'mdi-chevron-right' }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-flex>
      <v-flex xs12 lg8 :class="$vuetify.breakpoint.mdAndUp?'menu-divider-top':''" v-if="!this.$route.query.modify">
        <v-row>
          <v-col offset="3" cols="3" class=" pr-2">
            <v-btn
                x-large
                depressed
                :tile="true"
                block
                color="#93A1B4"
                class="white--text"
                style="text-transform: none !important;font-size: 20px"
                @click="goPrevious()"
            >
              <v-icon>{{ 'mdi-chevron-left' }}</v-icon>
              Previous

            </v-btn>
          </v-col>
          <v-col cols="3" class="pl-2">

            <v-btn
                x-large
                depressed
                :tile="true"
                block
                color="#00CCBC"
                class="white--text"
                style="text-transform: none !important;text-align: right;font-size: 20px"
                @click="validateAndGoNext"
            >Next
              <v-icon>{{ 'mdi-chevron-right' }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
    <v-dialog
        v-model="dialog"
        persistent
        :width="$vuetify.breakpoint.smAndDown?'90%':429"
    >
      <v-card
          color="white"
          class="pt-4 ma-0"
          style="box-shadow: 3px 4px 5px #00000033;border: 2px solid #006D9E !important;border-radius: 10px; padding: 2px"
      >
        <v-icon style="float: right;padding-right: 16px" @click="dialog=!dialog">clear</v-icon>
        <v-card-text class="px-4 py-8 text-body-1 black--text" style="text-align: center">
          Are you sure you want to<br/> delete this photo?
        </v-card-text>
        <div style="background: #F2FBFC 0% 0% no-repeat padding-box; height: 77px; padding-top: 5px !important;">
          <v-row justify="center" align-content="center" class="ma-0 pa-0">
            <v-col cols="6" align-self="center">
              <v-btn
                  color="#93A1B4"
                  class="ma-0  white--text text-body-1"
                  large
                  block
                  @click="dialog=!dialog">
                Cancel
              </v-btn>
            </v-col>
            <v-col cols="6" align-self="center">
              <v-btn
                  color="#00CCBC"
                  class="ma-0 white--text text-body-1"
                  large
                  block
              @click="removeItem()">
                Ok
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from 'moment';

import Compressor from 'compressorjs';
import FileUpload from "vue-upload-component";
import {mapGetters, mapMutations} from "vuex";

export default {
  components: {
    FileUpload,
  },
  data() {
    return {
      incidentHour:'',
      incidentMinutes:'',
      hours: Array.from(Array(24), (_,x) => x.toLocaleString('en-US',{minimumIntegerDigits:2})),
      minutes:Array.from(Array(60), (_,x) => x.toLocaleString('en-US',{minimumIntegerDigits:2})),
      dialog: false,
      toRemoveItem: null,
      toRemoveList: null,
      valid: false,
      page_title: 'Incident Details',
      show_incident: true,
      show_third_party: true,
      show_witness: true,
      show_injury: true,
      is_show_fav_icon: true,
      icons: {
        personal_accident_icon: require('@/assets/icons/icon_Image_00.png'),
        public_liability_icon: require('@/assets/icons/icon_Image_01.png'),
        right_arrow_icon: require('@/assets/icons/icon_select_arrow_01.png'),
        bottom_top_arrow: require('@/assets/icons/bottom_top_arrow.png'),
        date_icon: require('@/assets/icons/icon_Image_09.png'),
      },
      fromDateMenu: false,
      fromTimeMenu: false,
      minDate: "2020-01-01",
      pickerDate: moment().format('YYYY[-]MM[-]DD'),
      maxDate: moment().format('YYYY[-]MM[-]DD'),
      fromDateMenuIncident: false,
      accept: 'image/png,image/jpeg',
      acceptDocuments: 'image/png,image/jpeg,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      extensions: 'jpg,jpeg,png,webp',
      minSize: 1024,
      size: 1024 * 1024 * 10,
      maximum: 10,
      multiple: true,
      directory: false,
      drop: false,
      dropDirectory: false,
      addIndex: false,
      thread: 3,
      name: 'file',
      postAction: '/upload/post',
      putAction: '/upload/put',
      headers: {
        'X-Csrf-Token': 'xxxx',
      },
      data: {
        '_csrf_token': 'xxxxxx',
      },
      autoCompress: 1024 * 1024,
      uploadAuto: false,
      addData: {
        show: false,
        name: '',
        type: '',
        content: '',
      },
      editFile: {
        show: false,
        name: '',
      },

      incidentInfo: this.getIncident(),
      wasAnyThirdPartyLiableInfo: this.getWasAnyThirdPartyLiable(),
      thirdPartyInfo: this.getThirdParty(),
      wereThereAnyWitnessInfo: this.getWereThereAnyWitness(),
      witnessInfo: this.getWitness(),
      injuryInfo: this.getInjury(),
      rules: {
        fileSize: value => this.incidentInfo.photos>0 || "This field is mandatory",
        statementSize: value => this.witnessInfo.statementproof>0 || "This field is mandatory",
        requiredTime: value => (0<=value) || "This field is mandatory",
        required: value => (!!value && value.trim().length!==0) || "This field is mandatory",
        numberrule: value => {
          const pattern = /^\d*\.?\d*$/;
          if (value == null || value == undefined) {
            return true;
          }
          return (
              pattern.test(
                  String(value)
                      .replace(/\,/g, "")
                      .replace(/\-/g, "")
              ) || "This field must have numeric value"
          );
        },
        address1Rule: v => v.length<=140 || 'Please enter valid Address 1',
        address2Rule: v => v.length<=140 || 'Please enter valid Address 2',
        address3Rule: v => v.length<=140 || 'Please enter valid Address 3',
        address4Rule: v => v.length<=140 || 'Please enter valid Address 4',
        postalCodeRule: v => (v.length === 6 ||v.length === 0) || 'Please enter valid Postal Code',
        incidentLocationRule: v => v.length<=100 || 'Please enter valid incident location',
        incidentdescriptionRule: v => v.length<=400 || 'Please enter valid incident description',
        policyReportNumberRule: v => (v.length<=20 && /^[a-zA-Z0-9]*$/.test(v) ) || 'Please enter valid police report number',
        singaporeNumberRule:
            v => ((v.length === 8 && (v.startsWith(8)||v.startsWith(9))) || (v.length ===0))|| 'Please enter a valid contact number'
        ,
        hongKongNumberRule:
            v => (v.length === 8 || (v.length ===0)) || 'Please enter a valid contact number'
        ,
        emailValidation: value => {
          var pattern = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
          if (value == null || value === undefined || value==='') {
            return true;
          }
          return (
              pattern.test(
                  value
              ) || "E-mail must be valid"
          );
        },
      }
    }
  },
  computed: {
    IsAMERCoverageType() {
      return this.$store.state.incidenttype2 === "AMER";
    },
    IsPersonalDisabilityCoverageType() {
      return this.$store.state.incidenttype2 === "PD";
    },
    IsPersonalAccidentCoverageType() {
      return this.$store.state.incidenttype2 === "LDPA";
    },
    IsAccidentalDeathCoverageType() {
      return this.$store.state.incidenttype2 === "AD";
    },
    IsPoliceReportNumberNeeded(){
      return this.IsPersonalAccidentCoverageType
    },
    GetCountryName() {
      return this.$store.state.country;
    },
    injuryTypeOptionsFiltered(){
      return !this.IsAccidentalDeathCoverageType ? this.injuryTypeOptions.filter(r => r.code !== 'DEA') : this.injuryTypeOptions
    },
    ...mapGetters([
      'injuryLocationOptions',
      'injuryTypeOptions',
      'genderOptions',
      'prefferedContactOptions',
      'radioButtonOptions',
      'transportationModeOptions',

      'incidentDetails',
      'wasAnyThirdPartyLiable',
      'thirdPartyDetails',
      'wereThereAnyWitness',
      'witnessDetails',
      'injuryDetails'
    ]),
  },

  methods: {
    changeHour(type,value){
      if (this.incidentInfo.incidenttime===''){
        this.incidentInfo.incidenttime= '00:00'
      }
      this.incidentInfo.incidenttime = moment(this.incidentInfo.incidenttime,'HH:mm').set(type,value).format('HH:mm')
    },
    changeMinutes(){
      if (this.incidentInfo.incidenttime===''){
        this.incidentInfo.incidenttime= '00:00'
      }
      this.incidentInfo.incidenttime= moment(this.incidentInfo.incidenttime,'HH:mm').set('minutes',this.incidentMinutes).format('HH:mm')
    },
    checkPhotos(){
      if (this.incidentInfo.canprovidephoto ==='Y'){
        this.incidentInfo.photos=[]
      }
    },
    checkStatement(){
      if (this.witnessInfo.canprovidewitness ==='Y'){
        this.witnessInfo.statementproof=[]
      }
    },
    ...mapMutations(
        [
          'saveIncidentDetails',
          'saveThirdPartyDetails',
          'saveWasAnyThirdPartyLiable',
          'saveWereThereAnyWitness',
          'saveWitnessDetails',
          'saveInjuryDetails'
        ]
    ),
    getIncident() {
      return _.cloneDeep(this.$store.getters.incidentDetails)
    },
    getWasAnyThirdPartyLiable() {
      return _.cloneDeep(this.$store.getters.wasAnyThirdPartyLiable)
    },
    getThirdParty() {
      return _.cloneDeep(this.$store.getters.thirdPartyDetails)
    },
    getWereThereAnyWitness() {
      return _.cloneDeep(this.$store.getters.wereThereAnyWitness)
    },
    getWitness() {
      return _.cloneDeep(this.$store.getters.witnessDetails)
    },
    getInjury() {
      return _.cloneDeep(this.$store.getters.injuryDetails)
    },

    validateAndApplyChange: function () {
      if (this.$refs.form.validate()){
      this.$store.commit('saveIncidentDetails', this.incidentInfo)
      this.$store.commit('saveWasAnyThirdPartyLiable', this.wasAnyThirdPartyLiableInfo)
      this.$store.commit('saveThirdPartyDetails', this.thirdPartyInfo)
      this.$store.commit('saveWereThereAnyWitness', this.wereThereAnyWitnessInfo)
      this.$store.commit('saveWitnessDetails', this.witnessInfo)
      this.$store.commit('saveInjuryDetails', this.injuryInfo)
      this.$router.back()
      }
      else {
        let context = this;
        setTimeout(function () {
          let test = context.$el.querySelector('.v-messages__message')
          if (test){

            context.$vuetify.goTo(context.$el.querySelector('.v-messages__message').parentNode.parentNode.parentNode.parentNode, {container: document.documentElement,duration:100})
            context.$el.querySelector('.v-messages__message').parentNode.parentNode.parentNode.parentNode.focus()
          }
        },100)
      }
    },
    validateAndGoNext: function () {
      if (this.$refs.form.validate()){
      this.$store.commit('saveIncidentDetails', this.incidentInfo)
      this.$store.commit('saveWasAnyThirdPartyLiable', this.wasAnyThirdPartyLiableInfo)
      this.$store.commit('saveThirdPartyDetails', this.thirdPartyInfo)
      this.$store.commit('saveWereThereAnyWitness', this.wereThereAnyWitnessInfo)
      this.$store.commit('saveWitnessDetails', this.witnessInfo)
      this.$store.commit('saveInjuryDetails', this.injuryInfo)

      this.$router.push('/claims-documentupload');
      } else {
        let context = this;
        setTimeout(function () {
          let test = context.$el.querySelector('.v-messages__message')
          if (test){

            context.$vuetify.goTo(context.$el.querySelector('.v-messages__message').parentNode.parentNode.parentNode.parentNode, {container: document.documentElement,duration:100})
            context.$el.querySelector('.v-messages__message').parentNode.parentNode.parentNode.parentNode.focus()
          }
        },100)
      }
    },
    checkRule: function (isRequiredField, value) {
      if (isRequiredField && value.length === 0) {
        return ['Required.'];
      }
    },
    initPageTitle: function () {
      if (this.$route.query.modify) {
        this.$store.commit('changeMenuTitle', 'Modify Claim')
      } else {
        this.$store.commit('changeMenuTitle', 'Make a Claim')
      }
    },
    initSubMenuTitle: function () {
      this.$store.commit('changeSubMenuTitle', 'Fill Out Claim Form')
    },
    initTopProgressStep: function () {
      this.$store.commit('changeStep', 3)
    },
    setIncidentTime:function(){
      if (this.incidentInfo.incidenttime){
        this.incidentHour=moment(this.incidentInfo.incidenttime,'HH:mm').format('HH')
        this.incidentMinutes=moment(this.incidentInfo.incidenttime,'HH:mm').format('mm')
      }
    },
    moment: function (e) {
      return moment();
    },
    toTop() {
      this.$vuetify.goTo(0, {container: document.documentElement})
    },
    onScroll(e) {
      if (typeof window === 'undefined') {
        return
      }
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.is_show_fav_icon = top > 10
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
        // Filter system files or hide files

        if(newFile.size ===0){
          alert('You can\'t add empty file')
          return prevent()
        }else if (newFile.size>5000000){
          alert('You can\'t add file exceeds 5MB')
          return prevent()
        }
        if (!(newFile.name.indexOf('pdf')!==-1||
            newFile.name.indexOf('doc')!==-1||
            newFile.name.indexOf('docx')!==-1||
            newFile.name.indexOf('txt')!==-1||
            newFile.name.indexOf('jpg')!==-1||
            newFile.name.indexOf('jpeg')!==-1||
            newFile.name.indexOf('msg')!==-1||
            newFile.name.indexOf('png')!==-1)) {
          alert('You can\'t attach unsupported file. \nSupport File : pdf,doc,docx,txt,jpg,jpeg,msg,png')
          return prevent()
        }

        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent()
        }
        // Filter php html js file
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent()
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        // Create a blob field
        newFile.blob = ''
        let URL = window.URL || window.webkitURL
        if (URL && URL.createObjectURL) {
          newFile.blob = URL.createObjectURL(newFile.file)
        }
        // Thumbnails
        newFile.thumb = ''
        if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
          newFile.thumb = newFile.blob
        }
      }
    },
    // add, update, remove File Event
    inputFile(newFile, oldFile) {
      const file = newFile.file;
      function readFile(file, cb) {
        let FR = new FileReader();
        FR.readAsDataURL(file);
        FR.onloadend = () => {
          cb(FR.result);
        };
      }
      if (file) {

        new Compressor(file, {
          success(result) {
            readFile(result, cb => {
              newFile.base64 = cb;
            });
          },
          error(err) {
            readFile(file, cb => {
              newFile.base64 = cb;
            });
          },
        });


      }
    },
    alert(message) {
      alert(message)
    },
    showDeletePopup(list,item) {
      this.dialog = true
      this.toRemoveItem = item
      this.toRemoveList = list
    },
    removeItem(){
      // this.removeItemFromList(this.incidentInfo.photos)
      // this.removeItemFromList(this.witnessInfo.statementproof)
      this.removeItemFromList(this.toRemoveList)
      this.dialog = false
      this.toRemoveItem = null
      this.toRemoveList= null
    },
    removeItemFromList(list){
      let self = this;
      const idx = list.findIndex(function(item) {return item.id === self.toRemoveItem.id})
      if (idx > -1) list.splice(idx, 1)
      if (list.length ===0 ) {

        for (var i = 0, len = parent.length; i < len; i++) {
          if (parent[i].photos && parent[i].photos.length===0){
            delete parent[i].photos;
          }
          if (parent[i].statementproof && parent[i].statementproof.length===0){
            delete parent[i].statementproof;
          }
        }

      }
    },

    goPrevious() {
      this.$router.back()
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    isAlphaNumeric:function (evt){
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if (!(charCode > 47 && charCode < 58) && // numeric (0-9)
          !(charCode > 64 && charCode < 91) && // upper alpha (A-Z)
          !(charCode > 96 && charCode < 123)) {
        evt.preventDefault();
      } else {
        return true;
      }
    }
  },
  filters: {
    moment: function (date) {
      var result = moment(date).format('DD/MM/YY')
      if (result === 'Invalid date') {
        return ''
      } else {
        return moment(date).format('DD/MM/YY')
      }
    }
  },
  mounted() {

    this.initPageTitle()
    this.initSubMenuTitle()
    this.initTopProgressStep()
    this.setIncidentTime()
    this.$vuetify.goTo(0, {container: document.documentElement,duration:0})
    // if (this.incidentInfo.photos && this.incidentInfo.photos.length===0){
    //   delete this.incidentInfo.photos;
    // }
    // if (this.witnessInfo.statementproof &&this.witnessInfo.statementproof.length===0){
    //   delete this.witnessInfo.statementproof;
    // }
  },


}
</script>
<style scoped>
.fileItemHolder {
  position: relative;
  text-align: center;
}

.file-remove-icon {
  position: absolute;
  top: 25px;
  margin-left: -25px;
  z-index: 1;
  text-align: center;
  background: #000000 no-repeat padding-box;
  zoom: 1;
  filter: alpha(opacity=50);
  opacity: 0.45;
  border-radius: 50%;
  padding: 4px;
}

* {
  text-transform: none !important;
}

.add-photo-button {
  display: flex !important;
}

.file-text-span {
  white-space: nowrap;
  min-width: 140px
}

.hide-element {
  visibility: hidden;
}

li div {
  position: relative;
  display: block;
  line-height: 27px;
  padding-right: 80px;
  color: #333;
}

li div span {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

li div img {
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 3px
}

.menu-divider-top {
  border-top: #47494E 1px solid;
  padding-top: 20px;
}
.action-button {
  text-transform: none !important;
  font-size: 20px;
}
.section-title{
  color: #37424A;
  font-size: 24px;
}
.content-title{
  word-break:break-all;
  white-space:pre-line;
}
</style>

