<template>
  <footer style="padding-top: 0px">
    <div style="padding: 10px 20%;">
      <v-row class="row ma-0">
        <div class="text-center">
          {{ $t('lang.footer.introSG') }}
        </div>
      </v-row>
    </div>
    <section>
      <v-container class="container">
        <v-row class="row">
          <div class="col-md-3 col-sm-5 text-left col-xs-12">
            <p class="copy copyright-text"> {{ $t('lang.footer.copyright') }}</p>
          </div>
          <div class="col-md-4 text-center hidden-xs hidden-sm hidden-md">
            <p>
              <span :lang="getLang" v-html="$t('lang.common.arrangedInPartnership')"></span>
              &nbsp;&nbsp;&nbsp;
              <a href="https://www.tribe-advisory.com/" target="_blank">
                <img src="@/assets/images/tribe.png" width="40" class="footer-img"/>
              </a>
            </p>
          </div>
          <div class="col-md-5  col-sm-7 clearfix col-xs-12">
            <ul class="footer-links clearfix">
              <li>

                <a :href="openTermsOfUse" :lang="getLang" target="_blank"> {{ $t('lang.footer.termsOfUse') }}</a>
              </li>
              <li>
                <a :href="openPrivacyPolicy" target="_blank">{{ $t('lang.common.privacyPolicy') }}</a>

              </li>
              <!--  <li class="hidden-sm hidden-lg hidden-md"><a href="#">Xxxxxxx Xxxxx</a></li> -->
              <li>{{ $t('lang.footer.poweredByMarshDigital') }}</li>
            </ul>
          </div>
        </v-row>
      </v-container>
    </section>
  </footer>
</template>
<script>
import axios from 'axios'

export default {
  components: {},
  data() {
    return {}
  },
  props: [],
  methods: {
    downloadPDF(url, fileName) {
      axios({
        url: url,
        method: 'GET',
        responseType: 'blob'
      }).then(response => {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          let theFile = new Blob([response.data], {type: 'application/octet-stream'})

          window.navigator.msSaveOrOpenBlob(theFile, fileName + '.pdf')
        } else {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName + '.pdf')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      })
    },
    //    /**
    //    * Google Analytics fro Quote request
    //    * @param eventLabel Event label
    //    * @param eventAction Event Action
    //    */
    sendEvent(eventLabel, eventAction) {
      window.ga('send', 'event', {
        eventCategory: 'Deliveroo',
        eventLabel: eventLabel,
        eventAction: eventAction,
        eventValue: 10
      })
    }
  },
  computed: {
    openPrivacyPolicy() {
      let country = this.$store.state.country
      let language = this.$store.state.selectedLanguageCode
      switch (country) {
        case 'Hong Kong':
          return 'assets/pdf/Privacy Policy (Deliveroo HK).pdf'
        case 'Singapore':
          return 'assets/pdf/Privacy Policy (Deliveroo SG).pdf'

        default:
          return 'assets/pdf/Privacy Policy (Deliveroo HK).pdf'
      }
    },
    openTermsOfUse() {
      // eslint-disable-next-line

      let country = this.$store.state.country
      let language = this.$store.state.selectedLanguageCode
      switch (country) {
        case 'Hong Kong':
          return 'assets/pdf/Terms of Use and Access Agreement (Deliveroo HK).pdf'
        case 'Singapore':
          return 'assets/pdf/Terms of Use and Access Agreement (Deliveroo SG).pdf'

        default:
          // eslint-disable-next-line
          return 'assets/pdf/Terms of Use and Access Agreement (Deliveroo HK).pdf'
      }
    },
    getLang() {
      return this.$store.state.selectedLanguageCode === 'hk' || this.$store.state.selectedLanguageCode === 'tw' ? 'zh' : 'en'
    }
  }
}
</script>

<style>
</style>
