<template>
    <div class="under-construciton">
        <div class="image">
        </div>
        <div class="text">
            Under Construction
        </div>
        <div class="gohome">
            <button class="btn btn-one" @click="toHome()">
                <span>
                    Home
                </span>
            </button>
        </div>
    </div>
</template>
<script>
export default {
  name: 'UnderConstruction',
  methods: {
    toHome () {
      this.$store.dispatch('updateCountrySelection', false)
      this.$store.dispatch('updateCountry', '')
      this.$store.dispatch('updateLanguageCode', '')
      this.$store.dispatch('updatei18n', 'en')
      this.$router.push('/policy-coverage')
    }
  }
}
</script>
<style>
</style>
