<template>
    <div class="language-select-component" v-if="languageAvailable" lang="zh">
    
        <div class="language-dropdown">
    
            <button class="language-dropbtn" @click="showDropdown">
    
            <span>{{selectedLanguageName}}</span>
    
        <img src="@/assets/images/icons/selectbox_icon_new.png" />
    
        </button>
    
            <div class="language-dropdown-content" :class="{'language-dropdown-show':isShowDropdown}">
    
                <a href="#" v-for="item in langCodes" :key="item.languageCode" @click="langChange(item.languageCode)">
    
                {{ item.languageName }}
    
            </a>
    
            </div>
    
        </div>
    
    </div>
</template>
<script>
export default {
    props: { showPlaceHolder: Boolean },
    data() {
        return {
            isShowDropdown: false
        }
    },
    methods: {
        showDropdown() {

            this.isShowDropdown = true
        },
        hideDropdown() {
            this.isShowDropdown = false
        },
        langChange(languageCode) {
            this.hideDropdown()
            console.log(languageCode)
            document.getElementById('mobile-menu').classList.remove('side-nav')
            this.$store.dispatch('updateLanguageCode', languageCode)
            this.$store.dispatch('updateCountrySelection', true)
            document.querySelector('body').classList.remove('noscroll')
        }
    },
    computed: {
        langCodes() {
            var val = this.$store.state.country
            if (val !== '') {
                return this.$store.state.countryList.filter(function(item) {
                    return item.countryName === val
                })[0].languageList
            }
            return 'EN-us'
        },
        languageAvailable() {
            var val = this.$store.state.country
            if (val !== '') {
                let selectedCountry = this.$store.state.countryList.filter(function(item) {
                    return item.countryName === val
                });
                if(selectedCountry && selectedCountry.length>0){
                  let langList=selectedCountry[0].languageList;
                  if (langList && langList.length > 1) {
                      return true
                  }
                }
            }
            return false
        },
        selectedLanguageName() {
            if (this.$store.state.selectedLanguageCode) {
                let val = this.$store.state.country
                let langList = this.$store.state.countryList.filter(function(item) {
                    return item.countryName === val
                })[0].languageList
                let selectLangObj = langList.find(x => x.languageCode === this.$store.state.selectedLanguageCode)
                let langName = selectLangObj.languageName
                return langName
            } else {
                if (this.showPlaceHolder) {
                    return 'Select your language'
                } else {
                    return ''
                }
            }
        }
    }
}
</script>
