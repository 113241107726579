<template>
<keep-alive>
<component :is="dynamicComponent"></component>
</keep-alive>
</template>
<script>
import ClaimsProcessSG from '@/components/sg/claims-process/claims-process.component'
import ClaimsProcessHK from '@/components/hk/claims-process/claims-process.component'
export default{
  computed: {
    dynamicComponent () {
      // Hongkong
      if (this.$store.state.country === 'Hong Kong') {
        return ClaimsProcessHK
      } else {
        return ClaimsProcessSG
      }
    }
  }
}
</script>
