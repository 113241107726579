<template>
  <div class="my-body  group-personal">
    <section class="sec_common sec">
      <div class="container" style="margin-top: 70px">
        <div class="row text-center">
          <div class="col-md-12">
            <br>
            <br>
            <br>
            <h1>{{$t('lang.common.personalAccidentAndIncomeProtection')}}</h1>
            <p v-html="$t('lang.group-personal.coversAllRidersWhenWorking')"></p>
            <button class="btn btn-one visible-sm visible-xs" @click="movePage('/claims-process')">
              <router-link :to="'/claims-process'" class="uppercase">{{$t('lang.common.reportAnIncident')}}</router-link>
            </button>
          </div>
        </div>
      </div>
    </section>

    <div class="clearfix"></div>

    <section class="sec_common sec_1">
      <div class="container">
        <div class="row">
          <div class="col-md-7 col-sm-12">
            <h3>{{$t('lang.common.policySummary')}}</h3>
            <p>{{$t('lang.group-personal.downloadYourSummaryDocument')}}</p>
<br class="visible-xs"/>
          </div>
          <div class="col-md-5 col-sm-6">
            <!-- benefit pdf button -->
            <benefit-pdf-button></benefit-pdf-button>
            <button class="btn btnleft pull-right btn-one hidden-sm hidden-xs" @click="movePage('/claims-process')">
              <router-link :to="'/claims-process'" class="uppercase">{{$t('lang.common.reportAnIncident')}}</router-link>
            </button>
          </div>
        </div>
      </div>
    </section>

    <div class="clearfix"></div>

    <div class="container">
      <div class="row">
        <div class="bannerText col-md-12 col-sm-12">
          <p :lang="getLang">{{$t('lang.group-personal.substituteRiderWarningBannerSG')}}</p>
        </div>
      </div>
      <div class="row">
        <div class="show-benefits col-md-7 col-sm-12">
          <p>{{$t('lang.group-personal.hereAreThePrimaryBenefits')}}</p>
        </div>
      </div>
    </div>
    <section class="sec_common sec_2">
      <div class="container">
        <div class="row marsh_row row_2 text-center">
          <div class="col-md-3 col-sm-6 col-xs-6 col-3">
            <div class="column" v-html="$t('lang.group-personal.us_65000')">
            </div>
          </div>
          <div class="col-md-3 col-sm-6 col-xs-6 col-3">
            <div class="column large-contents" v-html="$t('lang.group-personal.us_500Week')">
            </div>
          </div>
            <div class="col-md-3 col-sm-6 col-xs-6 col-3">
              <div class="column  large-contents" v-html="$t('lang.group-personal.us_250Week')">
              </div>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-6 col-3">
              <div class="column" v-html="$t('lang.group-personal.us_13000SG')">
              </div>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-6 col-3">
              <div class="column" v-html="$t('lang.group-personal.us_55day')">
              </div>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-6 col-3">
              <div class="column" v-html="$t('lang.group-personal.us_3000SG')">
              </div>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-6 col-3">
              <div class="column" v-html="$t('lang.group-personal.us_400Week')">
              </div>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-6 col-3">
              <div class="column" v-html="$t('lang.group-personal.us_24000')">
              </div>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-6 col-3">
              <div class="column" v-html="$t('lang.group-personal.us_400')">
              </div>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-6 col-3">
              <div class="column" v-html="$t('lang.group-personal.us_5_000SG')">
              </div>
            </div>
            <!-- <div class="col-md-3 col-sm-6 col-xs-6 col-3">
                <div class="column">
                    <h1 class="heading1">$1,000,000</h1>
                    <p>Aggregate Limit</p>
                </div>
            </div> -->
        </div>
      </div>
    </section>

    <div class="clearfix"></div>

    <!-- <section class="sec_common sec_3">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <h3 class="sec_note"><span class="hidden-xs">*</span> Definition of Substitutes <span class="hide-some">*</span></h3>
                    <p class="sec_note_pad"><b>Authorised Substitute:</b> Cover applies to the Authorised Substitute in the event of an Accident during the Period of Insurance. Cover commences when the Authorised Substitute is made available for orders and the authorised Rider ID is online in the Deliveroo app and ceases after the Authorised Substitute has completed the food delivery duty using the authorised Rider?�s app and up to one (1) hour thereafter or terminated by any subsequent log-in of the Deliveroo app.</p>
                </div>
            </div>
        </div>
    </section> -->

<div class="clearfix"></div>
    <section class="sec_common sec_4">
      <div class="container">
        <div class="row">
          <div class="col-md-10 col-sm-10" v-html="$t('lang.group-personal.ourUnderwriterChubb')">
          </div>

          <div class="col-md-2 col-sm-2">
            <img class="img-responsive pull-right" src="@/assets/images/chubb.png">
          </div>
        </div>
      </div>
    </section>

    <div class="clearfix"></div>

    <section class="sec_common sec_5">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-sm-3 col-xs-4 coll-3 hidden-sm hidden-xs">
            <img class="img-responsive bottom_image" src="@/assets/images/public-liability-insurance-footer.jpg">
          </div>
          <div class="col-md-4 col-sm-12 col-xs-12  bottom-blue-box">
            <h2>{{$t('lang.common.publicLiability')}}</h2>
            <p>{{$t('lang.common.coversAllRidersWhenOffVehicle')}}</p>
            <button class="btn" @click="movePage('/liability')">
              <router-link :to="'/liability'">{{$t('lang.common.findOutMore')}}</router-link>
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import benefitPdfButton from '@/components/sg/shared/benefit.pdf.button'
export default {
  components: {
    benefitPdfButton
  },
  data () {
    return {
    }
  },
  methods: {
    movePage (path) {
      this.$router.push(path)
    }
  },
  mounted(){
    this.$vuetify.goTo(0, {container: document.documentElement,duration:0})
  }
}
</script>

<style>
</style>
