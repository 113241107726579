<template>
  <a class="pull-left" @click="downloadPDF()" :lang="getLang">{{$t('lang.common.benefitsSummaryDocument')}}
    <img src="@/assets/images/icons/download_icon.png">
  </a>
</template>

<script>
import axios from 'axios'
export default {
  name: 'benefit-pdf-button',
  components: {},
  data () {
    return {}
  },
  props: [],
  methods: {
    downloadPDF () {
      // this.sendEvent('Claims Process - download incident report form', 'click link')
      let country = this.$store.state.country
      let language = this.$store.state.selectedLanguageCode
      // set url
      let fileName
      let url
      switch (country) {
        case 'Hong Kong':
          if (language === 'hk') {
            fileName = '保障範圍匯總'
            // eslint-disable-next-line
            url =  'assets/pdf/Benefits_Summary_Document_HongKong_hk__2024.pdf'
          } else {
            fileName = 'Benefits_Summary_Document_HongKong'
            // eslint-disable-next-line
            url =  'assets/pdf/Benefits_Summary_Document_HongKong_en_2024.pdf'
          }
          break
        // case 'Taiwan':
        //   fileName = '保障範圍匯總'
        //   url = './assets/pdf/TWRiderPolicyDocument.pdf'
        //   break
        default:
          // default SG
          fileName = 'Benefits_Summary_Document_Singapore'
          // eslint-disable-next-line
          url =  'assets/pdf/Benefits_Summary_Document_Singapore_2024.pdf'
      }

      axios({
        baseURL: axios.defaults.baseURL.replace('api', ''),
        url: url.replace('/api', ''),
        method: 'GET',
        responseType: 'blob'
      }).then(response => {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          let theFile = new Blob([response.data], { type: 'application/octet-stream' })
          window.navigator.msSaveOrOpenBlob(theFile, fileName + '.pdf')
        } else {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName + '.pdf')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      })
    },
    //    /**
    //    * Google Analytics fro Quote request
    //    * @param eventLabel Event label
    //    * @param eventAction Event Action
    //    */
    sendEvent (eventLabel, eventAction) {
      window.ga('send', 'event', {
        eventCategory: 'Deliveroo',
        eventLabel: eventLabel,
        eventAction: eventAction,
        eventValue: 10
      })
    }
  },
  computed: {
    getLang () {
      return this.$store.state.selectedLanguageCode === 'hk' || this.$store.state.selectedLanguageCode === 'tw' ? 'zh' : 'en'
    }
  }
}
</script>

<style>
</style>
