<template>
   <div class="my-body" b-bind:class="[show?'overflow-hide':'overflow-scroll']">
   <div class="print-background" v-if="show">
      <span class="loading-text"> Processing...</span>
      <br />
      <img src="@/assets/images/loading.gif" class="loading"/>
   </div>

   <div class="form-area" v-if="!show">
      <!-- 1st page-->
      <div class="page">
         <div class="logo-div">
            <img src="@/assets/images/marshlogo.png" />
         </div>
         <div>
            <span class="pdf-title">Deliveroo - Incident Report Form</span>
         </div>
         <div class="item-title">
            <span >1. Type of incident (mandatory)</span>
         </div>
         <div class="row">
            <div class="title-input-col">
               <span>Public Liability</span>
               <img class="checkbox-image" v-if="publicLiability" @click="publicLiability = !publicLiability" src="@/assets/images/checkmark.png" />
               <img class="checkbox-image" v-if="!publicLiability" @click="publicLiability = !publicLiability" src="@/assets/images/uncheckmark.png" />
            </div>
            <div  class="title-input-col">
               <span>Personal Accident and Income Protection</span>
               <img class="checkbox-image" v-if="personalAccident" @click="personalAccident = !personalAccident" src="@/assets/images/checkmark.png" />
               <img class="checkbox-image" v-if="!personalAccident" @click="personalAccident = !personalAccident" src="@/assets/images/uncheckmark.png" />
            </div>
         </div>
         <div class="item-title">
            <span >2. Details of Rider (mandatory)</span>
         </div>
         <div class="row">
            <div class="title-col">
               <span>Rider ID Number</span>
            </div>
            <div class="input-col">
               <input type="text" v-model="riderIdNumber" />
            </div>
            <div class="title-col">
               <span>Name</span>
            </div>
            <div class="input-col">
               <input type="text" v-model="riderName" />
            </div>
         </div>
         <div class="row">
            <div class="title-col">
               <span>Address</span>
            </div>
            <div class="input-col">
               <input type="text" v-model="riderAddress" />
            </div>
            <div class="title-col">
               <span>Phone Number <br />(Home / /Mobile)</span>
            </div>
            <div class="input-col">
               <input type="text" v-model="riderPhoneNumber" />
            </div>
         </div>
         <div class="row">
            <div class="title-col">
               <span>Email address</span>
            </div>
            <div class="input-col">
               <input type="text" v-model="riderEmailAddress" />
            </div>
            <div class="title-col">
               <span>Type</span>
            </div>
            <div class="input-col">
               <div class="check-virtical-title">
                  <span>Walker </span>
                  <br />
               <img class="checkbox-image" v-if="riderTypeWalker" @click="riderTypeWalker = !riderTypeWalker" src="@/assets/images/checkmark.png" />
               <img class="checkbox-image" v-if="!riderTypeWalker" @click="riderTypeWalker = !riderTypeWalker" src="@/assets/images/uncheckmark.png" />
               </div>
               <div class="check-virtical-title">
                  <span>Bicycle </span><br />
               <img class="checkbox-image" v-if="riderTypeBicycle" @click="riderTypeBicycle = !riderTypeBicycle" src="@/assets/images/checkmark.png" />
               <img class="checkbox-image" v-if="!riderTypeBicycle" @click="riderTypeBicycle = !riderTypeBicycle" src="@/assets/images/uncheckmark.png" />
               </div>
               <div class="check-virtical-title">
                  <span>Scooter</span><br />
               <img class="checkbox-image" v-if="riderTypeScooter" @click="riderTypeScooter = !riderTypeScooter" src="@/assets/images/checkmark.png" />
               <img class="checkbox-image" v-if="!riderTypeScooter" @click="riderTypeScooter = !riderTypeScooter" src="@/assets/images/uncheckmark.png" />
               </div>
               <div class="check-virtical-title">
                  <span>Car</span><br />
               <img class="checkbox-image" v-if="riderTypeCar" @click="riderTypeCar = !riderTypeCar" src="@/assets/images/checkmark.png" />
               <img class="checkbox-image" v-if="!riderTypeCar" @click="riderTypeCar = !riderTypeCar" src="@/assets/images/uncheckmark.png" />
               </div>
               <div class="check-virtical-title">
                  <span>E-scooter</span><br />
               <img class="checkbox-image" v-if="riderTypeEScooter" @click="riderTypeEScooter = !riderTypeEScooter" src="@/assets/images/checkmark.png" />
               <img class="checkbox-image" v-if="!riderTypeEScooter" @click="riderTypeEScooter = !riderTypeEScooter" src="@/assets/images/uncheckmark.png" />
               </div>
            </div>
         </div>
         <div class="item-title">
            <span  >3. Details of Substituted Rider (if applicable)</span>
         </div>
         <div class="row">
            <div class="title-col">
               <span>Rider ID Of Account Holder</span>
            </div>
            <div class="input-col">
               <input type="text" v-model="subRiderIdNumber" />
            </div>
            <div class="title-col">
               <span>Name</span>
            </div>
            <div class="input-col">
               <input type="text" v-model="subRiderName" />
            </div>
         </div>
         <div class="row">
            <div class="title-col">
               <span>Address</span>
            </div>
            <div class="input-col">
               <input type="text" v-model="subRiderAddress" />
            </div>
            <div class="title-col">
               <span>Phone Number  <br />(Home/ / Mobile)</span>
            </div>
            <div class="input-col">
               <input type="text" v-model="subRiderPhoneNumber" />
            </div>
         </div>
         <div class="row">
            <div class="title-col">
               <span>Email address</span>
            </div>
            <div class="input-col">
               <input type="text" v-model="subRiderEmailAddress" />
            </div>
            <div class="title-col">
               <span>Type</span>
            </div>
            <div class="input-col">
               <div class="check-virtical-title">
                  <span>Walker </span>
                  <br />
               <img class="checkbox-image" v-if="subRiderTypeWalker" @click="subRiderTypeWalker = !subRiderTypeWalker" src="@/assets/images/checkmark.png" />
               <img class="checkbox-image" v-if="!subRiderTypeWalker" @click="subRiderTypeWalker = !subRiderTypeWalker" src="@/assets/images/uncheckmark.png" />
               </div>
               <div class="check-virtical-title">
                  <span>Bicycle </span><br />
               <img class="checkbox-image" v-if="subRiderTypeBicycle" @click="subRiderTypeBicycle = !subRiderTypeBicycle" src="@/assets/images/checkmark.png" />
               <img class="checkbox-image" v-if="!subRiderTypeBicycle" @click="subRiderTypeBicycle = !subRiderTypeBicycle" src="@/assets/images/uncheckmark.png" />
               </div>
               <div class="check-virtical-title">
                  <span>Scooter</span><br />
               <img class="checkbox-image" v-if="subRiderTypeScooter" @click="subRiderTypeScooter = !subRiderTypeScooter" src="@/assets/images/checkmark.png" />
               <img class="checkbox-image" v-if="!subRiderTypeScooter" @click="subRiderTypeScooter = !subRiderTypeScooter" src="@/assets/images/uncheckmark.png" />
               </div>
               <div class="check-virtical-title">
                  <span>Car</span><br />
               <img class="checkbox-image" v-if="subRiderTypeCar" @click="subRiderTypeCar = !subRiderTypeCar" src="@/assets/images/checkmark.png" />
               <img class="checkbox-image" v-if="!subRiderTypeCar" @click="subRiderTypeCar = !subRiderTypeCar" src="@/assets/images/uncheckmark.png" />
               </div>
               <div class="check-virtical-title">
                  <span>E-scooter</span><br />
               <img class="checkbox-image" v-if="subRiderTypeEScooter" @click="subRiderTypeEScooter = !subRiderTypeEScooter" src="@/assets/images/checkmark.png" />
               <img class="checkbox-image" v-if="!subRiderTypeEScooter" @click="subRiderTypeEScooter = !subRiderTypeEScooter" src="@/assets/images/uncheckmark.png" />
               </div>
            </div>
         </div>
         <div class="item-title">
            <span  >4. Details of incident space (mandatory)</span>
         </div>
         <div class="row">
            <div class="title-col">
               <span>Date</span>
            </div>
            <div class="input-col">
               <input type="date" v-model="incidentDate" />
            </div>
            <div class="title-col">
               <span>Time</span>
            </div>
            <div class="input-col">
               <input type="time" v-model="incidentTime" />
            </div>
         </div>
         <div class="row">
            <div class="title-col">
               <span>Location</span>
            </div>
            <div class="input-col">
               <input type="text" v-model="incidentLocation" />
            </div>
         </div>
         <div>
            <span>Description in detail</span>
         </div>
         <div>
            <textarea name="detailDescription" cols="40" rows="5" v-model="incidentDetail"></textarea>
            <!-- <input type="text" v-model="incidentDetail1" /> -->
         </div>
         <!-- <div>
            <input type="text" v-model="incidentDetail2" />
         </div>
         <div>
            <input type="text" v-model="incidentDetail3" />
         </div>
         <div>
            <input type="text" v-model="incidentDetail4" />
         </div>
         <div>
            <input type="text" v-model="incidentDetail5" />
         </div>
         <div>
            <input type="text" v-model="incidentDetail6" />
         </div>
         <div>
            <input type="text" v-model="incidentDetail7" />
         </div>
         <div>
            <input type="text" v-model="incidentDetail8" />
         </div> -->
      </div>
      <!-- end 1st page-->
      <!-- 2nd page-->
      <div class="page">
         <div class="item-title">
            <span >5. Details of Third Party (if relevant)</span>
         </div>
         <div class="row">
            <div class="title-col">
               <span>Name</span>
            </div>
            <div class="input-col">
               <input type="text" v-model="thirdPartyName" />
            </div>
            <div class="title-col">
               <span>Address</span>
            </div>
            <div class="input-col">
               <input type="text" v-model="thirdPartyAddress" />
            </div>
         </div>
         <div class="row">
            <div class="title-col">
               <span>Phone Number</span>
            </div>
            <div class="input-col">
               <input type="text" v-model="thirdPartyPhoneNumber" />
            </div>
            <div class="title-col">
               <span>Email Address</span>
            </div>
            <div class="input-col">
               <input type="text" v-model="thirdPartyEmailAddress" />
            </div>
         </div>
         <div class="item-title">
            <span  >6. Details of Witness (if any) </span>
         </div>
         <div class="row">
            <div class="title-col">
               <span>Name</span>
            </div>
            <div class="input-col">
               <input type="text" v-model="witnessName" />
            </div>
            <div class="title-col">
               <span>Address</span>
            </div>
            <div class="input-col">
               <input type="text" v-model="witnessAddress" />
            </div>
         </div>
         <div class="row">
            <div class="title-col">
               <span>Phone Number</span>
            </div>
            <div class="input-col">
               <input type="text" v-model="witnessPhoneNumber" />
            </div>
            <div class="title-col">
               <span>Email Address</span>
            </div>
            <div class="input-col">
               <input type="text" v-model="witnessEmailAddress" />
            </div>
         </div>
         <div>
            <span>Witness Statement (Yes* / No) - Please attach in email, if yes</span>
         </div>
         <div class="item-title">
            <span  >7. Photos taken at scene - (Yes*/ No) Please attach in email, if yes</span>
         </div>
         <div class="item-title">
            <span  style="margin-right:0px">8.Police Report Number </span>
            <span style="color:red;margin-right:0px" >*</span>
            <span style="margin-right:0px">(mandatory)</span>
         </div>
         <div>
            <span class="red">*Please attach Police Statement in email, if any</span>
         </div>
         <div class="item-title">
            <span  >9. Details of Loss</span>
         </div>
         <div class="item-details-of-loss">
            <span>(i)</span><span>Rider- Bodily Injury (Please provide full details of injuries that you have sustained)</span>
         </div>
         <div class="item-details-of-loss">
            <span>(ii)</span> <span>Third Party(Please provide full details of any loss or damage to property belongingto third parties)</span>
         </div>
         <div class="item-details-of-loss">
            <span>(iii)</span> <span>Third Party - Bodily Injury (Please provide as much information as possible about any injuries to Third Parties)</span>
         </div>
         <div class="item-title">
            <span >Declaration</span>
         </div>
         <div>
            <span class="declaration">
            I / We declare that the particulars provided in response to the questions contained in this Incident Report Form are true and correct, and I / We have not suppressed, misrepresented or misstated any relevant fact.
            </span>
         </div>
         <div>
            <span class="declaration">
            I / We consent to your use of any personal information included in this Incident Report Form in accordance with your Privacy Policy, which is accessible in the policy document.. I / We understand that failing to provide such personal information may prevent you from assisting with an insurance claim.
            </span>
         </div>
         <div>
            <span>I/We accept</span>
               <img class="checkbox-image" v-if="accept" @click="accept = !accept" src="@/assets/images/checkmark.png" />
               <img class="checkbox-image" v-if="!accept" @click="accept = !accept" src="@/assets/images/uncheckmark.png" />
         </div>
         <div class="row">
            <div class="title-input-col">
               <span>Full Name  Signature</span>
            </div>
            <div class="title-input-col">
               <span>Date</span>
            </div>
         </div>
         <div class="row">
            <div class="title-input-col">
               <input type="text" v-model="fullNameSignature" />
            </div>
            <div class="title-input-col">
               <input type="text" v-model="submitDate" />
            </div>
         </div>
      </div>
      <!-- end 2nd page-->
   </div>
   <div>
   </div>
   <div class="pdf-template-area"  v-if="show">
   <div id="contentToConvert">
      <!-- 1st page-->
      <div class="page">
         <div class="logo-div">
            <img src="@/assets/images/marshlogo.png" />
         </div>
         <div>
            <span class="pdf-title">Deliveroo - Incident Report Form</span>
         </div>
         <div class="item-title">
            <span >1. Type of incident (mandatory)</span>
         </div>
         <div class="row">
            <div class="title-input-col">
               <span>Public Liability</span>
               <img class="checkbox-image" v-if="publicLiability" src="@/assets/images/checkmark.png" />
               <img class="checkbox-image" v-if="!publicLiability" src="@/assets/images/uncheckmark.png" />
            </div>
            <div  class="title-input-col">
               <span>Personal Accident and Income Protection</span>
               <img class="checkbox-image" v-if="personalAccident" src="@/assets/images/checkmark.png" />
               <img class="checkbox-image" v-if="!personalAccident" src="@/assets/images/uncheckmark.png" />
            </div>
         </div>
         <div class="item-title">
            <span >2. Details of Rider (mandatory)</span>
         </div>
         <div class="row">
            <div class="title-col">
               <span>Rider ID Number</span>
            </div>
            <div class="input-col">
               <span class="user-input">{{riderIdNumber}}</span>
            </div>
            <div class="title-col">
               <span>Name</span>
            </div>
            <div class="input-col">
               <span class="user-input">{{riderName}}</span>
            </div>
         </div>
         <div class="row">
            <div class="title-col">
               <span>Address</span>
            </div>
            <div class="input-col">
               <span class="user-input">{{riderAddress}} </span>
            </div>
            <div class="title-col">
               <span>Phone Number <br />(Home / /Mobile)</span>
            </div>
            <div class="input-col">
               <span class="user-input">{{riderPhoneNumber}}</span>
            </div>
         </div>
         <div class="row">
            <div class="title-col">
               <span>Email address</span>
            </div>
            <div class="input-col">
               <span class="user-input">{{riderEmailAddress}}</span>
            </div>
            <div class="title-col">
               <span>Type</span>
            </div>
            <div class="input-col">
               <div class="check-virtical-title">
                  <span>Walker </span>
                  <br />
               <img class="checkbox-image" v-if="riderTypeWalker" src="@/assets/images/checkmark.png" />
               <img class="checkbox-image" v-if="!riderTypeWalker" src="@/assets/images/uncheckmark.png" />
               </div>
               <div class="check-virtical-title">
                  <span>Bicycle </span><br />
               <img class="checkbox-image" v-if="riderTypeBicycle" src="@/assets/images/checkmark.png" />
               <img class="checkbox-image" v-if="!riderTypeBicycle" src="@/assets/images/uncheckmark.png" />
               </div>
               <div class="check-virtical-title">
                  <span>Scooter</span><br />
               <img class="checkbox-image" v-if="riderTypeScooter" src="@/assets/images/checkmark.png" />
               <img class="checkbox-image" v-if="!riderTypeScooter" src="@/assets/images/uncheckmark.png" />
               </div>
               <div class="check-virtical-title">
                  <span>Car</span><br />
               <img class="checkbox-image" v-if="riderTypeCar" src="@/assets/images/checkmark.png" />
               <img class="checkbox-image" v-if="!riderTypeCar" src="@/assets/images/uncheckmark.png" />
               </div>
               <div class="check-virtical-title">
                  <span>E-scooter</span><br />
               <img class="checkbox-image" v-if="riderTypeEScooter" src="@/assets/images/checkmark.png" />
               <img class="checkbox-image" v-if="!riderTypeEScooter" src="@/assets/images/uncheckmark.png" />
               </div>
            </div>
         </div>
         <div class="item-title">
            <span  >3. Details of Substituted Rider (if applicable)</span>
         </div>
         <div class="row">
            <div class="title-col">
               <span>Rider ID Of Account Holder</span>
            </div>
            <div class="input-col">
               <span class="user-input">{{subRiderIdNumber}}</span>
            </div>
            <div class="title-col">
               <span>Name</span>
            </div>
            <div class="input-col">
               <span class="user-input">{{subRiderName}}</span>
            </div>
         </div>
         <div class="row">
            <div class="title-col">
               <span>Address</span>
            </div>
            <div class="input-col">
               <span class="user-input">{{subRiderAddress}} </span>
            </div>
            <div class="title-col">
               <span>Phone Number  <br />(Home/ / Mobile)</span>
            </div>
            <div class="input-col">
               <span class="user-input">{{subRiderPhoneNumber}}</span>
            </div>
         </div>
         <div class="row">
            <div class="title-col">
               <span>Email address</span>
            </div>
            <div class="input-col">
               <span class="user-input">{{subRiderEmailAddress}}</span>
            </div>
            <div class="title-col">
               <span>Type</span>
            </div>
            <div class="input-col">
               <div class="check-virtical-title">
                  <span>Walker </span>
                  <br />
               <img class="checkbox-image" v-if="subRiderTypeWalker" src="@/assets/images/checkmark.png" />
               <img class="checkbox-image" v-if="!subRiderTypeWalker" src="@/assets/images/uncheckmark.png" />
               </div>
               <div class="check-virtical-title">
                  <span>Bicycle </span><br />
               <img class="checkbox-image" v-if="subRiderTypeBicycle" src="@/assets/images/checkmark.png" />
               <img class="checkbox-image" v-if="!subRiderTypeBicycle" src="@/assets/images/uncheckmark.png" />
               </div>
               <div class="check-virtical-title">
                  <span>Scooter</span><br />
               <img class="checkbox-image" v-if="subRiderTypeScooter" src="@/assets/images/checkmark.png" />
               <img class="checkbox-image" v-if="!subRiderTypeScooter" src="@/assets/images/uncheckmark.png" />
               </div>
               <div class="check-virtical-title">
                  <span>Car</span><br />
               <img class="checkbox-image" v-if="subRiderTypeCar" src="@/assets/images/checkmark.png" />
               <img class="checkbox-image" v-if="!subRiderTypeCar" src="@/assets/images/uncheckmark.png" />
               </div>
               <div class="check-virtical-title">
                  <span>E-scooter</span><br />
               <img class="checkbox-image" v-if="subRiderTypeEScooter" src="@/assets/images/checkmark.png" />
               <img class="checkbox-image" v-if="!subRiderTypeEScooter" src="@/assets/images/uncheckmark.png" />
               </div>
            </div>
         </div>
         <div class="item-title">
            <span  >4. Details of incident space (mandatory)</span>
         </div>
         <div class="row">
            <div class="title-col">
               <span>Date</span>
            </div>
            <div class="input-col">
               <span class="user-input">{{incidentDate}}</span>
            </div>
            <div class="title-col">
               <span>Time</span>
            </div>
            <div class="input-col">
               <span class="user-input">{{incidentTime}}</span>
            </div>
         </div>
         <div class="row">
            <div class="title-col">
               <span>Location</span>
            </div>
            <div class="input-col">
               <span class="user-input">{{incidentLocation}}</span>
            </div>
         </div>
         <div>
            <span>Description in detail</span>
         </div>
         <div>
            <span class="desc">{{incidentDetail}}</span>
         </div>
         <!-- <div>
            <span class="desc">{{incidentDetail2}}</span>
         </div>
         <div>
            <span class="desc">{{incidentDetail3}}</span>
         </div>
         <div>
            <span class="desc">{{incidentDetail4}}</span>
         </div>
         <div>
            <span class="desc">{{incidentDetail5}}</span>
         </div>
         <div>
            <span class="desc">{{incidentDetail6}}</span>
         </div>
         <div>
            <span class="desc">{{incidentDetail7}}</span>
         </div>
         <div>
            <span class="desc">{{incidentDetail8}}</span>
         </div> -->
      </div>
      <!-- end 1st page-->
      <!-- 2nd page-->
      <div class="page">
         <div class="item-title">
            <span >5. Details of Third Party (if relevant)</span>
         </div>
         <div class="row">
            <div class="title-col">
               <span>Name</span>
            </div>
            <div class="input-col">
               <span class="user-input">{{thirdPartyName}} </span>
            </div>
            <div class="title-col">
               <span>Address</span>
            </div>
            <div class="input-col">
               <span class="user-input">{{thirdPartyAddress}} </span>
            </div>
         </div>
         <div class="row">
            <div class="title-col">
               <span>Phone Number</span>
            </div>
            <div class="input-col">
               <span class="user-input">{{thirdPartyPhoneNumber}} </span>
            </div>
            <div class="title-col">
               <span>Email Address</span>
            </div>
            <div class="input-col">
               <span class="user-input">{{thirdPartyEmailAddress}} </span>
            </div>
         </div>
         <div class="item-title">
            <span  >6. Details of Witness (if any) </span>
         </div>
         <div class="row">
            <div class="title-col">
               <span>Name</span>
            </div>
            <div class="input-col">
               <span class="user-input">{{witnessName}} </span>
            </div>
            <div class="title-col">
               <span>Address</span>
            </div>
            <div class="input-col">
               <span class="user-input">{{witnessAddress}} </span>
            </div>
         </div>
         <div class="row">
            <div class="title-col">
               <span>Phone Number</span>
            </div>
            <div class="input-col">
               <span class="user-input">{{witnessPhoneNumber}} </span>
            </div>
            <div class="title-col">
               <span>Email Address</span>
            </div>
            <div class="input-col">
               <span class="user-input">{{witnessEmailAddress}} </span>
            </div>
         </div>
         <div>
            <span>Witness Statement (Yes* / No) - Please attach in email, if yes</span>
         </div>
         <div class="item-title">
            <span  >7. Photos taken at scene - (Yes*/ No) Please attach in email, if yes</span>
         </div>
         <div class="item-title">
            <span  style="margin-right:0px">8.Police Report Number </span>
            <span style="color:red;margin-right:0px" >*</span>
            <span style="margin-right:0px">(mandatory)</span>
         </div>
         <div>
            <span class="red">*Please attach Police Statement in email, if any</span>
         </div>
         <div class="item-title">
            <span  >9. Details of Loss</span>
         </div>
         <div class="item-details-of-loss">
            <span>(i)</span><span>Rider- Bodily Injury (Please provide full details of injuries that you have sustained)</span>
         </div>
         <div class="item-details-of-loss">
            <span>(ii)</span> <span>Third Party(Please provide full details of any loss or damage to property belongingto third parties)</span>
         </div>
         <div class="item-details-of-loss">
            <span>(iii)</span> <span>Third Party - Bodily Injury (Please provide as much information as possible about any injuries to Third Parties)</span>
         </div>
         <div class="item-title">
            <span >Declaration</span>
         </div>
         <div>
            <span class="declaration">
            I / We declare that the particulars provided in response to the questions contained in this Incident Report Form are true and correct, and I / We have not suppressed, misrepresented or misstated any relevant fact.
            </span>
         </div>
         <div>
            <span class="declaration">
            I / We consent to your use of any personal information included in this Incident Report Form in accordance with your Privacy Policy, which is accessible in the policy document.. I / We understand that failing to provide such personal information may prevent you from assisting with an insurance claim.
            </span>
         </div>
         <div>
            <span>I/We accept</span>
               <img class="checkbox-image" v-if="accept" src="@/assets/images/checkmark.png" />
               <img class="checkbox-image" v-if="!accept" src="@/assets/images/uncheckmark.png" />
         </div>
         <div class="row">
            <div class="title-input-col">
               <span>Full Name  Signature</span>
            </div>
            <div class="title-input-col">
               <span>Date</span>
            </div>
         </div>
         <div class="row">
            <div class="title-input-col">
               <span  class="user-input">{{fullNameSignature}}</span>
            </div>
            <div class="title-input-col">
               <span  class="user-input">{{submitDate}}</span>
            </div>
         </div>
      </div>
      <!-- end 2nd page-->
   </div>
   </div>
   <!-- end html for pdf-->
     <div class="button-area">
      <input type="button" value="Submit"  @click="generatePdf"/>
   </div>
</div>
</template>
<script>

export default {
  name: 'ClaimsForm',
  data () {
    return {
      status: 'ready',
      show: false,
      publicLiability: true,
      personalAccident: false,
      riderIdNumber: '최용운',
      riderName: 'Kim samantha',
      riderAddress: '111 Chatham Road South, Tsimshatsui, Kowloon, Hong Kong  ',
      riderPhoneNumber: ' +82-10-8868-8522',
      riderEmailAddress: 'admin@marsh.com',
      riderType: '',
      riderTypeWalker: true,
      riderTypeBicycle: false,
      riderTypeScooter: false,
      riderTypeCar: false,
      riderTypeEScooter: false,
      subRiderIdNumber: '최용운',
      subRiderName: 'Park James',
      subRiderAddress: '132 Chatham Road South, Tsimshatsui, Kowloon, Hong Kong  ',
      subRiderPhoneNumber: ' +82-10-5207-4633',
      subRiderEmailAddress: 'subAdmin@marsh.com',
      subRiderType: '',
      subRiderTypeWalker: true,
      subRiderTypeBicycle: false,
      subRiderTypeScooter: false,
      subRiderTypeCar: false,
      subRiderTypeEScooter: false,
      incidentDate: '2018-08-12',
      incidentTime: '18:00',
      incidentLocation: 'Airport',
      incidentDetail: 'I was hit by car while crossing the street',
      // incidentDetail1: 'I was hit by car while crossing the street',
      // incidentDetail2: 'I',
      // incidentDetail3: 'I was',
      // incidentDetail4: 'I was hit',
      // incidentDetail5: 'I was hit by car',
      // incidentDetail6: 'I was hit by car while',
      // incidentDetail7: 'I was hit by car while crossing',
      // incidentDetail8: 'I was hit by car while crossing the street',
      thirdPartyName: 'Shin Yuliana',
      thirdPartyAddress: '28 Chatham Road South, Tsimshatsui, Kowloon, Hong Kong  ',
      thirdPartyPhoneNumber: ' +82-10-5207-4633',
      thirdPartyEmailAddress: 'yuliana.shin@marsh.com',
      witnessName: 'Lee won',
      witnessAddress: '28 Chatham Road South, Tsimshatsui, Kowloon, Hong Kong  ',
      witnessPhoneNumber: ' +82-10-8868-1111',
      witnessEmailAddress: 'witness@marsh.com',
      accept: false,
      fullNameSignature: '',
      submitDate: ''
    }
  },

   
}
</script>
<style>

</style>
