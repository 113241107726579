import Vuex from 'vuex'
import Vue from 'vue'
import i18n from './../i18n'

Vue.use(Vuex)
// var countries= {
//   HONG_KONG:'Hong Kong',
//   SINGAPORE:'Singapore'
// }
export default new Vuex.Store({
    state: {
        UATAgreed: '',
        ip:'',
        sessionId:'',
        firstAccessLogSent:false,
        isShowClaimProgressBar: true,
        country: '',
        countryCode:'',
        countrySelected: false,
        Precision: 2,
        countryList: [
            {
                countryName: 'Singapore',
                countryCode: 'lang.header.singapore',
                languageList: [
                    {languageName: 'English', languageCode: 'en', default: true}
                ]
            },
            {
                countryName: 'Hong Kong',
                countryCode: 'lang.header.hongkong',
                languageList: [
                    {languageName: 'English', languageCode: 'en', default: true},
                    {languageName: '中文 - 香港', languageCode: 'hk', default: false}
                ]
            }
            // {countryName: 'Taiwan',
            //   countryCode: 'lang.header.taiwan',
            //   languageList: [
            //     {languageName: '中文 - 香港', languageCode: 'tw', default: true}
            //   ]}
        ],
        selectedLanguageCode: '',
        currentStep: 1,
        menu_title: 'menu_title',
        sub_menu_title: 'sub_menu_title',
        declaration: '',
        incidenttype1: null,
        incidenttype2: '',
        MasterData: {},
        reporterrelationship:'',
        reporterdetails: {
            title: "",
            firstname: "",
            middlename: "",
            lastname: "",
            hongkongid: "",
            gender: "",
            dateofbirth: "",
            email: "",
            contactnumber: "",
            country: "",
            district: "",
            address1: "",
            address2: "",
            address3: "",
            address4: "",
            preferedcontact: "CTE"
        },
        riderdetails: {
            title: "",
            typeofrider: "",
            transportationmode: "",
            firstname: "",
            middlename: "",
            lastname: "",
            hongkongid: "",
            gender: "",
            dateofbirth: "",
            deliverooid: "",
            email: "",
            contactnumber: "",
            country: "",
            district: "",
            address1: "",
            address2: "",
            address3: "",
            address4: "",
            preferedcontact: "CTE"
        },
        incidentdetails: {
            wasyourfault: "Y",
            incidentdate: "",
            incidentlocation: "",
            incidenttime: "",
            incidentdescription: "",
            policyreportnumber: "",
            canprovidephoto: "",
            photos: []
        },

        wasanythirdpartyliable: "N",
        thirdpartydetails: {
            firstname: "",
            middlename: "",
            lastname: "",
            email: "",
            contactnumber: "",
            country: "",
            district: "",
            address1: "",
            address2: "",
            address3: "",
            address4: ""
        },
        werethereanywitness: "N",
        witnessdetails: {
            firstname: "",
            middlename: "",
            lastname: "",
            contactnumber: "",
            email: "",
            country: "",
            district: "",
            address1: "",
            address2: "",
            address3: "",
            address4: "",
            canprovidewitness: "Y",
            statementproof: []
        },
        injurydetails: {
            location: "",
            injurytype: "",
            other: ""
        },
        supportingdocuments: {
            totalclaimamount: "",
            describeinjury: "",
            dateofdeath: "",
            wheredeathoccur: "",
            causeofdeath: "",
            claimforfuneralexpenses: "N",
            anydependentchildren: "N",
            attachments: []
        },
        lossordamageitem: [
            {
                wasyouritemlostdamaged: "",
                whatitemlostdamaged: "",
                whenitempurchased: "",
                howmuchitem: "",
                havepurchasedreplaceitem: ""
            },

        ],
        otherinsurancecovering: 'N',
        otherinsurancecoverage: {
            nameofinsurer: '',
            policynumber: '',
            benefitamount: ''
        },
        pleasetickmedicalleave: 'N',
        medicalleave: {
            medicalleavestart: '',
            medicalleavelend: '',
            whenperformlightduty: '',
            whenfullyrecover: '',
            performlightduty: 'N',
            medicaldocuments: []
        },
        transferdetail: {
            accountholder:'',
            bankname:'',
            otherbankname:'',
            accountnumber:'',
            branchcode:''
        },
        submissionid:'',

        defaultFormFields: {
            reporterrelationship:'',
            reporterdetails: {
                title: "",
                firstname: "",
                middlename: "",
                lastname: "",
                hongkongid: "",
                gender: "",
                dateofbirth: "",
                email: "",
                contactnumber: "",
                country: "",
                district: "",
                address1: "",
                address2: "",
                address3: "",
                address4: "",
                preferedcontact: "CTE"
            },
            riderdetails: {
                title: "",
                typeofrider: "",
                transportationmode: "",
                firstname: "",
                middlename: "",
                lastname: "",
                hongkongid: "",
                gender: "",
                dateofbirth: "",
                deliverooid: "",
                email: "",
                contactnumber: "",
                country: "",
                district: "",
                address1: "",
                address2: "",
                address3: "",
                address4: "",
                preferedcontact: "CTE"
            },
            incidentdetails: {
                wasyourfault: "Y",
                incidentdate: "",
                incidentlocation: "",
                incidenttime: "",
                incidentdescription: "",
                policyreportnumber: "",
                canprovidephoto: "",
                photos: []
            },

            wasanythirdpartyliable: "N",
            thirdpartydetails: {
                firstname: "",
                middlename: "",
                lastname: "",
                email: "",
                contactnumber: "",
                country: "",
                district: "",
                address1: "",
                address2: "",
                address3: "",
                address4: ""
            },
            werethereanywitness: "N",
            witnessdetails: {
                firstname: "",
                middlename: "",
                lastname: "",
                contactnumber: "",
                email: "",
                country: "",
                district: "",
                address1: "",
                address2: "",
                address3: "",
                address4: "",
                canprovidewitness: "Y",
                statementproof: []
            },
            injurydetails: {
                location: "",
                injurytype: "",
                other: ""
            },
            supportingdocuments: {
                totalclaimamount: "",
                describeinjury: "",
                dateofdeath: "",
                wheredeathoccur: "",
                causeofdeath: "",
                claimforfuneralexpenses: "N",
                anydependentchildren: "N",
                attachments: []
            },
            lossordamageitem: [
                {
                    wasyouritemlostdamaged: "",
                    whatitemlostdamaged: "",
                    whenitempurchased: "",
                    howmuchitem: "",
                    havepurchasedreplaceitem: ""
                },
            ],
            otherinsurancecovering: 'N',
            otherinsurancecoverage: {
                nameofinsurer: '',
                policynumber: '',
                benefitamount: ''
            },
            pleasetickmedicalleave: 'N',
            medicalleave: {
                medicalleavestart: '',
                medicalleavelend: '',
                whenperformlightduty: '',
                whenfullyrecover: '',
                performlightduty: 'N',
                medicaldocuments: []
            },
            transferdetail: {
                accountholder:'',
                bankname:'',
                otherbankname:'',
                accountnumber:'',
                branchcode:''
            }
        }
    },

    getters: {
        uatAgreed: function (state) {
            return state.UATAgreed
        },
        ip:function(state){
            return state.ip
        },
        sessionId:function(state){
            return state.sessionId
        },
        firstAccessLogSent:function(state){
            return state.firstAccessLogSent
        },
        getReporterRelationship:function(state){
            return state.reporterrelationship
        },
        getSubmissionId:function (state) {
            return state.submissionid
        },
        getIncidentPhotos: function(state){
            return state.incidentdetails.photos
        },
        declaration: function(state){
          return state.declaration
        },
        incidentType:function(state){
          return state.incidenttype1
        },
        coverageType: function (state){
          return state.incidenttype2
        },
        incidentDetailsPhotoCode: function (state) {
            return state.MasterData['incidentdetailphoto'].code
        },
        witnessStatementCode: function (state) {
            return state.MasterData['witnessdetailsproof'].code
        },
        bankOptions: function (state) {
            return state.MasterData['bank']
        },
        branchCodeOptions: function (state) {
            return state.MasterData['branchcode']
        },
        countryCodeOptions: function (state) {
            return state.MasterData['countrycode']
        },
        currencyOptions: function (state) {
            return state.MasterData['currency']
        },
        incidentType1Options: function (state) {
            return state.MasterData['incidenttype1']
        },
        incidentType2Options: function (state) {
            return state.MasterData['incidenttype2']
        },
        injuryLocationOptions: function (state) {
            return state.MasterData['injurylocation']
        },
        injuryTypeOptions: function (state) {
            return state.MasterData['injurytype']
        },
        lostOrDamageOptions: function (state) {
            return state.MasterData['lostordamage']
        },
        prefferedContactOptions: function (state) {
            return state.MasterData['prefferedcontact']
        },
        radioButtonOptions: function (state) {
            return state.MasterData['radiobutton']
        },
        relationOptions: function (state) {
            return state.MasterData['relationship']
        },
        supportingDocumentOptions: function (state) {
            return state.MasterData['supportingdocument']
        },
        titleOptions: function (state) {
            return state.MasterData['title']
        },
        transportationModeOptions: function (state) {
            return state.MasterData['transportationmode']
        },
        typeofRiderOptions: function (state) {
            return state.MasterData['typeofrider']
        },
        witnessDetailsProofOptions: function (state) {
            return state.MasterData['witnessdetailsproof']
        },

        genderOptions: function (state) {
            return state.MasterData['gender']
        },
        supportDocumentOptions: function (state) {
            return state.MasterData['supportingdocument']
        },
        medicalDocumentOptions: function (state){
            return state.MasterData['medicaldocument']
        },

        reporterDetails: function (state) {
            return state.reporterdetails
        },
        riderDetails: function (state) {
            return state.riderdetails
        },
        incidentDetails: function (state) {
            return state.incidentdetails
        },
        wasAnyThirdPartyLiable: function (state) {
            return state.wasanythirdpartyliable
        },
        thirdPartyDetails: function (state) {
            return state.thirdpartydetails
        },
        wereThereAnyWitness: function (state) {
            return state.werethereanywitness
        },
        witnessDetails: function (state) {
            return state.witnessdetails
        },
        injuryDetails: function (state) {
            return state.injurydetails
        },
        supportDocumentDetails: function (state) {
            return state.supportingdocuments
        },
        lossOrDamageItem: function (state) {
            return state.lossordamageitem
        },
        otherInsuranceCovering: function (state) {
            return state.otherinsurancecovering
        },
        otherInsuranceCoverage: function (state){
            return state.otherinsurancecoverage
        },
        pleaseTickMedicalLeave: function (state){
            return state.pleasetickmedicalleave
        },
        medicalLeave: function (state){
            return state.medicalleave
        },
        transferDetail:function (state){
            return state.transferdetail
        },
    },

    actions: {
        hello(context) {
            console.log('hello')
        },
        updateCountry(context, status) {
            context.commit('setCountry', status)
        },
        updateCountrySelection(context, status) {
            context.commit('setCountrySelection', status)
        },
        updateLanguageCode(context, status) {
            context.commit('setLanguageCode', status)
        },
        updatei18n(context, status) {
            context.commit('seti18n', status)
        },
        saveDeclaration(context,data){
            context.commit('setDeclaration',data);
        },
        saveMasterData(context,data){
            context.commit('setMasterData',data);
        },
        saveSubmissionId(context,data){
            context.commit('setSubmissionId',data);
        },
        updateIp(context,data){
            context.commit('setIp',data);
        },
        updateSessionId(context,data){
            context.commit('setSessionId',data);
        },
        updateFirstAccessLogSent(context,data){
            context.commit('setFirstAccessLogSent',data);
        }
    },

    mutations: {
        resetFormData(state) {
            state.reporterdetails = JSON.parse(JSON.stringify(state.defaultFormFields.reporterdetails))
            state.reporterrelationship = JSON.parse(JSON.stringify(state.defaultFormFields.reporterrelationship))
            state.riderdetails = JSON.parse(JSON.stringify(state.defaultFormFields.riderdetails))
            state.incidentdetails = JSON.parse(JSON.stringify(state.defaultFormFields.incidentdetails))
            state.wasanythirdpartyliable = JSON.parse(JSON.stringify(state.defaultFormFields.wasanythirdpartyliable))
            state.thirdpartydetails = JSON.parse(JSON.stringify(state.defaultFormFields.thirdpartydetails))
            state.werethereanywitness = JSON.parse(JSON.stringify(state.defaultFormFields.werethereanywitness))
            state.witnessdetails = JSON.parse(JSON.stringify(state.defaultFormFields.witnessdetails))
            state.injurydetails = JSON.parse(JSON.stringify(state.defaultFormFields.injurydetails))
            state.supportingdocuments = JSON.parse(JSON.stringify(state.defaultFormFields.supportingdocuments))
            state.lossordamageitem = JSON.parse(JSON.stringify(state.defaultFormFields.lossordamageitem))
            state.otherinsurancecovering = JSON.parse(JSON.stringify(state.defaultFormFields.otherinsurancecovering))
            state.otherinsurancecoverage = JSON.parse(JSON.stringify(state.defaultFormFields.otherinsurancecoverage))
            state.pleasetickmedicalleave = JSON.parse(JSON.stringify(state.defaultFormFields.pleasetickmedicalleave))
            state.medicalleave = JSON.parse(JSON.stringify(state.defaultFormFields.medicalleave))
            state.transferdetail = JSON.parse(JSON.stringify(state.defaultFormFields.transferdetail))
            state.incidenttype2 = ''
        },
        setCountry(state, status) {
            state.country = status
            if (status==='Hong Kong'){
                state.countryCode='HK'
            }else {
                state.countryCode='SG'
            }
        },
        setCountrySelection(state, status) {
            state.countrySelected = status
        },
        setLanguageCode(state, status) {
            state.selectedLanguageCode = status
            i18n.locale = status
        },
        seti18n(state, status) {
            i18n.locale = status
        },
        changeMenuTitle(state, newTitle) {
            // state.counter++;
            this.state.menu_title = newTitle
        },
        changeSubMenuTitle(state, newTitle) {
            this.state.sub_menu_title = newTitle
        },
        changeStep(state, step) {
            this.state.currentStep = step
        },
        rider_details(state, data) {
            this.state.rider_details.find(t => t.id = data.id).value = data.value
        },
        saveIncidentType(state, type) {
            this.state.incidenttype1 = type
        },
        changeShowClaimProgressBarStatus(state, value) {
            this.state.isShowClaimProgressBar = value;
        },
        saveCoverageType(state, type) {
            this.state.incidenttype2 = type
        },
        saveRiderCountry(state, data) {
            //this.state.rider_details.find(t => t.title === data.title).value = data.value
            this.state.riderdetails.country = data
        },
        setMasterData(state, data) {
            console.log(data)
            this.state.MasterData = data;
        },
        saveReporterDetails(state, payload) {
            return state.reporterdetails = payload
        },
        saveRiderDetails(state, payload) {
            return state.riderdetails = payload
        },
        saveIncidentDetails(state, payload) {
            return state.incidentdetails = payload
        },
        saveThirdPartyDetails(state, payload) {
            return state.thirdpartydetails = payload
        },
        saveWasAnyThirdPartyLiable(state, payload) {
            return state.wasanythirdpartyliable = payload
        },
        saveWereThereAnyWitness(state, payload) {
            return state.werethereanywitness = payload
        },
        saveWitnessDetails(state, payload) {
            return state.witnessdetails = payload
        },
        saveInjuryDetails(state, payload) {
            return state.injurydetails = payload
        },
        saveSupportDocumentDetails(state, payload) {
            return state.supportingdocuments = payload
        },
        saveLossOrDamageItem(state, payload) {
            return state.lossordamageitem = payload
        },
        saveOtherInsuranceCovering(state, payload) {
            return state.otherinsurancecovering = payload
        },
        saveOtherInsuranceCoverage(state,payload){
            return state.otherinsurancecoverage = payload
        },
        savePleaseTickMedicalLeave(state,payload){
            return state.pleasetickmedicalleave = payload
        },
        saveMedicalLeave(state,payload){
            return state.medicalleave = payload
        },
        saveTransferDetail(state,payload){
            return state.transferdetail = payload
        },
        setDeclaration(state,payload){
            return state.declaration = payload
        },
        setSubmissionId(state,payload){
            return state.submissionid = payload
        },
        setReporterRelationship(state,payload){
            return state.reporterrelationship = payload
        },

        setIp(state,payload){
            return state.ip = payload
        },
        setSessionId(state,payload){
            return state.sessionId = payload
        },
        setFirstAccessLogSent(state,payload){
            return state.firstAccessLogSent = payload
        },
        agreeUseOfUAT(state){
            return state.UATAgreed= 'true'
        }
    },
})
