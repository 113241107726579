import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './en-US.json'
// import tw from './zh-TW.json'
import hk from './zh-HK.json'

Vue.use(VueI18n)

export default new VueI18n({
  locale: 'en',
  messages: {
    en: {lang: en},
    // tw: {lang: tw},
    hk: {lang: hk}
  }
})
