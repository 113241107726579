<template>
  <div>
    <div class="country-selector">
      <div class="title">
        Deliveroo Rider Insurance
        <br/>Protection Programs
      </div>
      <div v-show="!languageSelect">
        <h1 class="sub-title">Please select your country.</h1>
        <div class="buttons">
          <ul class="clearfix">
            <li class="Singapore" @click="selectCountry('Singapore')">
              <span></span>
              <span class="country-name">Singapore</span>
            </li>
            <li class="virtical-line">
              <div>&nbsp;</div>
            </li>
            <li class="Hongkong" @click="selectCountry('Hong Kong')">
              <span></span>
              <span class="country-name">Hong Kong</span>
            </li>
            <!-- <li class='virtical-line'><div >&nbsp;</div></li>
            <li class="Taiwan"   @click="selectCountry('Taiwan')"><span></span><span class="country-name">Taiwan</span></li>-->
          </ul>
        </div>
      </div>
      <div v-show="languageSelect">
        <h1 class="sub-title">Please select a language</h1>
        <div class="buttons">
          <ul class="clearfix langSelection">
            <li class="Hongkong">
              <span></span>
            </li>
            <li class="mobile-li">
              <span class="country-title">Hong Kong</span>
              <language-selector class="language-selector-popup" v-bind:show-place-holder="true"></language-selector>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="country-selector-background"></div>
  </div>
</template>

<script>
import languageSelector from "@/common/language.selector";
import axios from 'axios';
import PremiumApi from "@/plugins/axios";

export default {
  components: {
    languageSelector
  },
  data() {
    return {
      languageSelect: false
    };
  },
  props: [],
  methods: {
    selectCountry(val) {
      this.$store.dispatch("updateCountry", val);
      var langs = this.$store.state.countryList.filter(function (item) {
        return item.countryName === val;
      })[0].languageList;
      if (langs.length > 1) {
        this.languageSelect = true;
      } else {
        this.$store.dispatch("updateCountrySelection", true);
      }

      if (val !== "") {
        var langList = this.$store.state.countryList.filter(function (item) {
          return item.countryName === val;
        })[0].languageList;
        if (langList.length === 1) {
          this.$store.dispatch("updateLanguageCode", langList[0].languageCode);
        }
      }

      if (!this.$store.getters.firstAccessLogSent &&  this.$store.getters.ip!=='') {

        let ip = this.$store.getters.ip.replace(/(\r\n\t|\n|\r\t)/gm,"");
        let sessionId = this.$store.getters.sessionId;
        let accesssLog = {
          countrycode: this.$store.state.countryCode,
          url: window.location.href.replace('#',''),
          uniqueid_user:'',
          uniqueid_session: sessionId,
          ip: ip
        }

        this.$store.dispatch("updateFirstAccessLogSent",true);
        PremiumApi.postAccessLog(accesssLog,
            function (response) {
            }
        );
      }
    },
    langChange(languageCode) {
      console.log(languageCode);
      document.getElementById("mobile-menu").classList.remove("side-nav");
      this.$store.dispatch("updateLanguageCode", languageCode);
      this.$store.dispatch("updateCountrySelection", true);
    }
  },
  computed: {
    langCodes() {
      var val = this.$store.state.country;
      if (val !== "") {
        return this.$store.state.countryList.filter(function (item) {
          return item.countryName === val;
        })[0].languageList;
      }
      return "EN-us";
    }
  }
};
</script>

<style>
</style>
