<template>
  <div  class="my-body" >
<section class="banner-top hidden-xs">
  <v-container class="container">
    <v-row class="row">
      <v-col  class="col-xs-6 banner-left">
        <br>
        <br>
        <h1 :lang="getLang" v-html="$t('lang.policy-coverage.topBanner')">
        </h1>
      </v-col>

      <v-col class="col-xs-6 banner-right">

      </v-col>
    </v-row>
  </v-container>
</section>

<!-- for mobile -->
<section class="banner-top-mobile hidden-lg hidden-md hidden-sm">
  <div class="">
    <div class="">
      <div class="bann-top-mobile-top policy-bann">
      </div>
      <div class="bann-bottom-mobile-bt col-md-12">
        <h1 :lang="getLang" v-html="$t('lang.policy-coverage.topBanner_M')">
        </h1>
      </div>
    </div>
  </div>
</section>
<!-- for mobile -->

<section class="business">
  <v-container >
    <div class="row">
      <div class="col-md-12 text-center">
        <ul class=" hidden-lg hidden-md hidden-sm r-inci-mobile">
          <li><router-link :to="'/claims-process'" class="uppercase" :lang="getLang">{{$t('lang.common.reportAnIncident')}}</router-link></li>
        </ul>
        <h3 :lang="getLang" class="heading">{{$t('lang.policy-coverage.weAreHereToHelp')}}</h3>
      </div>
      <div class="col-md-10 col-md-offset-1 text-center pt-3">
        <p :lang="getLang" class="description" v-html="$t('lang.policy-coverage.yourPoliciesAreArranged')"></p>
      </div>
      <div class="col-md-10 col-md-offset-1 hidden-xs iconbox">
        <v-row>
          <div class="col-md-3 col-sm-3 col-xs-6 text-center">
            <div class="insurance-box bl-org">
              <img src="@/assets/images/icons/icon_scooter.png">
            </div>
          </div>
          <div class="col-md-3 col-sm-3 col-xs-6 text-center">
            <div class="insurance-box bl-blue">
              <img src="@/assets/images/icons/icon_car.png">
            </div>
          </div>
          <div class="col-md-3 col-sm-3 col-xs-6 text-center">
            <div class="insurance-box bl-pn">
              <img src="@/assets/images/icons/icon_cycle.png">
            </div>
          </div>
          <div class="col-md-3 col-sm-3 col-xs-6 text-center">
            <div class="insurance-box bl-nv">
              <img src="@/assets/images/icons/icon_foot.png">
            </div>
          </div>
        </v-row>
      </div>
      <!-- mobile carousel -->
      <div class="col-xs-12  hidden-lg hidden-md hidden-sm mobile-business">
        <carousel :loop="true" :autoplay="true" :perPage="1" :autoplayTimeout="4000">
          <slide
              data-index="0"
              data-name="icon_scooter">
            <img src="@/assets/images/icons/icon_scooter.png">
          </slide>
          <slide
              data-index="1"
              data-name="icon_car">
            <img src="@/assets/images/icons/icon_car.png">
          </slide>
          <slide
              data-index="2"
              data-name="icon_cycle">
            <img src="@/assets/images/icons/icon_cycle.png">
          </slide>
          <slide
              data-index="3"
              data-name="icon_foot">
            <img src="@/assets/images/icons/icon_foot.png">
          </slide>
        </carousel>
      </div>
      <!-- mobile carousel -->
    </div>
    <v-row>
      <v-col class="col-md-6 col-sm-6">
        <v-row class="blog-wrap">
          <h5 :lang="getLang" :class="{boldText: getLang == 'zh'}">{{$t('lang.common.personalAccidentAndIncomeProtection')}}</h5>
          <p class="blog-desc" :lang="getLang">{{$t('lang.common.coversAllRidersWhenWorking')}}</p>
          <img src="@/assets/images/img_work_injury_large.jpg" class="img-responsive bottom_image" width="100%"/>
          <!--
            <p class="hidden-xs">It offers you income in case you can't work due to an accident which happened whilst working. You can claim a weekly payment if you are injured and unable to work. You'll also receive a lump sum if you suffer one of the injuries covered under the policy.</p>
          -->
          <v-row>
            <v-col class="col-sm-12">
              <button class="btn-blog text text-capitalize" @click="movePage('/group-personal')">
                <router-link class="findBtn text-capitalize" :to="'/group-personal'" :lang="getLang">{{$t('lang.common.findOutMore')}}</router-link>
              </button>
              <router-link :to="'/claims-process'" class="hidden-xs" :lang="getLang">{{$t('lang.common.reportAnIncident')}}</router-link>
            </v-col>
          </v-row>
        </v-row>
      </v-col>
      <div class="col-md-6 col-sm-6">
        <v-row class="blog-wrap">
          <h5 :lang="getLang" :class="{boldText: getLang == 'zh'}">{{$t('lang.common.publicLiability')}}</h5>
          <p class="blog-desc" :lang="getLang">{{$t('lang.common.coversAllRidersWhenOffVehicle')}}</p>
          <img src="@/assets/images/public-liability-insurance.jpg" class="img-responsive" width="100%"/>
          <!--
          <p class="hidden-xs">It offers you income in case you can't work due to an accident which happened whilst working. You can claim a weekly payment if you are injured and unable to work. You'll also receive a lump sum if you suffer one of the injuries covered under the policy.</p>
          -->
          <v-row class="row">
            <v-col class="col-sm-12 col-xs-12">
              <button class="btn-blog text text-capitalize" @click="movePage('/liability')">
                <router-link class="findBtn text-capitalize" :to="'/liability'" :lang="getLang">{{$t('lang.common.findOutMore')}}</router-link>
              </button>
              <router-link :to="'/claims-process'" class="hidden-xs" :lang="getLang">{{$t('lang.common.reportAnIncident')}}</router-link>
            </v-col>
          </v-row>
        </v-row>
      </div>
    </v-row>
  </v-container>
</section>
<section class="benefits-section">
  <v-container class="container">
    <v-row class="row">
      <v-col class="col-md-6">
        <h2 :lang="getLang">
          {{$t('lang.policy-coverage.theseBenefitsApplyToAllRiders')}}
        </h2>
      </v-col>
       <v-spacer></v-spacer>
      <div class="col-md-4 col-md-offset-2 col-sm-5 text-right hidden-xs benefits-sec-back">
        <!-- <img src="@/assets/images/mobile_phones.png" width="100%"> -->
      </div>
    </v-row>
  </v-container>
</section>
<section class="footer-section  hidden-lg hidden-md hidden-sm">
  <v-container class="container">
    <v-row class="row">
      <div class="col-md-6 col-sm-7 arrange-in-partnership" :lang="getLang" v-html="$t('lang.common.arrangedInPartnership')">

      </div>
    </v-row>
  </v-container>
</section>
</div>
</template>
<script>
// import {CountrySelectorPopupComponent} from '../shared/country.selector.popup';
// import {GlobalService} from '../shared/global.service';
import { Carousel, Slide } from 'vue-carousel'

export default {
  name: 'PolicyCoverage',
  components: {
    Carousel,
    Slide
  },
  methods: {
    movePage (path) {
      this.$router.push(path)
    }
  },
  computed: {
    getLang () {
      return this.$store.state.selectedLanguageCode === 'hk' || this.$store.state.selectedLanguageCode === 'tw' ? 'zh' : 'en'
    }
  },mounted(){
    this.$vuetify.goTo(0, {container: document.documentElement,duration:0})
  }
}
</script>
<style>
</style>
