<template>
<keep-alive>
<component :is="dynamicComponent"></component>
</keep-alive>
</template>
<script>
import ContactUsSG from '@/components/sg/contact-us/contact-us.component'
import ContactUsHK from '@/components/hk/contact-us/contact-us.component'
export default{
  computed: {
    dynamicComponent () {
      // Hongkong
      if (this.$store.state.country === 'Hong Kong') {
        return ContactUsHK
      } else {
        return ContactUsSG
      }
    }
  }
}
</script>
