<template>
   <div class="my-body" b-bind:class="[show?'overflow-hide':'overflow-scroll']">
   <div class="print-background" v-if="show">
      <span class="loading-text"> Processing...</span>
      <br />
      <img src="@/assets/images/loading.gif" class="loading"/>
   </div>

   <div class="form-area" v-if="!show">
      <!-- 1st page-->
     <b-form @submit="onSubmit" @reset="onReset" v-if="showB">

      <b-form-group id="typeOfIncident" label="Type of incident">
        <b-form-checkbox-group id="checkboxes1" name="flavour1" v-model="typeOfIncident" :options="incidentTypes">
        </b-form-checkbox-group>
      </b-form-group>
      <br>
      <b-form-group id="substitutedRider" label="Are you a substituted Rider?">
        <b-form-radio-group id="radios1" v-model="substitutedRider" :options="radioOptions" name="radioOptions">
        </b-form-radio-group>
      </b-form-group>
      <br>
      <b-card bg-variant="light">
        <b-form-group horizontal
                      breakpoint="lg"
                      label="Details of Rider"
                      label-size="lg"
                      label-class="font-weight-bold pt-0"
                      class="mb-0">
          <b-form-group horizontal
                        label="Rider ID number"
                        label-class="text-sm-right"
                        label-for="riderIdNumber">
            <b-form-input id="riderIdNumber" v-model="riderIdNumber"></b-form-input>
          </b-form-group>
          <b-form-group horizontal
                        label="Name"
                        label-class="text-sm-right"
                        label-for="riderName">
            <b-form-input id="riderName" v-model="riderName"></b-form-input>
          </b-form-group>
          <b-form-group horizontal
                        label="Address"
                        label-class="text-sm-right"
                        label-for="riderAddress">
            <b-form-input id="riderAddress" v-model="riderAddress"></b-form-input>
          </b-form-group>
          <b-form-group horizontal
                        label="Phone Number"
                        label-class="text-sm-right"
                        label-for="riderPhoneNumber">
            <b-form-input id="riderPhoneNumber" v-model="riderPhoneNumber"></b-form-input>
          </b-form-group>
          <b-form-group horizontal
                        label="Email Address"
                        label-class="text-sm-right"
                        label-for="riderEmailAddress">
            <b-form-input id="riderEmailAddress" v-model="riderEmailAddress"></b-form-input>
          </b-form-group>
          <b-form-group horizontal
                        label="Type"
                        label-class="text-sm-right"
                        class="mb-0">
            <b-form-radio-group class="pt-2" :options="types" />
          </b-form-group>
        </b-form-group>
      </b-card>
      <br>
      <b-card bg-variant="light">
        <b-form-group horizontal
                      breakpoint="lg"
                      label="Details of Substituted Rider"
                      label-size="lg"
                      label-class="font-weight-bold pt-0"
                      class="mb-0">
          <b-form-group horizontal
                        label="Name"
                        label-class="text-sm-right"
                        label-for="subRiderName">
            <b-form-input id="subRiderName" v-model="subRiderName"></b-form-input>
          </b-form-group>
          <b-form-group horizontal
                        label="Address"
                        label-class="text-sm-right"
                        label-for="subRiderAddress">
            <b-form-input id="subRiderAddress" v-model="subRiderAddress"></b-form-input>
          </b-form-group>
          <b-form-group horizontal
                        label="Phone Number"
                        label-class="text-sm-right"
                        label-for="subRiderPhoneNumber">
            <b-form-input id="subRiderPhoneNumber" v-model="subRiderPhoneNumber"></b-form-input>
          </b-form-group>
          <b-form-group horizontal
                        label="Email Address"
                        label-class="text-sm-right"
                        label-for="subRiderEmailAddress">
            <b-form-input id="subRiderEmailAddress" v-model="subRiderEmailAddress"></b-form-input>
          </b-form-group>
          <b-form-group horizontal
                        label="Type"
                        label-class="text-sm-right"
                        class="mb-0">
            <b-form-radio-group class="pt-2" :options="types" />
          </b-form-group>
        </b-form-group>
      </b-card>
      <br>
      <b-card bg-variant="light">
        <b-form-group horizontal
                      breakpoint="lg"
                      label="Details of incident space"
                      label-size="lg"
                      label-class="font-weight-bold pt-0"
                      class="mb-0">
          <b-form-group horizontal
                        label="Date"
                        label-class="text-sm-right"
                        label-for="incidentDate">
            <b-form-input id="incidentDate" v-model="incidentDate" type="date"></b-form-input>
          </b-form-group>
          <b-form-group horizontal
                        label="Time"
                        label-class="text-sm-right"
                        label-for="incidentTime">
            <b-form-input id="incidentTime" v-model="incidentTime" type="time"></b-form-input>
          </b-form-group>
          <b-form-group horizontal
                        label="Location"
                        label-class="text-sm-right"
                        label-for="incidentLocation">
            <b-form-input id="incidentLocation" v-model="incidentLocation"></b-form-input>
          </b-form-group>
          <b-form-group horizontal
                        label="Description in detail"
                        label-class="text-sm-right"
                        label-for="incidentDetail">
            <b-form-textarea  id="incidentDetail" v-model="incidentDetail" :rows="6" :max-rows="8"></b-form-textarea >
          </b-form-group>
        </b-form-group>
      </b-card>
      <br>
      <b-form-group id="thirdParty" label="Do you have third party?">
        <b-form-radio-group id="radios2" v-model="thirdParty" :options="radioOptions" name="radioOptions2">
        </b-form-radio-group>
      </b-form-group>
      <br>
      <b-card bg-variant="light">
        <b-form-group horizontal
                      breakpoint="lg"
                      label="Details of Third Party"
                      label-size="lg"
                      label-class="font-weight-bold pt-0"
                      class="mb-0">
          <b-form-group horizontal
                        label="Name"
                        label-class="text-sm-right"
                        label-for="thirdPartyName">
            <b-form-input id="thirdPartyName" v-model="thirdPartyName"></b-form-input>
          </b-form-group>
          <b-form-group horizontal
                        label="Address"
                        label-class="text-sm-right"
                        label-for="thirdPartyAddress">
            <b-form-input id="thirdPartyAddress" v-model="thirdPartyAddress"></b-form-input>
          </b-form-group>
          <b-form-group horizontal
                        label="Phone Number"
                        label-class="text-sm-right"
                        label-for="thirdPartyPhoneNumber">
            <b-form-input id="thirdPartyPhoneNumber" v-model="thirdPartyPhoneNumber"></b-form-input>
          </b-form-group>
          <b-form-group horizontal
                        label="Email Address"
                        label-class="text-sm-right"
                        label-for="thirdPartyEmailAddress">
            <b-form-input id="thirdPartyEmailAddress" v-model="thirdPartyEmailAddress"></b-form-input>
          </b-form-group>
        </b-form-group>
      </b-card>
      <br>
      <b-form-group id="witness" label="Do you have witness?">
        <b-form-radio-group id="radios3" v-model="witness" :options="radioOptions" name="radioOptions3">
        </b-form-radio-group>
      </b-form-group>
      <br>
      <b-card bg-variant="light">
        <b-form-group horizontal
                      breakpoint="lg"
                      label="Details of Witness"
                      label-size="lg"
                      label-class="font-weight-bold pt-0"
                      class="mb-0">
          <b-form-group horizontal
                        label="Name"
                        label-class="text-sm-right"
                        label-for="witnessName">
            <b-form-input id="witnessName" v-model="witnessName"></b-form-input>
          </b-form-group>
          <b-form-group horizontal
                        label="Address"
                        label-class="text-sm-right"
                        label-for="witnessAddress">
            <b-form-input id="witnessAddress" v-model="witnessAddress"></b-form-input>
          </b-form-group>
          <b-form-group horizontal
                        label="Phone Number"
                        label-class="text-sm-right"
                        label-for="witnessPhoneNumber">
            <b-form-input id="witnessPhoneNumber" v-model="witnessPhoneNumber"></b-form-input>
          </b-form-group>
          <b-form-group horizontal
                        label="Email Address"
                        label-class="text-sm-right"
                        label-for="witnessEmailAddress">
            <b-form-input id="witnessEmailAddress" v-model="witnessEmailAddress"></b-form-input>
          </b-form-group>
          <b-form-group id="witnessStatement" label="Witness Statement (Please attach in email, if yes)">
            <b-form-radio-group id="radios4" v-model="witnessStatement" :options="radioOptions" name="radioOptions4">
            </b-form-radio-group>
          </b-form-group>
          <b-form-group id="attachForWitness" label="Attachement for witness">
            <b-form-file v-model="attachForWitness" multiple  class="mt-3" plain></b-form-file>
          </b-form-group>
        </b-form-group>
      </b-card>
      <br>
      <b-card bg-variant="light">
          <b-form-group id="photosTaken" label="Photos taken at scene (Please attach in email, if yes)">
            <b-form-radio-group id="radios5" v-model="photosTaken" :options="radioOptions" name="radioOptions5">
            </b-form-radio-group>
          </b-form-group>
          <b-form-group id="attachTakenPhotos" label="Taken photos">
            <b-form-file v-model="attachTakenPhotos" multiple  class="mt-3" plain></b-form-file>
          </b-form-group>
      </b-card>
      <br>
      <b-card bg-variant="light">
          <b-form-group id="isPoliceReportNumber" label="Do you have Police Report Number?">
            <b-form-radio-group id="radios6" v-model="isPoliceReportNumber" :options="radioOptions" name="radioOptions6">
            </b-form-radio-group>
          </b-form-group>
          <b-form-group horizontal
                        label="Police Report Number"
                        label-class="text-sm-right"
                        label-for="policeReportNumber">
            <b-form-input id="policeReportNumber" v-model="policeReportNumber"></b-form-input>
          </b-form-group>
          <b-form-group id="attachPoliceStatement" label="Please attach Police Statement in email, if any">
            <b-form-file v-model="attachPoliceStatement" multiple  class="mt-3" plain></b-form-file>
          </b-form-group>
      </b-card>
      <br>
      <b-card bg-variant="light">
        <b-form-group horizontal
                      breakpoint="lg"
                      label="Details of Loss"
                      label-size="lg"
                      label-class="font-weight-bold pt-0"
                      class="mb-0">

i. Rider Bodily Injury - please provide full details of the injuries that you have suffered<br>
ii. Third Party Property Damage - please provide full details of any loss or damage to property belonging to third parties<br>
iii. Third Party Bodily Injury - please provide as much information as possible about any injuries to third parties<br>
          <b-form-group horizontal
                        label="Description in detail"
                        label-class="text-sm-right"
                        label-for="detailsOfLoss">
            <b-form-textarea  id="detailsOfLoss" v-model="detailsOfLoss" :rows="6" :max-rows="8"></b-form-textarea >
          </b-form-group>
        </b-form-group>
      </b-card>
      <br>
      <b-card bg-variant="light">
        <b-form-group horizontal
                      breakpoint="lg"
                      label="Declaration"
                      label-size="lg"
                      label-class="font-weight-bold pt-0"
                      class="mb-0">
                      I / We declare that the particulars provided in response to the questions contained in this Incident Report Form are true and correct, and I / We have not suppressed, misrepresented or misstated any relevant fact.<br>
                      I / We consent to your use of any personal information included in this Incident Report Form in accordance with your Privacy Policy, which is accessible in the policy document... I / We understand that failing to provide such personal information may prevent you from assisting with an insurance claim.<br>
          <b-form-checkbox id="checkbox1"
                          v-model="accept"
                          value="accepted"
                          unchecked-value="not_accepted">
            I/We accept
          </b-form-checkbox>
          <b-form-group horizontal
                        label="Full Name & Signature"
                        label-class="text-sm-right"
                        label-for="fullName">
            <b-form-input id="fullName" disabled v-model="riderName"></b-form-input>
          </b-form-group>
          <b-form-group horizontal
                        label="Date"
                        label-class="text-sm-right"
                        label-for="getDate">
            <b-form-input id="getDate" disabled v-model="getDate" type="date"></b-form-input>
          </b-form-group>
        </b-form-group>
      </b-card>

      <b-button type="submit" variant="primary">Submit</b-button>
      <b-button type="reset" variant="danger">Reset</b-button>
    </b-form>
      <!-- end 2nd page-->
   </div>
   <div>
   </div>
   <div class="pdf-template-area"  v-if="show">
   <div id="contentToConvert">
      <!-- 1st page-->
      <div class="page">
         <div class="logo-div">
            <img src="@/assets/images/marshlogo.png" />
         </div>
         <div>
            <span class="pdf-title">Deliveroo - Incident Report Form</span>
         </div>
         <div class="item-title">
            <span >1. Type of incident (mandatory)</span>
         </div>
         <div class="row">
            <div class="title-input-col">
               <span>Public Liability</span>
               <img class="checkbox-image" v-if="publicLiability" src="@/assets/images/checkmark.png" />
               <img class="checkbox-image" v-if="!publicLiability" src="@/assets/images/uncheckmark.png" />
            </div>
            <div  class="title-input-col">
               <span>Personal Accident and Income Protection</span>
               <img class="checkbox-image" v-if="personalAccident" src="@/assets/images/checkmark.png" />
               <img class="checkbox-image" v-if="!personalAccident" src="@/assets/images/uncheckmark.png" />
            </div>
         </div>
         <div class="item-title">
            <span >2. Details of Rider (mandatory)</span>
         </div>
         <div class="row">
            <div class="title-col">
               <span>Rider ID Number</span>
            </div>
            <div class="input-col">
               <span class="user-input">{{riderIdNumber}}</span>
            </div>
            <div class="title-col">
               <span>Name</span>
            </div>
            <div class="input-col">
               <span class="user-input">{{riderName}}</span>
            </div>
         </div>
         <div class="row">
            <div class="title-col">
               <span>Address</span>
            </div>
            <div class="input-col">
               <span class="user-input">{{riderAddress}} </span>
            </div>
            <div class="title-col">
               <span>Phone Number <br />(Home / /Mobile)</span>
            </div>
            <div class="input-col">
               <span class="user-input">{{riderPhoneNumber}}</span>
            </div>
         </div>
         <div class="row">
            <div class="title-col">
               <span>Email address</span>
            </div>
            <div class="input-col">
               <span class="user-input">{{riderEmailAddress}}</span>
            </div>
            <div class="title-col">
               <span>Type</span>
            </div>
            <div class="input-col">
               <div class="check-virtical-title">
                  <span>Walker </span>
                  <br />
               <img class="checkbox-image" v-if="riderTypeWalker" src="@/assets/images/checkmark.png" />
               <img class="checkbox-image" v-if="!riderTypeWalker" src="@/assets/images/uncheckmark.png" />
               </div>
               <div class="check-virtical-title">
                  <span>Bicycle </span><br />
               <img class="checkbox-image" v-if="riderTypeBicycle" src="@/assets/images/checkmark.png" />
               <img class="checkbox-image" v-if="!riderTypeBicycle" src="@/assets/images/uncheckmark.png" />
               </div>
               <div class="check-virtical-title">
                  <span>Scooter</span><br />
               <img class="checkbox-image" v-if="riderTypeScooter" src="@/assets/images/checkmark.png" />
               <img class="checkbox-image" v-if="!riderTypeScooter" src="@/assets/images/uncheckmark.png" />
               </div>
               <div class="check-virtical-title">
                  <span>Car</span><br />
               <img class="checkbox-image" v-if="riderTypeCar" src="@/assets/images/checkmark.png" />
               <img class="checkbox-image" v-if="!riderTypeCar" src="@/assets/images/uncheckmark.png" />
               </div>
               <div class="check-virtical-title">
                  <span>E-scooter</span><br />
               <img class="checkbox-image" v-if="riderTypeEScooter" src="@/assets/images/checkmark.png" />
               <img class="checkbox-image" v-if="!riderTypeEScooter" src="@/assets/images/uncheckmark.png" />
               </div>
            </div>
         </div>
         <div class="item-title">
            <span  >3. Details of Substituted Rider (if applicable)</span>
         </div>
         <div class="row">
            <div class="title-col">
               <span>Rider ID Of Account Holder</span>
            </div>
            <div class="input-col">
               <span class="user-input">{{subRiderIdNumber}}</span>
            </div>
            <div class="title-col">
               <span>Name</span>
            </div>
            <div class="input-col">
               <span class="user-input">{{subRiderName}}</span>
            </div>
         </div>
         <div class="row">
            <div class="title-col">
               <span>Address</span>
            </div>
            <div class="input-col">
               <span class="user-input">{{subRiderAddress}} </span>
            </div>
            <div class="title-col">
               <span>Phone Number  <br />(Home/ / Mobile)</span>
            </div>
            <div class="input-col">
               <span class="user-input">{{subRiderPhoneNumber}}</span>
            </div>
         </div>
         <div class="row">
            <div class="title-col">
               <span>Email address</span>
            </div>
            <div class="input-col">
               <span class="user-input">{{subRiderEmailAddress}}</span>
            </div>
            <div class="title-col">
               <span>Type</span>
            </div>
            <div class="input-col">
               <div class="check-virtical-title">
                  <span>Walker </span>
                  <br />
               <img class="checkbox-image" v-if="subRiderTypeWalker" src="@/assets/images/checkmark.png" />
               <img class="checkbox-image" v-if="!subRiderTypeWalker" src="@/assets/images/uncheckmark.png" />
               </div>
               <div class="check-virtical-title">
                  <span>Bicycle </span><br />
               <img class="checkbox-image" v-if="subRiderTypeBicycle" src="@/assets/images/checkmark.png" />
               <img class="checkbox-image" v-if="!subRiderTypeBicycle" src="@/assets/images/uncheckmark.png" />
               </div>
               <div class="check-virtical-title">
                  <span>Scooter</span><br />
               <img class="checkbox-image" v-if="subRiderTypeScooter" src="@/assets/images/checkmark.png" />
               <img class="checkbox-image" v-if="!subRiderTypeScooter" src="@/assets/images/uncheckmark.png" />
               </div>
               <div class="check-virtical-title">
                  <span>Car</span><br />
               <img class="checkbox-image" v-if="subRiderTypeCar" src="@/assets/images/checkmark.png" />
               <img class="checkbox-image" v-if="!subRiderTypeCar" src="@/assets/images/uncheckmark.png" />
               </div>
               <div class="check-virtical-title">
                  <span>E-scooter</span><br />
               <img class="checkbox-image" v-if="subRiderTypeEScooter" src="@/assets/images/checkmark.png" />
               <img class="checkbox-image" v-if="!subRiderTypeEScooter" src="@/assets/images/uncheckmark.png" />
               </div>
            </div>
         </div>
         <div class="item-title">
            <span  >4. Details of incident space (mandatory)</span>
         </div>
         <div class="row">
            <div class="title-col">
               <span>Date</span>
            </div>
            <div class="input-col">
               <span class="user-input">{{incidentDate}}</span>
            </div>
            <div class="title-col">
               <span>Time</span>
            </div>
            <div class="input-col">
               <span class="user-input">{{incidentTime}}</span>
            </div>
         </div>
         <div class="row">
            <div class="title-col">
               <span>Location</span>
            </div>
            <div class="input-col">
               <span class="user-input">{{incidentLocation}}</span>
            </div>
         </div>
         <div>
            <span>Description in detail</span>
         </div>
         <div>
            <span class="desc">{{incidentDetail}}</span>
         </div>
         <!-- <div>
            <span class="desc">{{incidentDetail2}}</span>
         </div>
         <div>
            <span class="desc">{{incidentDetail3}}</span>
         </div>
         <div>
            <span class="desc">{{incidentDetail4}}</span>
         </div>
         <div>
            <span class="desc">{{incidentDetail5}}</span>
         </div>
         <div>
            <span class="desc">{{incidentDetail6}}</span>
         </div>
         <div>
            <span class="desc">{{incidentDetail7}}</span>
         </div>
         <div>
            <span class="desc">{{incidentDetail8}}</span>
         </div> -->
      </div>
      <!-- end 1st page-->
      <!-- 2nd page-->
      <div class="page">
         <div class="item-title">
            <span >5. Details of Third Party (if relevant)</span>
         </div>
         <div class="row">
            <div class="title-col">
               <span>Name</span>
            </div>
            <div class="input-col">
               <span class="user-input">{{thirdPartyName}} </span>
            </div>
            <div class="title-col">
               <span>Address</span>
            </div>
            <div class="input-col">
               <span class="user-input">{{thirdPartyAddress}} </span>
            </div>
         </div>
         <div class="row">
            <div class="title-col">
               <span>Phone Number</span>
            </div>
            <div class="input-col">
               <span class="user-input">{{thirdPartyPhoneNumber}} </span>
            </div>
            <div class="title-col">
               <span>Email Address</span>
            </div>
            <div class="input-col">
               <span class="user-input">{{thirdPartyEmailAddress}} </span>
            </div>
         </div>
         <div class="item-title">
            <span  >6. Details of Witness (if any) </span>
         </div>
         <div class="row">
            <div class="title-col">
               <span>Name</span>
            </div>
            <div class="input-col">
               <span class="user-input">{{witnessName}} </span>
            </div>
            <div class="title-col">
               <span>Address</span>
            </div>
            <div class="input-col">
               <span class="user-input">{{witnessAddress}} </span>
            </div>
         </div>
         <div class="row">
            <div class="title-col">
               <span>Phone Number</span>
            </div>
            <div class="input-col">
               <span class="user-input">{{witnessPhoneNumber}} </span>
            </div>
            <div class="title-col">
               <span>Email Address</span>
            </div>
            <div class="input-col">
               <span class="user-input">{{witnessEmailAddress}} </span>
            </div>
         </div>
         <div>
            <span>Witness Statement (Yes* / No) - Please attach in email, if yes</span>
         </div>
         <div class="item-title">
            <span  >7. Photos taken at scene - (Yes*/ No) Please attach in email, if yes</span>
         </div>
         <div class="item-title">
            <span  style="margin-right:0px">8.Police Report Number </span>
            <span style="color:red;margin-right:0px" >*</span>
            <span style="margin-right:0px">(mandatory)</span>
         </div>
         <div>
            <span class="red">*Please attach Police Statement in email, if any</span>
         </div>
         <div class="item-title">
            <span  >9. Details of Loss</span>
         </div>
         <div class="item-details-of-loss">
            <span>(i)</span><span>Rider- Bodily Injury (Please provide full details of injuries that you have sustained)</span>
         </div>
         <div class="item-details-of-loss">
            <span>(ii)</span> <span>Third Party(Please provide full details of any loss or damage to property belongingto third parties)</span>
         </div>
         <div class="item-details-of-loss">
            <span>(iii)</span> <span>Third Party - Bodily Injury (Please provide as much information as possible about any injuries to Third Parties)</span>
         </div>
         <div class="item-title">
            <span >Declaration</span>
         </div>
         <div>
            <span class="declaration">
            I / We declare that the particulars provided in response to the questions contained in this Incident Report Form are true and correct, and I / We have not suppressed, misrepresented or misstated any relevant fact.
            </span>
         </div>
         <div>
            <span class="declaration">
            I / We consent to your use of any personal information included in this Incident Report Form in accordance with your Privacy Policy, which is accessible in the policy document.. I / We understand that failing to provide such personal information may prevent you from assisting with an insurance claim.
            </span>
         </div>
         <div>
            <span>I/We accept</span>
               <img class="checkbox-image" v-if="accept" src="@/assets/images/checkmark.png" />
               <img class="checkbox-image" v-if="!accept" src="@/assets/images/uncheckmark.png" />
         </div>
         <div class="row">
            <div class="title-input-col">
               <span>Full Name  Signature</span>
            </div>
            <div class="title-input-col">
               <span>Date</span>
            </div>
         </div>
         <div class="row">
            <div class="title-input-col">
               <span  class="user-input">{{fullNameSignature}}</span>
            </div>
            <div class="title-input-col">
               <span  class="user-input">{{submitDate}}</span>
            </div>
         </div>
      </div>
      <!-- end 2nd page-->
   </div>
   </div>
   <!-- end html for pdf-->
     <div class="button-area">
      <input type="button" value="Submit"  @click="generatePdf"/>
   </div>
</div>
</template>
<script>

export default {
  name: 'ClaimsForm',
  data () {
    return {
      status: 'ready',
      show: false,
      publicLiability: true,
      personalAccident: false,
      riderIdNumber: '최용운',
      riderName: 'Kim samantha',
      riderAddress: '111 Chatham Road South, Tsimshatsui, Kowloon, Hong Kong  ',
      riderPhoneNumber: ' +82-10-8868-8522',
      riderEmailAddress: 'admin@marsh.com',
      riderType: '',
      riderTypeWalker: true,
      riderTypeBicycle: false,
      riderTypeScooter: false,
      riderTypeCar: false,
      riderTypeEScooter: false,
      subRiderIdNumber: '최용운',
      subRiderName: 'Park James',
      subRiderAddress: '132 Chatham Road South, Tsimshatsui, Kowloon, Hong Kong  ',
      subRiderPhoneNumber: ' +82-10-5207-4633',
      subRiderEmailAddress: 'subAdmin@marsh.com',
      subRiderType: '',
      subRiderTypeWalker: true,
      subRiderTypeBicycle: false,
      subRiderTypeScooter: false,
      subRiderTypeCar: false,
      subRiderTypeEScooter: false,
      incidentDate: '2018-08-12',
      incidentTime: '18:00',
      incidentLocation: 'Airport',
      incidentDetail: 'I was hit by car while crossing the street',
      // incidentDetail1: 'I was hit by car while crossing the street',
      // incidentDetail2: 'I',
      // incidentDetail3: 'I was',
      // incidentDetail4: 'I was hit',
      // incidentDetail5: 'I was hit by car',
      // incidentDetail6: 'I was hit by car while',
      // incidentDetail7: 'I was hit by car while crossing',
      // incidentDetail8: 'I was hit by car while crossing the street',
      thirdPartyName: 'Shin Yuliana',
      thirdPartyAddress: '28 Chatham Road South, Tsimshatsui, Kowloon, Hong Kong  ',
      thirdPartyPhoneNumber: ' +82-10-5207-4633',
      thirdPartyEmailAddress: 'yuliana.shin@marsh.com',
      witnessName: 'Lee won',
      witnessAddress: '28 Chatham Road South, Tsimshatsui, Kowloon, Hong Kong  ',
      witnessPhoneNumber: ' +82-10-8868-1111',
      witnessEmailAddress: 'witness@marsh.com',
      accept: false,
      fullNameSignature: '',
      submitDate: '',
      form: {
        email: '',
        name: '',
        checked: []
      },
      showB: true,
      typeOfIncident: '',
      substitutedRider: '',
      thirdParty: '',
      witness: '',
      witnessStatement: '',
      attachForWitness: [],
      photosTaken: '',
      attachTakenPhotos: [],
      isPoliceReportNumber: '',
      policeReportNumber: '',
      attachPoliceStatement: [],
      detailsOfLoss: 'I was hit by car while crossing the street',
      getDate: '2018-09-07',
      incidentTypes: [
        { text: 'Public Liability', value: 'Public Liability' },
        { text: 'Personal Accident and Income Protection', value: 'Personal Accident and Income Protection' }
      ],
      types: [
        { text: 'Walker', value: 'Walker' },
        { text: 'Bicycle', value: 'Bicycle' },
        { text: 'Scooter', value: 'Scooter' },
        { text: 'Car', value: 'Car' },
        { text: 'E-scooter', value: 'E-scooter' }
      ],
      radioOptions: [
        { text: 'Yes', value: true },
        { text: 'No', value: false }
      ]
    }
  },

  methods: {
    onSubmit (evt) {
      evt.preventDefault()
      alert(JSON.stringify(this.form))
    },
    onReset (evt) {
      evt.preventDefault()
      /* Reset our form values */
      this.form.email = ''
      this.form.name = ''
      this.form.food = null
      this.form.checked = []
      /* Trick to reset/clear native browser form validation state */
      this.showB = false
      this.$nextTick(() => { this.showB = true })
    },
    
  }
}
</script>
<style>

</style>
