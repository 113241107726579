<template>
<keep-alive>
<component :is="dynamicComponent"></component>
</keep-alive>
</template>
<script>
import UnderconstructionSG from '@/components/sg/shared/underconstruction'
import UnderconstructionHK from '@/components/hk/shared/underconstruction'
export default{
  computed: {
    dynamicComponent () {
      // Hongkong
      if (this.$store.state.country === 'Hong Kong') {
        return UnderconstructionHK
      } else if (this.$store.state.country === 'Singapore') {
        return UnderconstructionSG
      }else{
        //default
        return UnderconstructionSG
      }
    }
  }
}
</script>
