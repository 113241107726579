import Vue from 'vue'
import VueRouter from 'vue-router'
import ClaimsProcess from '@/components/general/claims-process/claims-process.component.vue'
import ClaimsForm from '@/components/general/claims-process/claims-form.component.vue'
import PolicyCoverage from '@/components/general/policy-coverage/policy-coverage.component.vue'
import GroupPersonal from '@/components/general/policy-coverage/group-personal/group-personal.component.vue'
import Liability from '@/components/general/policy-coverage/liability/liability.component.vue'
import ContactUs from '@/components/general/contact-us/contact-us.component.vue'
import FAQ from '@/components/general/faq/faq.component.vue'
import UnderConstruction from '@/components/general/shared/underconstruction.vue'
//claim registration component below
import ClaimsConfirmation from '@/components/general/claims-registration/claims-confirmation.component.vue'
import ClaimsCoverage from '@/components/general/claims-registration/claims-coverage.component.vue'
import ClaimsDeclaration from '@/components/general/claims-registration/claims-declaration.component.vue'
import ClaimsDocumentUpload from '@/components/general/claims-registration/claims-documentupload.component.vue'
import ClaimsIncidentDetails from '@/components/general/claims-registration/claims-incidentdetails.component.vue'
import ClaimsNotification from '@/components/general/claims-registration/claims-notification.component.vue'
import ClaimsRegistration from '@/components/general/claims-registration/claims-registration.component.vue'
import ClaimsRider from '@/components/general/claims-registration/claims-rider.component.vue'
import ClaimsPublicLiability from '@/components/general/claims-registration/claims-public-liability'
import ClaimsAgreement from '@/components/general/claims-registration/claim-agreement'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    redirect: '/policy-coverage'
  },
  {
    path: '/policy-coverage',
    name: 'PolicyCoverage',
    component: PolicyCoverage
  },
  {
    path: '/contact-us',
    name: 'ContactUs',
    component: ContactUs
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: FAQ
  },
  {
    path: '/claims-process',
    name: 'ClaimsProcess',
    component: ClaimsProcess
  },
  {
    path: '/claims-form',
    name: 'ClaimsForm',
    component: ClaimsForm
  },
  {
    path: '/group-personal',
    name: 'GroupPersonal',
    component: GroupPersonal
  },
  {
    path: '/liability',
    name: 'Liability',
    component: Liability
  },
  {
    path: '/under-construction',
    name: 'UnderConstruction',
    component: UnderConstruction
  },
  {
    path: '/claims-confirmation',
    name: 'ClaimsConfirmation',
    component: ClaimsConfirmation
  },
  {
    path: '/claim-coverage',
    name: 'ClaimsCoverage',
    component: ClaimsCoverage
  },
  {
    path: '/claims-declaration',
    name: 'ClaimsDeclaration',
    component: ClaimsDeclaration
  },
  {
    path: '/claims-documentupload',
    name: 'ClaimsDocumentUpload',
    component: ClaimsDocumentUpload
  },
  {
    path: '/claims-incidentdetails',
    name: 'ClaimsIncidentDetails',
    component: ClaimsIncidentDetails
  },
  {
    path: '/claims-notification',
    name: 'ClaimsNotification',
    component: ClaimsNotification
  },
  {
    path: '/claims-registration',
    name: 'ClaimsRegistration',
    component: ClaimsRegistration
  },
  {
    path: '/claims-rider',
    name: 'ClaimsRider',
    component: ClaimsRider
  },
  {
    path: '/claims-public-liability',
    name: 'Claim_public_liability',
    component: ClaimsPublicLiability
  },
  {
    path : '/claims-agreement',
    name: 'ClaimsAgreement',
    component: ClaimsAgreement
  }
]
export default new VueRouter({
  routes: routes,
  mode: 'history'
})
