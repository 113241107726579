<template>
<keep-alive>
<component :is="dynamicComponent"></component>
</keep-alive>
</template>
<script>
import ClaimsDeclarationSG from '@/components/sg/claims-registration/claims-declaration.component'
import ClaimsDeclarationHK from '@/components/hk/claims-registration/claims-declaration.component'
export default{
  computed: {
    dynamicComponent () {
      // Hongkong
      // if (this.$store.state.country === 'Hong Kong') {
      //   return ClaimsDeclarationHK
      // } else {
        return ClaimsDeclarationSG
      // }
    }
  }
}
</script>
