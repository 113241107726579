<template>
  <v-container class="ma-0 pa-0" fluid v-resize="onResize" style="background: #F2FBFC">
    <v-layout>
      <v-flex lg2 class="pa-0 ma-0" style="background: #F2FBFC"></v-flex>
      <v-flex xs12 lg8 class="pa-0 ma-0">

        <v-card class=" pt-12 pb-4" elevation="0"
                :tile=true style="background: #F2FBFC">
          <v-row>
            <v-col cols="12">
              <v-row
              >
                <v-img :src="require('@/assets/icons/icon_Image_15.png')" height="70" contain></v-img>
              </v-row>
              <v-row class="justify-center">
                <v-card-subtitle class="pb-0 font-weight-bold text-h5 text-xs-center" style="color: #007E8A">Claim Submitted
                </v-card-subtitle>
              </v-row>
              <v-row class="justify-center">
                <v-card-subtitle class="py-2 black--text font-weight-bold" style="font-size: 20px">You have submitted
                  your
                  claim
                </v-card-subtitle>
              </v-row>
              <v-row v-if="$vuetify.breakpoint.mdAndDown" class="justify-center">

                <v-card-subtitle>
                  <span style="color: #37424A">Your claim ID is : </span>
                </v-card-subtitle>
              </v-row>
              <v-row v-if="$vuetify.breakpoint.mdAndDown" class="justify-center">
                <v-card-subtitle class="content-item mx-4 text-h6"
                                 style="color: #00CCBC;width: 90%; text-align: center;font-size: 18px">
                  {{ this.$store.getters.getSubmissionId }}
                </v-card-subtitle>
              </v-row>
              <v-row v-if="$vuetify.breakpoint.lgAndUp" class="justify-center pt-4" style="text-align: center">
                <v-card-subtitle class="content-item mx-4 "
                                 style="color: #00CCBC; width: 300px; font-size: 20px;font-weight: bold; border-radius: 3%;">
                  <span style="color: #37424A;font-weight: normal">Your claim ID is : </span>
                  {{ this.$store.getters.getSubmissionId }}
                </v-card-subtitle>

              </v-row>
              <v-row  class="justify-center justify-lg-start">
                <v-card-subtitle class=" justify-center pt-10 pb-0 text-body-2 text-lg-body-1 text-center text-lg-left"
                                 :style="$vuetify.breakpoint.lgAndUp?'width: 100%;':'width: 90%;'"
                                 style="color: #333333; ">We have received your claim and will begin processing the information you have submitted.  If we need more information from you, a claim representative will contact you.  Further documents can be emailed to
                  {{ getEmailAddressByCountry }}.  (maximum file size should not exceed 5MB).  Please quote your claim ID number.  Alternatively, we will notify you once there is an update on your claim. </v-card-subtitle>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="ma-0 pa-4 content-item" elevation="0"
                justify="start">
          <div v-if="$vuetify.breakpoint.mdAndDown">
            <v-row style="font-size: 15px; color: #37424A">
              <v-col>
                <ul v-if="(IsAMERCoverageType || IsPersonalAccidentCoverageType)">
                  <li>
                    <span style="display: inline-block; width: 45%">Rider Name</span>
                    <span class="li-content-item">
                      {{this.$store.state.riderdetails.firstname}} {{this.$store.state.riderdetails.middlename}} {{this.$store.state.riderdetails.lastname}}
                    </span>
                  </li>
                  <li>
                    <span style="display: inline-block; width: 45%">Rider ID#</span>
                    <span class="li-content-item">{{ this.$store.state.riderdetails.deliverooid }}</span>
                  </li>
                  <li>
                    <span style="display: inline-block; width: 45%">Rider Email</span>
                    <span class="li-content-item">{{ this.$store.state.riderdetails.email }}</span>
                  </li>
                  <li>
                    <span style="display: inline-block; width: 45%">Rider Contact Number</span>
                    <span class="li-content-item">{{this.$store.state.riderdetails.contactnumber}}</span>
                  </li>
                  <li>
                    <span style="display: inline-block; width: 45%">Incident Date</span>
                    <span class="li-content-item" >{{ this.$store.state.incidentdetails.incidentdate | moment }}</span>
                  </li>

                  <li>
                    <span style="display: inline-block; width: 45%">Incident Location</span>
                    <span class="li-content-item">{{this.$store.state.incidentdetails.incidentlocation}}</span>
                  </li>
                </ul>
                <ul v-if="(IsPersonalDisabilityCoverageType || IsAccidentalDeathCoverageType)">
                  <li>
                    <span style="display: inline-block; width: 45%">Reporter Name</span>
                    <span class="li-content-item">{{this.$store.state.reporterdetails.firstname}} {{this.$store.state.reporterdetails.middlename}} {{this.$store.state.reporterdetails.lastname}}</span>
                  </li>
                  <li>
                    <span style="display: inline-block; width: 45%">Reporter Email</span>
                    <span class="li-content-item">{{this.$store.state.reporterdetails.email}}</span>
                  </li>
                  <li>
                    <span style="display: inline-block; width: 45%">Reported Contact Number</span>
                    <span class="li-content-item">{{this.$store.state.reporterdetails.contactnumber}}</span>
                  </li>
                  <li>
                    <span style="display: inline-block; width: 45%">Rider Name</span>
                    <span class="li-content-item">{{this.$store.state.riderdetails.firstname}} {{this.$store.state.riderdetails.middlename}} {{this.$store.state.riderdetails.lastname}}</span>

                  </li>


                  <li>
                    <span style="display: inline-block; width: 45%">Rider ID#</span>
                    <span class="li-content-item" >{{this.$store.state.riderdetails.deliverooid}}</span>
                  </li>
                  <li>
                    <span style="display: inline-block; width: 45%">Rider Email</span>
                    <span class="li-content-item" >{{this.$store.state.riderdetails.email}}</span>
                  </li>
                  <li>
                    <span style="display: inline-block; width: 45%">Rider Contact Number</span>
                    <span class="li-content-item">{{this.$store.state.riderdetails.contactnumber}}</span>
                  </li>
                  <li>
                    <span style="display: inline-block; width: 45%">Incident Date</span>
                    <span class="li-content-item" >{{ this.$store.state.incidentdetails.incidentdate | moment }}</span>
                  </li>

                  <li>
                    <span style="display: inline-block; width: 45%">Incident Location</span>
                    <span class="li-content-item" >{{this.$store.state.incidentdetails.incidentlocation}}</span>
                  </li>
                </ul>
              </v-col>
            </v-row>
            <v-row class="text-body-2 black--text ">
            </v-row>
            <v-row class="text-body-2 black--text px-2">
              <v-col cols="6" style="font-size: 16px; color: #37424A;font-weight: bold">
                If you have any questions please contact via email or phone:
              </v-col>
              <v-col cols="12" class="content-item px-0" style="border-radius: 5px; border: 2px solid #E1E1E1;">
                <v-card-subtitle style="font-size: 16px">
                  <img src='@/assets/icons/icon_Image_13.png'
                       class="mail-icon">
                  <span>{{ getEmailAddressByCountry }}</span>
                </v-card-subtitle>
                <v-divider/>
                <v-card-subtitle style="font-size: 16px">
                  <img src='@/assets/icons/icon_Image_14.png'
                       class="mail-icon">
<!--                  <span>{{ ('Hong Kong' === $store.state.country ? '(+852) 2301 7000' : '(+65) 2301 7000') }} </span>-->
                <span>{{ getPhoneByCountry }}</span>
                </v-card-subtitle>
              </v-col>
            </v-row>
          </div>
          <div v-if="$vuetify.breakpoint.lgAndUp">
            <v-row>
              <v-col cols="6" style="border-right: #F4F4F4 1px solid; ">
                <v-row v-if="(IsAMERCoverageType || IsPersonalAccidentCoverageType)"
                       no-gutters class="mx-11 px-0" justify="center" align="center">
                  <v-col cols="12" sm="6">
                    <v-row class="black--text" style="font-size: 17px">
                      <ul>
                        <li style="list-style-type: square;">Rider Name</li>
                      </ul>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="5" offset="1">
                    <v-row class="li-content-item" style="font-size: 17px">{{this.$store.state.riderdetails.firstname}} {{this.$store.state.riderdetails.middlename}} {{this.$store.state.riderdetails.lastname}}</v-row>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-row class="black--text" style="font-size: 17px">
                      <ul>
                        <li style="list-style-type: square;">Rider ID#</li>
                      </ul>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="5" offset="1">
                    <v-row class="li-content-item" style="font-size: 17px">{{this.$store.state.riderdetails.deliverooid}}</v-row>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-row class="black--text" style="font-size: 17px">
                      <ul>
                        <li style="list-style-type: square;">Rider Email</li>
                      </ul>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="5" offset="1">
                    <v-row class="li-content-item" style="font-size: 17px">{{this.$store.state.riderdetails.email}}</v-row>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-row class="black--text" style="font-size: 17px">
                      <ul>
                        <li style="list-style-type: square;">Rider Contact Number</li>
                      </ul>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="5" offset="1">
                    <v-row class="li-content-item" style="font-size: 17px">{{this.$store.state.riderdetails.contactnumber}}</v-row>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-row class="black--text" style="font-size: 17px">
                      <ul>
                        <li style="list-style-type: square;">Incident Date</li>
                      </ul>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="5" offset="1">
                    <v-row class="li-content-item" style="font-size: 17px">{{ this.$store.state.incidentdetails.incidentdate | moment }}</v-row>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-row class="black--text" style="font-size: 17px">
                      <ul>
                        <li style="list-style-type: square;">Incident Location</li>
                      </ul>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="5" offset="1">
                    <v-row class="li-content-item" style="font-size: 17px">{{this.$store.state.incidentdetails.incidentlocation}}</v-row>
                  </v-col>
                </v-row>
                <v-row v-if="(IsPersonalDisabilityCoverageType || IsAccidentalDeathCoverageType)"
                       no-gutters class="mx-11 px-0" justify="center" align="center">
                  <v-col cols="12" sm="6">
                    <v-row class="black--text" style="font-size: 17px">
                      <ul>
                        <li style="list-style-type: square;">Reporter Name</li>
                      </ul>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="5" offset="1">
                    <v-row class="li-content-item" style="font-size: 17px">{{this.$store.state.reporterdetails.firstname}} {{this.$store.state.reporterdetails.middlename}} {{this.$store.state.reporterdetails.lastname}}</v-row>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-row class="black--text" style="font-size: 17px">
                      <ul>
                        <li style="list-style-type: square;">Reporter Email</li>
                      </ul>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="5" offset="1">
                    <v-row class="li-content-item" style="font-size: 17px">{{this.$store.state.reporterdetails.email}}</v-row>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-row class="black--text" style="font-size: 17px">
                      <ul>
                        <li style="list-style-type: square;">Reporter Contact Number</li>
                      </ul>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="5" offset="1">
                    <v-row class="li-content-item" style="font-size: 17px">{{this.$store.state.reporterdetails.contactnumber}}</v-row>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-row class="black--text" style="font-size: 17px">
                      <ul>
                        <li style="list-style-type: square;">Rider Name</li>
                      </ul>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="5" offset="1">
                    <v-row class="li-content-item" style="font-size: 17px">{{this.$store.state.riderdetails.firstname}} {{this.$store.state.riderdetails.middlename}} {{this.$store.state.riderdetails.lastname}}</v-row>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-row class="black--text" style="font-size: 17px">
                      <ul>
                        <li style="list-style-type: square;">Rider ID#</li>
                      </ul>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="5" offset="1">
                    <v-row class="li-content-item" style="font-size: 17px">{{this.$store.state.riderdetails.deliverooid}}</v-row>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-row class="black--text" style="font-size: 17px">
                      <ul>
                        <li style="list-style-type: square;">Rider Email</li>
                      </ul>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="5" offset="1">
                    <v-row class="li-content-item" style="font-size: 17px">{{this.$store.state.riderdetails.email}}</v-row>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-row class="black--text" style="font-size: 17px">
                      <ul>
                        <li style="list-style-type: square;">Rider Contact Number</li>
                      </ul>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="5" offset="1">
                    <v-row class="li-content-item" style="font-size: 17px">{{this.$store.state.riderdetails.contactnumber}}</v-row>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-row class="black--text" style="font-size: 17px">
                      <ul>
                        <li style="list-style-type: square;">Incident Date</li>
                      </ul>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="5" offset="1">
                    <v-row class="li-content-item" style="font-size: 17px">{{ this.$store.state.incidentdetails.incidentdate | moment }}</v-row>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-row class="black--text" style="font-size: 17px">
                      <ul>
                        <li style="list-style-type: square;">Incident Location</li>
                      </ul>
                    </v-row>
                  </v-col>
                  <v-col cols="12" sm="5" offset="1">
                    <v-row class="li-content-item" style="font-size: 17px">{{this.$store.state.incidentdetails.incidentlocation}}</v-row>
                  </v-col>
                </v-row>

              </v-col>
              <v-col cols="6" class="px-16 ">
                <v-row class="text-body-2 black--text">
                  <v-col cols="6" style="font-size: 17px;color: #37424A">
                    If you have any questions please contact via email or phone:
                  </v-col>
                  <v-col cols="12" class="content-item ma-0 pa-0"
                         style="border-radius: 5px; border: 2px solid #E1E1E1;">
                    <v-card-subtitle class=" mx-2" style="font-size: 17px;color: #37424A">
                      <img src='@/assets/icons/icon_Image_13.png'
                           class="mail-icon">
                      <span>{{ getEmailAddressByCountry }}</span>
                    </v-card-subtitle>
                    <v-divider/>
                    <v-card-subtitle class="mx-2" style="font-size: 17px;color: #37424A">
                      <img src='@/assets/icons/icon_Image_14.png'
                           class="mail-icon">
<!--                      <span>{{ ('Hong Kong' === $store.state.country ? '(+852) 2301 7000' : '(+65) 2301 7000') }} </span>-->
                      <span>{{ getPhoneByCountry }}</span>
                    </v-card-subtitle>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>


          </div>
        </v-card>
        <v-btn
            x-large
            depressed
            block
            :tile="true"
            color="#00CCBC"
            class="ma-0 pa-0 white--text d-md-flex d-lg-none action-button"
            @click="goClaimsProcessPage"
        >Done
        </v-btn>
      </v-flex>
      <v-flex lg2 class="pa-0 ma-0" style="background: #F2FBFC"></v-flex>
    </v-layout>
    <v-layout class="d-none d-lg-flex my-4">
      <v-flex lg2></v-flex>
      <v-flex xs12 lg8>
        <v-row>

          <v-col offset="4" cols="4" class="px-12">

            <v-btn
                x-large
                depressed
                :tile="true"
                block
                color="#00CCBC"
                class="white--text action-button"

                @click="goClaimsProcessPage"
            >Done
            </v-btn>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

import axios from "axios";
import {mapGetters, mapMutations} from "vuex";
import moment from "moment";

export default {
  name: "agreement",
  data() {
    return {
      isAgreed: false,
    }
  },
  methods: {
    moment: function (e) {
      return moment();
    },
    initPageTitle: function () {
      this.$store.commit('changeMenuTitle', 'Make a Claim')
    },
    disableShowClaimProgressBar: function () {
      this.$store.commit('changeShowClaimProgressBarStatus', false)
    },
    onResize() {
      this.windowSize = {x: window.innerWidth, y: window.innerHeight}
    },
    goPrevious() {
      this.$router.back()
    },
    goClaimsProcessPage() {
      this.$router.push('/claims-process')
    }
  },
  mounted() {
    this.initPageTitle()
    this.disableShowClaimProgressBar()
    this.onResize()
  },

  computed: {
    getDeviceHeight() {
      return 1
    },
    IsAMERCoverageType() {
      return this.$store.state.incidenttype2 === "AMER";
    },
    IsPersonalDisabilityCoverageType() {
      return this.$store.state.incidenttype2 === "PD";
    },
    IsPersonalAccidentCoverageType() {
      return this.$store.state.incidenttype2 === "LDPA";
    },
    IsAccidentalDeathCoverageType() {
      return this.$store.state.incidenttype2 === "AD";
    },
    getEmailAddressByCountry() {
      let country = this.$store.state.country
      if (country ==='Hong Kong'){
        return 'deliverooclaimsint@uk.sedgwick.com'
      }else {
        return 'deliverooclaimsint@uk.sedgwick.com'
      }
    },
    getPhoneByCountry() {
      let country = this.$store.state.country
      if (country ==='Hong Kong'){
        return '(+852) 2529 0123'
      }else {
        return '(+65) 6223 2008'
      }
    },
    ...mapGetters(['getSubmissionId'])
  },
  filters: {
    moment: function (date) {
      var result = moment(date).format('DD/MM/YY')
      if (result === 'Invalid date') {
        return ''
      } else {
        return moment(date).format('DD/MM/YY')
      }
    },
  }
}
</script>

<style scoped>
.mail-icon {
  height: 16px;
  align-content: center;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.content-item {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #E1E1E1;
  opacity: 1;
}

ul {
  list-style-type: square;
}

.li-content-item {
  color: #00A8C8
}

.action-button {
  text-transform: none !important;
  font-size: 20px;
}
</style>
