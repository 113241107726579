<template>
<keep-alive>
<component :is="dynamicComponent"></component>
</keep-alive>
</template>
<script>
import FooterSG from '@/components/sg/footer/footer.component'
import FooterHK from '@/components/hk/footer/footer.component'
export default{
  computed: {
    dynamicComponent () {
      // Hongkong
      if (this.$store.state.country === 'Hong Kong') {
        return FooterHK
      } else {
        return FooterSG
      }
    }
  }
}
</script>
