import PremiumApi from "@/plugins/axios";
/**
 * @description Logger class
 * This is responsible for logging of all kind of stuff in the application
 * Default, we are using the console api for logging and this provides the basic level of logging such as
 * you can use the available method of console in developement but in production these will be replaced with empty methods
 * This can be extended with the help of adding Log level functionality
 */
class AppLogger {
    /**
     * @constructor AppLogger
     */
    constructor() {
        /** Initializing the configuration of logger */
        this.initLogger();
    }

    /**
     * @description Initializing the configuration such as if environment is production then all log method will be replaced with empty methods
     * except logToServer, which will be responsible for logging the important stuff on server
     */
    initLogger() {
        if (location.hostname === '127.0.0.1' || location.hostname==='localhost') {
            // To nothing.
        }
        else {
            this.logToServer = err => {
                /** temp added to print in the console during production */
                console.error(err.err.stack); //
                PremiumApi.postClaim(err.err.stack.toString(), function (data) {
                });
            };
        }

    }
}

/** Creating the instance of logger */
const logger = new AppLogger();

export {logger};
