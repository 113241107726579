<template>
  <header>
    <nav class="navbar">
      <div class="container">
        <v-toolbar flat class="topmenu">
          <v-toolbar-items class="text-right navbar-nav" id="countryPage">
            <v-btn text class="btn-home">
              <router-link
                tag="button"
                class="navbar-brand nav-home"
                :to="'/'"
                v-bind:class="[($route.path =='/')?'active':'']"
              >{{$t('lang.header.home')}}</router-link>
            </v-btn>
            <v-spacer></v-spacer>
            <v-menu open-on-hover dark offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn text v-bind="attrs" v-on="on" class="desktop-menu">
                  <router-link
                    :to="'/policy-coverage'"
                    class="dropdown-toggle navbar-brand"
                    data-toggle="dropdown"
                    v-bind:class="[($route.path =='/policy-coverage' || $route.path =='/group-personal' ||$route.path =='/liability' )?'active':'']"
                  >{{$t('lang.header.yourCover')}}</router-link>
                </v-btn>
              </template>

              <v-list class="your-cover-submenu" :width="350">
                <v-list-item>
                  <v-list-item-title>
                    <router-link
                      tag="span"
                      :to="'/group-personal'"
                      v-bind:class="[($route.path =='/group-personal')?'active':'']"
                    >
                      <a
                        class="menuBtn"
                        @click="closeMenu()"
                      >{{$t('lang.header.personalAccidentAndIncomeProtection')}}</a>
                    </router-link>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>
                    <router-link
                      tag="span"
                      :to="'/liability'"
                      v-bind:class="[($route.path =='/liability')?'active':'']"
                    >
                      <a class="menuBtn" @click="closeMenu()">{{$t('lang.header.publicLiability')}}</a>
                    </router-link>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-btn text class="desktop-menu">
              <router-link
                tag="button"
                :to="'/claims-process'"
                class="navbar-brand"
                v-bind:class="[($route.path =='/claims-process')?'active':'']"
              >{{$t('lang.header.reportAnIncident')}}</router-link>
            </v-btn>

            <v-btn text class="desktop-menu">
              <router-link
                tag="button"
                :to="'/faq'"
                class="navbar-brand"
                v-bind:class="[($route.path =='/faq')?'active':'']"
              >{{$t('lang.header.faqs')}}</router-link>
            </v-btn>

            <v-btn text class="desktop-menu">
              <router-link
                tag="button"
                :to="'/contact-us'"
                class="navbar-brand"
                v-bind:class="[($route.path =='/contact-us')?'active':'']"
              >{{$t('lang.header.contactUs')}}</router-link>
            </v-btn>

            <v-menu open-on-hover   offset-y  class="my-body" >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  v-bind="attrs"
                  v-on="on"
                  class="navbar-brand selected-country desktop-menu"
                >
                  <span :class="$store.state.country"></span>
                  <span v-t="getCountry"></span>
                </v-btn>
              </template>

              <v-list class="dropdown-content" id="countryDropDown" flat>
                <v-list-item
                  v-for="country in countryList"
                  :key="country.countryName"
                  :class="country.countryName"
                  @click="countryChange(country.countryName)"
                >
                  <v-list-item-title>{{country.countryName}}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <language-selector
              class="language-selector-menu desktop-menu"
              v-bind:show-place-holder="false"
            ></language-selector>
          </v-toolbar-items>
          <v-toolbar-items class="ham">
            <v-app-bar-nav-icon @click="drawer=true"></v-app-bar-nav-icon>
          </v-toolbar-items>
        </v-toolbar>

        <v-navigation-drawer
          dark
          :right="true"
          :width="375"
          absolute
          v-model="drawer"
          class="mobile-menu"
          id="mobile-menu"
        >
          <template v-slot:prepend>
            <v-list-item>
              <v-list-item-content>
                <span @click="drawer=false" class="closebtn">×</span>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <router-link :to="'/'" class="main-option">
                    <span class="uppercase">{{$t('lang.header.home')}}</span>
                  </router-link>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <router-link :to="'/policy-coverage'" class="main-option">
                    <span>{{$t('lang.header.yourCover')}}</span>
                  </router-link>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <router-link :to="'/group-personal'" class="sub-option">
                    <span>{{$t('lang.header.personalAccidentAndIncomeProtection')}}</span>
                  </router-link>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <router-link :to="'/liability'" class="sub-option">
                    <span>{{$t('lang.header.publicLiability')}}</span>
                  </router-link>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <router-link :to="'/claims-process'" class="main-option">
                    <span class="uppercase">{{$t('lang.header.reportAnIncident')}}</span>
                  </router-link>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <router-link :to="'/faq'" class="main-option">
                    <span>{{$t('lang.header.faqs')}}</span>
                  </router-link>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <router-link :to="'/contact-us'" class="main-option">
                    <span>{{$t('lang.header.contactUs')}}</span>
                  </router-link>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <v-combobox
                    v-model="$store.state.country"
                    :items="$store.state.countryList"
                    label
                    outlined
                    item-text="countryName"
                    item-value="countryCode"
                    dense
                    @change="countryChange($store.state.country.countryName)"
                  ></v-combobox>
                  <language-selector
                    class="language-selector-menu"
                    v-bind:show-place-holder="false"
                  ></language-selector>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </div>
    </nav>
  </header>
</template>
<script>
import languageSelector from "@/common/language.selector";

export default {
  components: {
    "language-selector": languageSelector
  },
  data: function() {
    return {
      drawer: false
    };
  },
  methods: {
    openNav() {
      document.getElementById("mobile-menu").classList.add("side-nav");
      document.querySelector("body").classList.add("noscroll");
    },
    closeNav() {
      document.getElementById("mobile-menu").classList.remove("side-nav");
      document.querySelector("body").classList.remove("noscroll");
    },
    closeMenu() {
      // document.getElementById("dropdown-menu").style.display = "none !important";
    },
    langChange(languageCode) {
      document.getElementById("mobile-menu").classList.remove("side-nav");
      this.$store.dispatch("updateLanguageCode", languageCode);
    },
    countryChange(countryName) {
      //document.getElementById('mobile-menu').classList.remove('side-nav')
      // document.getElementById('mCountryDropDown').style.display = 'none !important'
      this.$store.dispatch("updateCountry", countryName);

      if (countryName !== "") {
        var langList = this.$store.state.countryList.filter(function(item) {
          return item.countryName === countryName;
        })[0].languageList;
        var defaultLang = langList.filter(function(item) {
          return item.default === true;
        })[0].languageCode;
        this.$store.dispatch("updateLanguageCode", defaultLang);
      }
    }
  },
  computed: {
    langCodes() {
      var val = this.$store.state.country;
      if (val !== "") {
        return this.$store.state.countryList.filter(function(item) {
          return item.countryName === val;
        })[0].languageList;
      }
      return "EN-us";
    },
    showLangSel() {
      var val = this.$store.state.country;
      if (val === "") {
        return false;
      } else {
        if (this.langCodes.length > 1) {
          return true;
        } else {
          return false;
        }
      }
    },
    countryList() {
      return this.$store.state.countryList.filter(
        x => x.countryName !== this.$store.state.country
      );
    },

    getCountry() {
      var country = this.$store.state.country;

      switch (country) {
        case "Hong Kong":
          return "lang.header.hongkong";
        // case 'Taiwan':
        //   return 'lang.header.taiwan'
        default:
          return "lang.header.singapore";
      }
    }
  }
};
</script>
<style>
</style>
