<template>
  <v-container class="ma-0 pa-0" fluid v-resize="onResize" style="background: #F4F4F4">
    <v-layout>
      <v-flex lg2></v-flex>
      <v-flex xs12 lg8 class=" pa-0">

        <v-card class="ma-xs-0 ma-lg-8 pa-12 content-item" elevation="0"
                :tile=true align="center">

            <v-img :src="require('@/assets/icons/icon_Image_06.png')" height="70" contain></v-img>
            <v-card-subtitle class="py-8 font-weight-bold text-xs-center black--text" style="font-size: 27px">Public Liability
            </v-card-subtitle>
            <v-card-subtitle  style="width: 330px;font-size: 20px;color: #37424A">For public liability claims please download the PDF form
              and send to:
            </v-card-subtitle>
            <v-card-subtitle style="margin-bottom: 100px">
              <img src='@/assets/icons/icon_Image_08.png'
                   class="mail-icon">
              <span style="color: #0FB694; font-size: 18px">{{ getEmailAddressByCountry }}</span>
            </v-card-subtitle>
            <v-card-subtitle class="py-8 text-xs-center red--text" style="font-size: 20px;text-align:left">
              {{ openBannerMsg }}
            </v-card-subtitle>
        </v-card>
        
        <v-btn
            x-large
            depressed
            block
            :tile="true"
               color="#00CCBC"
               class="ma-0 pa-0 white--text d-md-flex d-lg-none"
               style="text-transform: none !important;" @click="downloadPDF"
        >Download PDF
        </v-btn>
      </v-flex>

    </v-layout>
    <v-layout class="d-none d-lg-flex mb-8">
      <v-flex lg2></v-flex>
      <v-flex xs12 lg8>
        <v-row>
          <v-col offset="3" cols="3" class=" pr-2">
            <v-btn
                x-large
                depressed
                :tile=true
                block
                color="#93A1B4"
                class="white--text"
                style="text-transform: none !important; font-size: 20px"
                @click="goPrevious()"
            >
              <v-icon>{{ 'mdi-chevron-left' }}</v-icon>
              Previous
            </v-btn>
          </v-col>
          <v-col cols="3" class="pl-2">

            <v-btn
                x-large
                depressed
                :tile="true"
                block
                color="#00CCBC"
                class="white--text"
                style="text-transform: none !important; font-size: 20px"
                @click="downloadPDF"
            >Download PDF
            </v-btn>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

import axios from "axios";

export default {
  name: "agreement",
  data() {
    return {
      isAgreed: false,
    }
  },
  methods: {
    initPageTitle: function () {
      this.$store.commit('changeMenuTitle', 'Make a Claim')
    },
    disableShowClaimProgressBar: function () {
      this.$store.commit('changeShowClaimProgressBarStatus', false)
    },
    downloadPDF() {
      let country = this.$store.state.country
      let language = this.$store.state.selectedLanguageCode
      // set url
      let fileName
      let url
      switch (country) {
        case 'Hong Kong':
          if (language === 'hk') {
            fileName = '戶戶送 – 意外報告表格'
            url = 'assets/pdf/HKIncidentReportForm_Chinese_2024.pdf'
          } else {
            fileName = 'Deliveroo Incident Notification Form_HongKong'
            // eslint-disable-next-line
            url = 'assets/pdf/HKIncidentReportForm_English_2024.pdf'
          }
          break
          // case 'Taiwan':
          //   fileName = '保障範圍匯總'
          //   url = './assets/pdf/TWRiderPolicyDocument.pdf'
          //   break
        default:
          // default SG
          fileName = 'Deliveroo Incident Notification Form_Singapore'
          // eslint-disable-next-line
          url = 'assets/pdf/SGIncidentReportForm_English_2024.pdf'
      }
      console.log(url)
      axios({
        baseURL: axios.defaults.baseURL.replace('api', ''),
        url: url.replace('/api', ''),
        method: 'GET',
        responseType: 'blob'
      }).then(response => {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          let theFile = new Blob([response.data], {type: 'application/octet-stream'})
          window.navigator.msSaveOrOpenBlob(theFile, fileName + '.pdf')
        } else {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName + '.pdf')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      })
    },
    onResize() {
      this.windowSize = {x: window.innerWidth, y: window.innerHeight}
    },
    goPrevious(){
      this.$router.back()
    }
  },
  mounted() {
    this.initPageTitle()
    this.disableShowClaimProgressBar()
    this.onResize()
  },

  computed: {
    getDeviceHeight() {
      return 1
    },
    getEmailAddressByCountry() {
      let country = this.$store.state.country
      if (country ==='Hong Kong'){
        return 'deliverooclaimsint@uk.sedgwick.com'
      }else {
        return 'deliverooclaimsint@uk.sedgwick.com'
      }
    },
    openBannerMsg () {
      let country = this.$store.state.country
      let language = this.$store.state.selectedLanguageCode
      switch (country) {
        case 'Hong Kong':

          if (language === 'hk') {
            // eslint-disable-next-line
            return  '由 2024 年 10 月 18 日起，代送專員將不再涵蓋於由戶戶送所提供的個人意外和公眾責任保險之保障範圍中。'
          } else {
            // eslint-disable-next-line
            return  'Please note that effective from 18 October 2024, substitute riders will no longer be covered under both Deliveroo\'\s Personal Accident and Public Liability Insurance.'
          }
        default:
          // eslint-disable-next-line
          return  'Please note that effective from 17 October 2024, substitute riders will no longer be covered under both Deliveroo\'\s Personal Accident and Public Liability Insurance.'
      }
  }
  }
}
</script>

<style scoped>
.mail-icon {
  height: 16px;
  align-content: center;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.content-item {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 2px 3px 3px #0000001A;
  border: 1px solid #E1E1E1;
  border-radius: 5px;
  opacity: 1;
}
</style>
