<template>
  <!--  <v-row align=center justify="center">-->
  <v-stepper
    v-model="$store.state.currentStep"
    class="ma-0 pa-0"
    style="background: #F2FBFC;box-shadow: none; !important;"
  >
    <v-stepper-header>
      <v-stepper-step
        :complete="($store.state.currentStep)> 1"
        :color=stepperColor
        step="1"
      ><span style="font-size: 15px">Incident Type</span></v-stepper-step>
      <v-divider/>
      <v-stepper-step
        :complete="$store.state.currentStep  > 2"
        step="2"
        :color=stepperColor
      ><span style="font-size: 15px">Rider Details</span></v-stepper-step>
      <v-divider/>
      <v-stepper-step
          :complete="$store.state.currentStep  > 3"
          step="3"
          :color=stepperColor
      ><span style="font-size: 15px">Incident Details</span></v-stepper-step>
      <v-divider/>
      <v-stepper-step
          :complete="$store.state.currentStep  > 4"
          step="4"
          :color=stepperColor
      ><span style="font-size: 15px">Supporting Documents & Claims Payment Transfer Details</span></v-stepper-step>
      <v-divider/>
      <v-stepper-step
          :complete="$store.state.currentStep  > 5"
          step="5"
          :color=stepperColor><span style="font-size: 15px">Confirmation & Submission</span></v-stepper-step>
<!--      <v-stepper-step step="3" style="padding: 5px 15px 5px 2px"></v-stepper-step>-->
<!--      <v-stepper-step step="4" style="padding: 5px 15px 5px 2px"></v-stepper-step>-->
<!--      <v-stepper-step step="5" style="padding: 5px 15px 5px 2px"></v-stepper-step>-->
    </v-stepper-header>
  </v-stepper>
  <!--  </v-row>-->
</template>

<script>
export default {
  name: "stepper_top",
  data: () => ({
    stepperColor: "#00A8C8"
  }),
};
</script>
<style scoped>
</style>
