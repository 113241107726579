// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
// import 'babel-polyfill'
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import jQuery from 'jquery'
import i18n from './i18n'
import vuetify from './plugins/vuetify';
import _ from 'lodash';
import PremiumApi from '@/plugins/axios'
import { logger } from './shared/app-logger.js';

Object.defineProperty(Vue.prototype, '$_', {value: _});
global.jQuery = jQuery
global.$ = jQuery
// let Bootstrap = require('bootstrap')
Vue.config.productionTip = false
Vue.config.errorHandler = (err, vm, info) => {
    logger.logToServer({ err, vm, info });
};

window.onerror = function(message, source, lineno, colno, error) {
    logger.logToServer({ message, source, lineno, colno, error });
};
/* eslint-disable no-new */
var vm = new Vue({
    el: '#app',
    router,
    store,
    i18n,
    components: {App},
    vuetify,
    template: '<App/>',
    watch: {
        '$route': function (to, from) {
            if (this.$store.state.country!==''&& this.$store.getters.ip!==''){
                let ip = this.$store.getters.ip.replace(/(\r\n\t|\n|\r\t)/gm,"");
                let sessionId = this.$store.getters.sessionId;
                let accesssLog = {
                    countrycode: this.$store.state.countryCode,
                    url: window.location.href.replace('#',''),
                    uniqueid_user:'',
                    uniqueid_session: sessionId,
                    ip: ip
                }
                PremiumApi.postAccessLog(accesssLog,
                    function (response) {
                    }
                );
            }

        }
    },
})


const lang = store.state.language
if (lang) {
    i18n.locale = lang
}

global.vm = vm
