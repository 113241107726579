<template>
  <v-row align="center" justify="center" style="background: #F2FBFC">
<!--    <v-col-->
<!--      class="px-6"-->
<!--      cols="8"-->
<!--      v-text="this.$store.state.sub_menu_title"-->
<!--      style="color:#00A8C8;font-size: 1.1em"-->
<!--    ></v-col>-->
    <v-col class="col-sm-8 col-xs-12 pa-0">
      <stepper_top />
    </v-col>
  </v-row>
</template>

<script>
import stepper_top from "@/components/common/stepper_top";

export default {
  name: "topProgress",
  components: { stepper_top }
};
</script>

<style scoped>
</style>
