<template>

  <v-container id="document-upload" style="background: #ffffff" fluid justify-center class="ma-0 pa-0">

    <v-layout>
      <v-flex sm2></v-flex>
      <v-flex xs12 sm8>
        <v-card
            :tile=true
            class="elevation-0 section-title">
          <v-col class="d-flex justify-space-between align-center pa-0 pr-2">
            <v-toolbar-title class="pa-4 font-weight-bold d-inline-flex justify-space-between text-h6"
                             style="color: #006D9E">1. Incident Type
            </v-toolbar-title>
          </v-col>
        </v-card>
        <v-card
            :tile=true
            class="elevation-0 section-content">
          <v-card-title class="pa-4 d-inline-flex justify-space-between text-body-1">{{
              getCoverageText
            }}
          </v-card-title>
        </v-card>
        <v-card :tile="true" class="elevation-0 d-sm-none mobile-divider">

        </v-card>
        <v-card
            :tile=true
            class="elevation-0 section-title">
          <v-col class="d-flex justify-space-between align-center pa-0 pr-2">
            <v-toolbar-title class="pa-4 font-weight-bold d-inline-flex justify-space-between text-h6"
                             style="color: #006D9E">2. Rider Details
            </v-toolbar-title>
            <v-btn
                icon
                @click="modify('/claims-rider?modify=true')"
                style="right: auto">
              <v-icon color="secondColor">{{ 'edit' }}</v-icon>
            </v-btn>
          </v-col>
        </v-card>
        <div id="reporterDetails"
             v-if="this.reporterRelationship === 'SPO' || this.reporterRelationship === 'PAR' || this.reporterRelationship === 'CHI' ">
          <v-card
              :tile="true"
              class="elevation-0 ">
            <v-col class="d-flex justify-space-between align-center pa-0 pr-2">
              <v-toolbar-title class="pa-4 font-weight-bold d-inline-flex justify-space-between">Reporter Details
              </v-toolbar-title>
              <v-btn
                  icon
                  @click="show_reporter_details = !show_reporter_details"
                  style="right: auto">
                <v-icon>{{ show_reporter_details ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-col>
          </v-card>
          <div>
            <v-card
                :tile="true"
                class="elevation-0 expanded-area-border">
              <v-expand-transition name="support">
                <div v-if="show_reporter_details">
                  <v-layout row wrap justify-left px-1>
                    <v-flex xs12 sm6 md4 px-3>
                      <v-card-title class="text-body-1">
                        Title
                      </v-card-title>
                      <v-card-text class="text-body-1 section-content">
                        {{
                          this.$store.getters.titleOptions.filter(o => o.code === reporterInfo.title).map(o => o.value).toString()
                        }}
                      </v-card-text>
                    </v-flex>
                    <v-flex xs12 sm6 md4 px-3>
                      <v-card-title class="text-body-1">
                        Email
                      </v-card-title>
                      <v-card-text class="text-body-1 section-content">
                        {{ reporterInfo.email }}
                      </v-card-text>
                    </v-flex>

                    <v-flex xs12 sm6 md4 px-3>
                      <v-card-title class="text-body-1">
                        Contact Number
                      </v-card-title>
                      <v-card-text class="text-body-1 section-content">
                        {{ getPrefixPhoneNumber }} {{ reporterInfo.contactnumber }}
                      </v-card-text>
                    </v-flex>


                    <v-flex xs12 sm6 md4 px-3>
                      <v-card-title class="text-body-1">
                        First name
                      </v-card-title>
                      <v-card-text class="text-body-1 section-content">
                        {{ reporterInfo.firstname }}
                      </v-card-text>
                    </v-flex>
                    <v-flex xs12 sm6 md4 px-3>
                      <v-card-title class="text-body-1">
                        Middle name
                      </v-card-title>
                      <v-card-text class="text-body-1 section-content">
                        {{ reporterInfo.middlename }}
                      </v-card-text>
                    </v-flex>
                    <v-flex xs12 sm6 md4 px-3>
                      <v-card-title class="text-body-1">
                        Last name
                      </v-card-title>
                      <v-card-text class="text-body-1 section-content">
                        {{ reporterInfo.lastname }}
                      </v-card-text>
                    </v-flex>

                    <v-flex xs12 sm6 md4 px-3>
                      <v-card-title class="text-body-1">
                        {{ getTitleForID }}
                      </v-card-title>
                      <v-card-text class="text-body-1 section-content">
                        {{ this.getReporterIDNumber }}
                      </v-card-text>
                    </v-flex>
                    <v-flex xs12 sm6 md4 px-3>
                      <v-card-title class="text-body-1">
                        Gender
                      </v-card-title>
                      <v-card-text class="text-body-1 section-content">
                        {{
                          this.$store.getters.genderOptions.filter(o => o.code === reporterInfo.gender).map(o => o.value).toString()
                        }}
                      </v-card-text>
                    </v-flex>
                    <v-flex xs12 sm6 md4 px-3>
                      <v-card-title class="text-body-1">
                        Date of Birth(DD/MM/YY)
                      </v-card-title>
                      <v-card-text class="text-body-1 section-content">
                        {{ reporterInfo.dateofbirth | moment }}
                      </v-card-text>
                    </v-flex>


                    <v-flex xs12 sm6 md4 px-3>
                      <v-card-title class="text-body-1">
                        Country
                      </v-card-title>
                      <v-card-text class="text-body-1 section-content">
                        {{ this.$store.state.country }}
                      </v-card-text>
                    </v-flex>
                    <v-flex xs12 sm6 md4 px-3>
                      <v-card-title class="text-body-1">
                        District
                      </v-card-title>
                      <v-card-text class="text-body-1 section-content">
                        {{ reporterInfo.district }}
                      </v-card-text>
                    </v-flex>
                    <v-flex xs12 sm6 md4 px-3>
                      <v-card-title class="text-body-1">
                        Address
                      </v-card-title>
                      <v-card-text class="text-body-1 section-content">
                        {{ reporterInfo.address1 }}<br/>
                        {{ reporterInfo.address2 }}<br/>
                        {{ reporterInfo.address3 }}<br/>
                        {{ reporterInfo.address4 }}<br/>
                      </v-card-text>
                    </v-flex>
<!--                    <v-flex xs12 sm6 md4 px-3>-->
<!--                      &lt;!&ndash;                            v-if="reporterInfo.preferedcontact!==''"&ndash;&gt;-->
<!--                      <v-card-title class="text-body-1">-->
<!--                        Preferred method contact-->
<!--                      </v-card-title>-->
<!--                      <v-card-text class="text-body-1 section-content">-->
<!--                        {{-->
<!--                          this.$store.getters.prefferedContactOptions.filter(o => o.code === reporterInfo.preferedcontact).map(o => o.value).toString()-->
<!--                        }}-->
<!--                      </v-card-text>-->
<!--                    </v-flex>-->
                  </v-layout>

                </div>
              </v-expand-transition>
            </v-card>
          </div>
        </div>
        <div id="riderDetails">
          <v-card
              :tile="true"
              class="elevation-0 ">
            <v-col class="d-flex justify-space-between align-center pa-0 pr-2">
              <v-toolbar-title class="pa-4 font-weight-bold d-inline-flex justify-space-between">Rider Details
              </v-toolbar-title>
              <v-btn

                  icon
                  @click="show_rider_details = !show_rider_details"
                  style="right: auto">
                <v-icon>{{ show_rider_details ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-col>
          </v-card>
          <div>
            <v-card
                :tile="true"
                class="elevation-0 expanded-area-border">
              <v-expand-transition name="support">
                <div v-if="show_rider_details">
                  <v-layout row wrap justify-left px-1>
                    <v-flex xs12 sm6 md4 px-3>
                      <v-card-title class="text-body-1">
                        Title
                      </v-card-title>
                      <v-card-text class="text-body-1 section-content">
                        {{
                          this.$store.getters.titleOptions.filter(o => o.code === riderInfo.title).map(o => o.value).toString()
                        }}
                      </v-card-text>
                    </v-flex>
                    <v-flex xs12 sm6 md4 px-3>
                      <v-card-title class="text-body-1">
                        Type of Rider
                      </v-card-title>
                      <v-card-text class="text-body-1 section-content">
                        {{
                          this.$store.getters.typeofRiderOptions.filter(o => o.code === riderInfo.typeofrider).map(o => o.value).toString()
                        }}
                      </v-card-text>
                    </v-flex>
                    <v-flex xs12 sm6 md4 px-3>
                      <v-card-title class="text-body-1">
                        Transportation mode
                      </v-card-title>
                      <v-card-text class="text-body-1 section-content">
                        {{
                          this.$store.getters.transportationModeOptions.filter(o => o.code === riderInfo.transportationmode).map(o => o.value).toString()
                        }}
                      </v-card-text>
                    </v-flex>

                    <v-flex xs12 sm6 md4 px-3>
                      <v-card-title class="text-body-1">
                        First name
                      </v-card-title>
                      <v-card-text class="text-body-1 section-content">
                        {{ riderInfo.firstname }}
                      </v-card-text>
                    </v-flex>
                    <v-flex xs12 sm6 md4 px-3>
                      <v-card-title class="text-body-1">
                        Middle name
                      </v-card-title>
                      <v-card-text class="text-body-1 section-content">
                        {{ riderInfo.middlename }}
                      </v-card-text>
                    </v-flex>
                    <v-flex xs12 sm6 md4 px-3>
                      <v-card-title class="text-body-1">
                        Last name
                      </v-card-title>
                      <v-card-text class="text-body-1 section-content">
                        {{ riderInfo.lastname }}
                      </v-card-text>
                    </v-flex>

                    <v-flex xs12 sm6 md4 px-3>
                      <v-card-title class="text-body-1">
                        {{ getTitleForID }}
                      </v-card-title>
                      <v-card-text class="text-body-1 section-content">
                        {{ this.getRiderIDNumber}}
                      </v-card-text>
                    </v-flex>
                    <v-flex xs12 sm6 md4 px-3>
                      <v-card-title class="text-body-1">
                        Gender
                      </v-card-title>
                      <v-card-text class="text-body-1 section-content">
                        {{
                          this.$store.getters.genderOptions.filter(o => o.code === riderInfo.gender).map(o => o.value).toString()
                        }}
                      </v-card-text>
                    </v-flex>
                    <v-flex xs12 sm6 md4 px-3>
                      <v-card-title class="text-body-1">
                        Date of Birth(DD/MM/YY)
                      </v-card-title>
                      <v-card-text class="text-body-1 section-content">
                        {{ riderInfo.dateofbirth | moment }}
                      </v-card-text>
                    </v-flex>

                    <v-flex xs12 sm6 md4 px-3>
                      <v-card-title class="text-body-1">
                        Rider ID#
                      </v-card-title>
                      <v-card-text class="text-body-1 section-content">
                        {{ riderInfo.deliverooid }}
                      </v-card-text>
                    </v-flex>
                    <v-flex xs12 sm6 md4 px-3>
                      <v-card-title class="text-body-1">
                        Email
                      </v-card-title>
                      <v-card-text class="text-body-1 section-content">
                        {{ riderInfo.email }}
                      </v-card-text>
                    </v-flex>
                    <v-flex xs12 sm6 md4 px-3>
                      <v-card-title class="text-body-1">
                        Contact Number
                      </v-card-title>
                      <v-card-text class="text-body-1 section-content">
                        {{ getPrefixPhoneNumber }} {{ riderInfo.contactnumber }}
                      </v-card-text>
                    </v-flex>

                    <v-flex xs12 sm6 md4 px-3>
                      <v-card-title class="text-body-1">
                        Country
                      </v-card-title>
                      <v-card-text class="text-body-1 section-content">
                        {{ this.$store.state.country }}
                      </v-card-text>
                    </v-flex>
                    <v-flex xs12 sm6 md4 px-3>
                      <v-card-title class="text-body-1">
                        District
                      </v-card-title>
                      <v-card-text class="text-body-1 section-content">
                        {{ riderInfo.district }}
                      </v-card-text>
                    </v-flex>
                    <v-flex xs12 sm6 md4 px-3>
                      <v-card-title class="text-body-1">
                        Address
                      </v-card-title>
                      <v-card-text class="text-body-1 section-content">
                        {{ riderInfo.address1 }}<br/>
                        {{ riderInfo.address2 }}<br/>
                        {{ riderInfo.address3 }}<br/>
                        {{ riderInfo.address4 }}<br/>
                      </v-card-text>
                    </v-flex>
<!--                    <v-flex xs12 sm6 md4 px-3>-->
<!--                      <v-card-title class="text-body-1">-->
<!--                        Preferred method contact-->
<!--                      </v-card-title>-->
<!--                      <v-card-text class="text-body-1 section-content">-->
<!--                        {{-->
<!--                          this.$store.getters.prefferedContactOptions.filter(o => o.code === riderInfo.preferedcontact).map(o => o.value).toString()-->
<!--                        }}-->
<!--                      </v-card-text>-->
<!--                    </v-flex>-->
                  </v-layout>

                </div>
              </v-expand-transition>
            </v-card>
          </div>
        </div>
        <v-card :tile="true" class="elevation-0 d-sm-none mobile-divider">

        </v-card>
        <v-card
            :tile=true
            class="elevation-0 section-title">
          <v-col class="d-flex justify-space-between align-center pa-0 pr-2">
            <v-toolbar-title class="pa-4 font-weight-bold d-inline-flex justify-space-between text-h6"
                             style="color: #006D9E">3. Incident
              Details
            </v-toolbar-title>
            <v-btn
                icon
                @click="modify('/claims-incidentdetails?modify=true')"
                style="right: auto">
              <v-icon color="secondColor">{{ 'edit' }}</v-icon>
            </v-btn>
          </v-col>
        </v-card>
        <div id="incidentDetails">
          <v-card
              :tile=true
              class="elevation-0 ">
            <v-col class="d-flex justify-space-between align-center pa-0 pr-2">
              <v-toolbar-title class="pa-4 font-weight-bold d-inline-flex justify-space-between">Incident Details
              </v-toolbar-title>
              <v-btn
                  icon
                  @click="show_incident_details = !show_incident_details"
                  style="right: auto">
                <v-icon>{{ show_incident_details ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-col>
          </v-card>

          <v-card class="elevation-0 expanded-area-border" :tile="true">
            <v-expand-transition name="support">
              <div v-if="show_incident_details">
                <v-layout row wrap justify-left px-1>
                  <v-flex xs12 sm6 md4 px-3>
                    <v-card-title class="text-body-1">
                      Were you fault for causing the accident?
                    </v-card-title>
                    <v-card-text class="text-body-1 section-content">
                      {{
                        this.$store.getters.radioButtonOptions.filter(o => o.code === incidentInfo.wasyourfault).map(o => o.value).toString()
                      }}
                    </v-card-text>
                  </v-flex>
                  <v-flex xs12 sm6 md4 px-3>
                    <v-card-title class="text-body-1">
                      Incident date (DD/ MM/ YY)
                    </v-card-title>
                    <v-card-text class="text-body-1 section-content">
                      {{ incidentInfo.incidentdate | moment }}
                    </v-card-text>
                  </v-flex>

                  <v-flex xs12 sm6 md4 px-3>
                    <v-card-title class="text-body-1">
                      Incident time (Hours / Minutes)
                    </v-card-title>
                    <v-card-text class="text-body-1 section-content">
                      {{ incidentInfo.incidenttime }}
                    </v-card-text>
                  </v-flex>

                  <v-flex xs12 sm6 md4 px-3>
                    <v-card-title class="text-body-1">
                      Incident location
                    </v-card-title>
                    <v-card-text class="text-body-1 section-content">
                      {{ incidentInfo.incidentlocation }}
                    </v-card-text>
                  </v-flex>

                  <v-flex xs12 sm6 md4 px-3>
                    <v-card-title class="text-body-1">
                      Incident description
                    </v-card-title>
                    <v-card-text class="text-body-1 section-content">
                      {{ incidentInfo.incidentdescription }}
                    </v-card-text>
                  </v-flex>
                  <v-flex xs12 sm6 md4 px-3>
                    <v-card-title class="text-body-1">
                      Police report number
                    </v-card-title>
                    <v-card-text class="text-body-1 section-content">
                      {{ incidentInfo.policyreportnumber }}
                    </v-card-text>
                  </v-flex>
                  <v-flex xs12 sm6 md4 px-3>
                    <v-card-title class="text-body-1">
                      Can you provide photos of the incident?
                    </v-card-title>
                    <v-card-text class="text-body-1 section-content">
                      {{
                        this.$store.getters.radioButtonOptions.filter(o => o.code ===
                            incidentInfo.canprovidephoto).map(o => o.value).toString()
                      }}
                    </v-card-text>
                  </v-flex>

                  <v-flex xs12 sm6 md4 px-3>
                    <v-card-title class="text-body-1">
                      Attached Incident photos
                    </v-card-title>
                    <v-card-text>
                      <ul>
                        <li class="section-content"
                            v-for="item in incidentInfo.photos" v-bind:key="item.id">
                          {{ item.name }}
                        </li>
                      </ul>
                    </v-card-text>
                  </v-flex>
                </v-layout>

              </div>
            </v-expand-transition>
          </v-card>

          <v-card
              :tile=true
              class="elevation-0">
            <v-col class="d-flex justify-space-between align-center pa-0 pr-2">
              <v-toolbar-title class="pa-4 font-weight-bold d-inline-flex justify-space-between">Third Party Details
              </v-toolbar-title>
              <v-btn
                  icon
                  @click="show_third_party = !show_third_party"
                  style="right: auto">
                <v-icon>{{ show_third_party ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-col>
          </v-card>

          <v-card class="elevation-0 expanded-area-border" :tile="true">
            <v-expand-transition name="support">
              <div v-if="show_third_party">
                <v-layout row wrap justify-left px-1>
                  <v-flex xs12 sm6 md4 px-3>
                    <v-card-title class="text-body-1">
                      Was any third party liable for the incident?
                    </v-card-title>
                    <v-card-text class="text-body-1 section-content">
                      {{
                        this.$store.getters.radioButtonOptions.filter(o => o.code === wasAnyThirdPartyLiableInfo).map(o => o.value).toString()
                      }}
                    </v-card-text>
                  </v-flex>
                </v-layout>

                <v-layout row wrap justify-left px-1 v-if="wasAnyThirdPartyLiableInfo==='Y'">
                  <v-flex xs12 sm6 md4 px-3>
                    <v-card-title class="text-body-1">
                      First name
                    </v-card-title>
                    <v-card-text class="text-body-1 section-content">
                      {{ thirdPartyInfo.firstname }}
                    </v-card-text>
                  </v-flex>

                  <v-flex xs12 sm6 md4 px-3>
                    <v-card-title class="text-body-1">
                      Middle name
                    </v-card-title>
                    <v-card-text class="text-body-1 section-content">
                      {{ thirdPartyInfo.middlename }}
                    </v-card-text>
                  </v-flex>
                  <v-flex xs12 sm6 md4 px-3>
                    <v-card-title class="text-body-1">
                      Last name
                    </v-card-title>
                    <v-card-text class="text-body-1 section-content">
                      {{ thirdPartyInfo.lastname }}
                    </v-card-text>
                  </v-flex>

                  <v-flex xs12 sm6 md4 px-3>
                    <v-card-title class="text-body-1">
                      Contact Number
                    </v-card-title>
                    <v-card-text class="text-body-1 section-content">
                      {{ getPrefixPhoneNumber }} {{ thirdPartyInfo.contactnumber }}
                    </v-card-text>
                  </v-flex>

                  <v-flex xs12 sm6 md4 px-3>
                    <v-card-title class="text-body-1">
                      Email
                    </v-card-title>
                    <v-card-text class="text-body-1 section-content">
                      {{ thirdPartyInfo.email }}
                    </v-card-text>
                  </v-flex>
                  <v-flex xs12 sm6 md4 px-3>
                    <v-card-title class="text-body-1">
                      Country
                    </v-card-title>
                    <v-card-text class="text-body-1 section-content">
                      {{ thirdPartyInfo.country }}
                    </v-card-text>
                  </v-flex>
                  <v-flex xs12 sm6 md4 px-3>
                    <v-card-title class="text-body-1">
                      District
                    </v-card-title>
                    <v-card-text class="text-body-1 section-content">
                      {{ thirdPartyInfo.district }}
                    </v-card-text>
                  </v-flex>
                  <v-flex xs12 sm6 md4 px-3>
                    <v-card-title class="text-body-1">
                      Address
                    </v-card-title>
                    <v-card-text class="text-body-1 section-content">
                      {{ thirdPartyInfo.address1 }}<br/>
                      {{ thirdPartyInfo.address2 }}<br/>
                      {{ thirdPartyInfo.address3 }}<br/>
                      {{ thirdPartyInfo.address4 }}<br/>
                    </v-card-text>
                  </v-flex>
                </v-layout>
              </div>
            </v-expand-transition>
          </v-card>

          <v-card
              :tile=true
              class="elevation-0">
            <v-col class="d-flex justify-space-between align-center pa-0 pr-2">
              <v-toolbar-title class="pa-4 font-weight-bold d-inline-flex justify-space-between">Witness Details
              </v-toolbar-title>
              <v-btn
                  icon
                  @click="show_witness = !show_witness"
                  style="right: auto">
                <v-icon>{{ show_witness ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-col>
          </v-card>

          <v-card class="elevation-0 expanded-area-border" :tile="true">
            <v-expand-transition name="support">
              <div v-if="show_witness">
                <v-layout row wrap justify-left px-1>
                  <v-flex xs12 sm6 md4 px-3>
                    <v-card-title class="text-body-1">
                      Was the accident witnessed by anyone?
                    </v-card-title>
                    <v-card-text class="text-body-1 section-content">
                      {{
                        this.$store.getters.radioButtonOptions.filter(o => o.code === wereThereAnyWitnessInfo).map(o => o.value).toString()
                      }}
                    </v-card-text>
                  </v-flex>
                </v-layout>

                <v-layout row wrap justify-left px-1 v-if="wereThereAnyWitnessInfo==='Y'">
                  <v-flex xs12 sm6 md4 px-3>
                    <v-card-title class="text-body-1">
                      First name
                    </v-card-title>
                    <v-card-text class="text-body-1 section-content">
                      {{ witnessInfo.firstname }}
                    </v-card-text>
                  </v-flex>

                  <v-flex xs12 sm6 md4 px-3>
                    <v-card-title class="text-body-1">
                      Middle name
                    </v-card-title>
                    <v-card-text class="text-body-1 section-content">
                      {{ witnessInfo.middlename }}
                    </v-card-text>
                  </v-flex>
                  <v-flex xs12 sm6 md4 px-3>
                    <v-card-title class="text-body-1">
                      Last name
                    </v-card-title>
                    <v-card-text class="text-body-1 section-content">
                      {{ witnessInfo.lastname }}
                    </v-card-text>
                  </v-flex>

                  <v-flex xs12 sm6 md4 px-3>
                    <v-card-title class="text-body-1">
                      Contact Number
                    </v-card-title>
                    <v-card-text class="text-body-1 section-content">
                      {{ getPrefixPhoneNumber }} {{ witnessInfo.contactnumber }}
                    </v-card-text>
                  </v-flex>

                  <v-flex xs12 sm6 md4 px-3>
                    <v-card-title class="text-body-1">
                      Email
                    </v-card-title>
                    <v-card-text class="text-body-1 section-content">
                      {{ witnessInfo.email }}
                    </v-card-text>
                  </v-flex>
                  <v-flex xs12 sm6 md4 px-3>
                    <v-card-title class="text-body-1">
                      Country
                    </v-card-title>
                    <v-card-text class="text-body-1 section-content">
                      {{ witnessInfo.country }}
                    </v-card-text>
                  </v-flex>
                  <v-flex xs12 sm6 md4 px-3>
                    <v-card-title class="text-body-1">
                      District
                    </v-card-title>
                    <v-card-text class="text-body-1 section-content">
                      {{ witnessInfo.district }}
                    </v-card-text>
                  </v-flex>
                  <v-flex xs12 sm6 md4 px-3>
                    <v-card-title class="text-body-1">
                      Address
                    </v-card-title>
                    <v-card-text class="text-body-1 section-content">
                      {{ witnessInfo.address1 }}<br/>
                      {{ witnessInfo.address2 }}<br/>
                      {{ witnessInfo.address3 }}<br/>
                      {{ witnessInfo.address4 }}<br/>
                    </v-card-text>
                  </v-flex>
                  <v-flex xs12 sm6 md4 px-3>
                    <v-card-title class="text-body-1">
                      Can you provide a witness statement?
                    </v-card-title>
                    <v-card-text class="text-body-1 section-content">
                      {{
                        this.$store.getters.radioButtonOptions.filter(o => o.code === witnessInfo.canprovidewitness).map(o => o.value).toString()
                      }}
                    </v-card-text>
                  </v-flex>
                  <v-flex xs12 sm6 md4 px-3>
                    <v-card-title class="text-body-1">
                      Attached statement proof
                    </v-card-title>
                    <v-card-text>
                      <ul>
                        <li class="section-content"
                            v-for="item in witnessInfo.statementproof" v-bind:key="item.id">
                          {{ item.name }}
                        </li>
                      </ul>
                    </v-card-text>
                  </v-flex>
                </v-layout>
              </div>
            </v-expand-transition>
          </v-card>

          <div v-if="IsAMERCoverageType||IsPersonalDisabilityCoverageType">
            <v-card
                :tile=true
                class="elevation-0">
              <v-col class="d-flex justify-space-between align-center pa-0 pr-2">
                <v-toolbar-title class="pa-4 font-weight-bold d-inline-flex justify-space-between">Injury Details
                </v-toolbar-title>
                <v-btn
                    icon
                    @click="show_injury_details = !show_injury_details"
                    style="right: auto">
                  <v-icon>{{ show_injury_details ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </v-btn>
              </v-col>
            </v-card>

            <v-card class="elevation-0 expanded-area-border" :tile="true">
              <v-expand-transition name="support">
                <div v-if="show_injury_details">
                  <v-layout row wrap justify-left px-1>
                    <v-flex xs12 sm4 px-3>
                      <v-card-title class="text-body-1">
                        Injury Category
                      </v-card-title>
                      <v-card-text class="text-body-1 section-content">
                        {{
                          this.$store.getters.injuryLocationOptions.filter(o => o.code === injuryInfo.location).map(o => o.value).toString()
                        }}
                      </v-card-text>
                    </v-flex>
                    <v-flex xs12 sm4 px-3>
                      <v-card-title class="text-body-1">
                        Injury Type
                      </v-card-title>
                      <v-card-text class="text-body-1 section-content">
                        {{
                          this.$store.getters.injuryTypeOptions.filter(o => o.code === injuryInfo.injurytype).map(o => o.value).toString()
                        }}
                      </v-card-text>
                    </v-flex>
                    <v-flex xs12 sm4 px-3>
                      <v-card-title class="text-body-1">
                        Others (please describe)
                      </v-card-title>
                      <v-card-text class="text-body-1 section-content">
                        {{
                          injuryInfo.other
                        }}
                      </v-card-text>
                    </v-flex>
                  </v-layout>

                </div>
              </v-expand-transition>
            </v-card>
          </div>
          <v-card :tile="true" class="elevation-0 d-sm-none mobile-divider">

          </v-card>
          <v-card
              :tile=true
              class="elevation-0 section-title">
            <v-col class="d-flex justify-space-between align-center pa-0 pr-2">
              <v-toolbar-title class="pa-4 font-weight-bold d-inline-flex justify-space-between text-h6"
                               style="color: #006D9E">4. Supporting Documents & Claims Payment Transfer Details
              </v-toolbar-title>
              <v-btn
                  icon
                  @click="modify('/claims-documentupload?modify=true')"
                  style="right: auto">
                <v-icon color="secondColor">{{ 'edit' }}</v-icon>
              </v-btn>
            </v-col>
          </v-card>

          <v-card
              :tile=true
              class="elevation-0 ">
            <v-col class="d-flex justify-space-between align-center pa-0 pr-2">
              <v-toolbar-title class="pa-4 font-weight-bold d-inline-flex justify-space-between">Supporting Documents
              </v-toolbar-title>
              <v-btn
                  icon
                  @click="show_supporting_document = !show_supporting_document"
                  style="right: auto">
                <v-icon>{{ show_supporting_document ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-col>
          </v-card>

          <v-card class="elevation-0 expanded-area-border" :tile="true">
            <v-expand-transition name="support">
              <div v-if="show_supporting_document">
                <v-layout
                    v-if="IsAMERCoverageType||IsPersonalAccidentCoverageType"
                    row wrap justify-left px-1>
                  <v-flex xs12 sm6 md4 px-4>
                    <v-card-title class="text-body-1">
                      Total claim amount ({{ getTitleForTotalClaimAmount }})
                    </v-card-title>
                    <v-card-text class="text-body-1 section-content">
                      {{ new Intl.NumberFormat().format(documentInfo.totalclaimamount) }}
                    </v-card-text>
                  </v-flex>
                </v-layout>

                <v-layout
                    v-if="IsAccidentalDeathCoverageType"
                    row wrap justify-left px-1>
                  <v-flex
                      xs12
                      sm6 md4
                      px-4>
                    <v-card-title
                        class="text-body-1">
                      Date of Death
                    </v-card-title>
                    <v-card-text
                        class="text-body-1 section-content">
                      {{ documentInfo.dateofdeath|moment }}
                    </v-card-text>
                  </v-flex>
                  <v-flex
                      xs12
                      sm6 md4
                      px-4>
                    <v-card-title
                        class="text-body-1">
                      Where did the Death occur?
                    </v-card-title>
                    <v-card-text
                        class="text-body-1 section-content">
                      {{ documentInfo.wheredeathoccur }}
                    </v-card-text>
                  </v-flex>
                  <v-flex
                      xs12
                      sm6 md4
                      px-4>
                    <v-card-title
                        class="text-body-1">
                      Cause of Death
                    </v-card-title>
                    <v-card-text
                        class="text-body-1 section-content">
                      {{ documentInfo.causeofdeath }}
                    </v-card-text>
                  </v-flex>

                  <v-flex
                      xs12
                      sm6 md4
                      px-4>
                    <v-card-title
                        class="text-body-1">
                      Do you want to claim for Funeral Expenses?
                    </v-card-title>
                    <v-card-text
                        class="text-body-1 section-content">
                      {{
                        this.$store.getters.radioButtonOptions.filter(o => o.code === documentInfo.claimforfuneralexpenses).map(o => o.value).toString()
                      }}
                    </v-card-text>
                  </v-flex>
                  <v-flex
                      xs12
                      sm6 md4
                      px-4>
                    <v-card-title
                        class="text-body-1">
                      Does the insured person have any dependant children under education?
                    </v-card-title>
                    <v-card-text
                        class="text-body-1 section-content">
                      {{
                        this.$store.getters.radioButtonOptions.filter(o => o.code === documentInfo.anydependentchildren).map(o => o.value).toString()
                      }}
                    </v-card-text>
                  </v-flex>
                </v-layout>

                <v-layout
                    v-if="IsPersonalAccidentCoverageType"
                    row wrap justify-left px-1>
                  <v-flex v-for="(item,index) in lossOrDamageItemInfo" :key="index" class="px-4">
                    <v-card
                        :tile=true
                        class="elevation-0" style="border-bottom: #00CCBC 1px solid">
                      <v-col class="d-flex justify-space-between align-center pa-0 pr-2">
                        <v-toolbar-title class="pa-2 font-weight-bold d-inline-flex justify-space-between">
                          Item
                        </v-toolbar-title>
                      </v-col>
                    </v-card>


                        <v-layout row wrap justify-left px-1>

                          <v-flex xs12 sm6 md4>
                            <v-card-title
                                class="text-body-1">
                              Was your item lost or damaged?
                            </v-card-title>
                            <v-card-text
                                class="text-body-1 section-content">
                              {{
                                lostOrDamageOptions.filter(o => o.code ===
                                    item.wasyouritemlostdamaged).map(o => o.value).toString()
                              }}
                            </v-card-text>
                          </v-flex>
                          <v-flex xs12 sm6 md4>
                            <v-card-title
                                class="text-body-1">
                              What item was lost or damaged
                            </v-card-title>
                            <v-card-text
                                class="text-body-1 section-content">
                              {{ item.whatitemlostdamaged }}
                            </v-card-text>
                          </v-flex>
                          <v-flex xs12 sm6 md4>
                            <v-card-title
                                class="text-body-1">
                              When was the item purchased?
                            </v-card-title>
                            <v-card-text
                                class="text-body-1 section-content">
                              {{ item.whenitempurchased | moment }}
                            </v-card-text>
                          </v-flex>
                          <v-flex xs12 sm6 md4>
                            <v-card-title
                                class="text-body-1">
                              How much was the item({{getCurrency}})
                            </v-card-title>
                            <v-card-text
                                class="text-body-1 section-content">
                              {{ item.howmuchitem }}
                            </v-card-text>
                          </v-flex>
                          <v-flex xs12 sm6 md4>
                            <v-card-title
                                class="text-body-1">
                              Have you purchased a replacement for the item?
                            </v-card-title>
                            <v-card-text
                                class="text-body-1 section-content">
                              {{
                                GetYesNoOptions.filter(o => o.code ===
                                    item.havepurchasedreplaceitem).map(o => o.value).toString()

                              }}
                            </v-card-text>
                          </v-flex>


                        </v-layout>


                  </v-flex>
                </v-layout>
                <v-layout style="border: #F3F3F3 solid 1px;" row wrap justify-left px-1
                          class="my-8" :class="$vuetify.breakpoint.mdAndUp?'mx-2':''">
                  <v-flex
                      v-for=" (item,index) in this.supportDocumentAttachments"
                      :key="item.code"
                      xs12
                      md4>
                    <v-card-title
                        class="text-body-2">
                      {{ (index + 10).toString(36).toLowerCase() }}. {{ item.value }}
                    </v-card-title>
                    <v-card-text
                        v-if="typeof item.documents!= 'undefined' && item.documents!==undefined && item.documents.length !==0">
                      <ul style="list-style-type: disc;width: 100%;">
                        <li style="width: 100%;line-height: 10px" v-for="fileItem in item.documents"
                            v-bind:key="fileItem.id" class="section-content">
                          <p
                              class="section-content">
                            {{ fileItem.name }}
                          </p>
                        </li>
                      </ul>
                    </v-card-text>
                    <v-divider v-if="$vuetify.breakpoint.smAndDown" name="name"/>
                  </v-flex>
                </v-layout>

              </div>
            </v-expand-transition>
          </v-card>

          <v-card
              :tile=true
              class="elevation-0 ">
            <v-col class="d-flex justify-space-between align-center pa-0 pr-2">
              <v-toolbar-title class="pa-4 font-weight-bold d-inline-flex justify-space-between">Other Insurance
                Coverage
              </v-toolbar-title>
              <v-btn
                  icon
                  @click="show_other_insurance = !show_other_insurance"
                  style="right: auto">
                <v-icon>{{ show_other_insurance ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-col>
          </v-card>
          <v-card class="elevation-0 expanded-area-border" :tile="true">
            <v-expand-transition name="support">
              <div v-if="show_other_insurance">
                <v-layout row wrap justify-left px-1>
                  <v-flex xs12 sm6 md4 px-3>
                    <v-card-title class="text-body-1">
                      Do you have any other insurance that might cover this loss?
                    </v-card-title>
                    <v-card-text class="text-body-1 section-content">
                      {{
                        GetYesNoOptions.filter(o => o.code === otherInsuranceCover).map(o => o.value).toString()
                      }}
                    </v-card-text>
                  </v-flex>
                  <v-flex xs12 sm6 md4 px-3 v-if="otherInsuranceCover==='Y'">
                    <v-card-title class="text-body-1">
                      Name of Insurance Company
                    </v-card-title>
                    <v-card-text class="text-body-1 section-content">
                      {{ otherInsuranceCoverage.nameofinsurer }}
                    </v-card-text>
                  </v-flex>
                  <v-flex xs12 sm6 md4 px-3 v-if="otherInsuranceCover==='Y'">
                    <v-card-title class="text-body-1">
                      Policy number
                    </v-card-title>
                    <v-card-text class="text-body-1 section-content">
                      {{ otherInsuranceCoverage.policynumber }}
                    </v-card-text>
                  </v-flex>
                  <v-flex xs12 sm6 md4 px-3 v-if="otherInsuranceCover==='Y'">
                    <v-card-title class="text-body-1">
                      {{ getTitleForOtherInsuranceBenefitAmount }}
                    </v-card-title>
                    <v-card-text class="text-body-1 section-content">
                      {{ new Intl.NumberFormat().format(otherInsuranceCoverage.benefitamount) }}
                    </v-card-text>
                  </v-flex>
                </v-layout>

              </div>
            </v-expand-transition>
          </v-card>

          <v-card
              v-if="IsAMERCoverageType"
              :tile=true
              class="elevation-0 ">
            <v-col class="d-flex justify-space-between align-center pa-0 pr-2">
              <v-toolbar-title class="pa-4 font-weight-bold d-inline-flex justify-space-between">Medical Leave
              </v-toolbar-title>
              <v-btn
                  icon
                  @click="show_medical_leave = !show_medical_leave"
                  style="right: auto">
                <v-icon>{{ show_medical_leave ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-col>
          </v-card>
          <v-card
              v-if="IsAMERCoverageType"
              class="elevation-0 expanded-area-border" :tile="true">
            <v-expand-transition name="support">
              <div v-if="show_medical_leave">
                <v-layout row wrap justify-left px-1>
                  <v-flex xs12 md4 px-3>
                    <v-card-title class="text-body-1">
                      Did you require medical leave?
                    </v-card-title>
                    <v-card-text class="text-body-1 section-content">
                      {{
                        GetYesNoOptions.filter(o => o.code === pleaseTickMedicalLeaveInfo).map(o => o.value).toString()
                      }}
                    </v-card-text>
                  </v-flex>
                  <v-flex xs12 sm6 md4 px-3 v-if="pleaseTickMedicalLeaveInfo==='Y'">
                    <v-card-title class="text-body-1">
                      When did your medical leave start?(DD/ MM/ YY)
                    </v-card-title>
                    <v-card-text class="text-body-1 section-content">
                      {{ medicalLeave.medicalleavestart|moment }}
                    </v-card-text>
                  </v-flex>
                  <v-flex xs12 sm6 md4 px-3 v-if="pleaseTickMedicalLeaveInfo==='Y'">
                    <v-card-title class="text-body-1">
                      When did your medical leave end?(DD/ MM/ YY)
                    </v-card-title>
                    <v-card-text class="text-body-1 section-content">
                      {{ medicalLeave.medicalleavelend|moment }}
                    </v-card-text>
                  </v-flex>

                </v-layout>
                <v-layout row wrap justify-left px-1 v-if="pleaseTickMedicalLeaveInfo==='Y'">
                  <v-flex xs12 md4 px-3>
                    <v-card-title class="text-body-1">
                      Were you able to perform light duties, during your medical leave, according to a Doctor’s advice?
                    </v-card-title>
                    <v-card-text class="text-body-1 section-content">
                      {{
                        GetYesNoOptions.filter(o => o.code === medicalLeave.performlightduty).map(o => o.value).toString()
                      }}
                    </v-card-text>
                  </v-flex>
                  <v-flex xs12 sm6 md4 px-3 v-if="medicalLeave.performlightduty==='Y'">
                    <v-card-title class="text-body-1">
                      When were you able start performing light duty during the medical leaves?
                    </v-card-title>
                    <v-card-text class="text-body-1 section-content">
                      {{ medicalLeave.whenperformlightduty|moment }}
                    </v-card-text>
                  </v-flex>
                  <v-flex xs12 sm6 md4 px-3 v-if="medicalLeave.performlightduty==='Y'">
                    <v-card-title class="text-body-1">
                      When did you fully recover?
                    </v-card-title>
                    <v-card-text class="text-body-1 section-content">
                      {{ medicalLeave.whenfullyrecover|moment }}
                    </v-card-text>
                  </v-flex>

                </v-layout>
                <v-layout
                    v-if="pleaseTickMedicalLeaveInfo==='Y'"
                    style="border: #F3F3F3 solid 1px;" row wrap justify-left px-1
                    class="my-8" :class="$vuetify.breakpoint.mdAndUp?'mx-2':''">
                  <v-flex
                      v-for=" (item,index) in this.medicalDocumentAttachments"
                      :key="item.code"
                      xs12
                      md4>
                    <v-card-title
                        class="text-body-2">
                      {{ (index + 10).toString(36).toLowerCase() }}. {{ item.value }}
                    </v-card-title>
                    <v-card-text
                        v-if="typeof item.medicaldocuments!= 'undefined' && item.medicaldocuments!==undefined && item.medicaldocuments.length !==0">
                      <ul style="list-style-type: disc;width: 100%;">
                        <li style="width: 100%;line-height: 10px" v-for="fileItem in item.medicaldocuments"
                            v-bind:key="fileItem.id" class="section-content">
                          <p
                              class="section-content">
                            {{ fileItem.name }}
                          </p>
                        </li>
                      </ul>
                    </v-card-text>
                    <v-divider v-if="$vuetify.breakpoint.smAndDown" name="name"/>
                  </v-flex>
                </v-layout>
              </div>
            </v-expand-transition>
          </v-card>

          <v-card
              :tile=true
              class="elevation-0 ">
            <v-col class="d-flex justify-space-between align-center pa-0 pr-2">
              <v-toolbar-title
                  class="pa-4 font-weight-bold d-inline-flex justify-space-between">
                Claims Payment Transfer Details
              </v-toolbar-title>
              <v-btn
                  icon
                  @click="show_transfer_detail = !show_transfer_detail"
                  style="right: auto">
                <v-icon>{{ show_transfer_detail ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-col>
          </v-card>
          <v-card
              :tile="true"
              class="elevation-0 expanded-area-border">
            <v-expand-transition name="support">
              <div v-if="show_transfer_detail">
                <v-layout row wrap justify-left px-1>

                  <v-flex xs12 sm6 md4 px-3>
                    <v-card-title class="text-body-1">
                      Payee name / Account holder
                    </v-card-title>
                    <v-card-text class="text-body-1 section-content">
                      {{ transferDetailInfo.accountholder }}
                    </v-card-text>
                  </v-flex>
                  <v-flex xs12 sm6 md4 px-3>
                    <v-card-title class="text-body-1">
                      Bank name
                    </v-card-title>
                    <v-card-text class="text-body-1 section-content" v-if="!transferDetailInfo.otherbankname">
                      {{
                        getBankOptions.filter(o => o.code === transferDetailInfo.bankname).map(o => o.value).toString()
                      }}
                    </v-card-text>
                    <v-card-text class="text-body-1 section-content" v-if="transferDetailInfo.otherbankname">
                      {{
                        transferDetailInfo.otherbankname
                      }}
                    </v-card-text>
                  </v-flex>

                  <v-flex xs12 sm6 md4 px-3>
                    <v-card-title class="text-body-1">
                      Account number
                    </v-card-title>
                    <v-card-text class="text-body-1 section-content">
                      {{ transferDetailInfo.accountnumber }}
                    </v-card-text>
                  </v-flex>
                  <v-flex xs12 sm4 px-3>
                    <v-card-title class="text-body-1">
                      Bank / branch code
                    </v-card-title>
                    <v-card-text class="text-body-1 section-content">
                      {{ transferDetailInfo.branchcode }}
                    </v-card-text>
                  </v-flex>
                </v-layout>

              </div>
            </v-expand-transition>
          </v-card>

        </div>
        <v-flex sm2></v-flex>
        <v-flex xs12 sm12>
          <v-btn
              x-large
              depressed
              block
              :tile=true
              class="ma-0 pa-0 white--text d-md-flex d-lg-none action-button"
              color="#00CCBC"
              @click="validateAndGoNext">Submit Claim
          </v-btn>
        </v-flex>
      </v-flex>
    </v-layout>

    <v-dialog
        v-model="dialog"
        persistent
        width="300"
    >
      <v-card
          color="primary"
      >
        <v-card-text class="pa-4 white--text">
          Please wait until your claim is submitted..
          <v-progress-linear
              indeterminate
              color="white"
              class="mb-0 mt-3"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-layout class="d-none d-lg-flex mb-8 " :tile="true">
      <v-flex lg2></v-flex>
      <v-flex xs12 lg8 :class="$vuetify.breakpoint.mdAndUp?'menu-divider-top':''">
        <v-row>
          <v-col offset="3" cols="3" class=" pr-2">
            <v-btn
                x-large
                depressed
                :tile="true"
                block
                color="#93A1B4"
                class="white--text action-button"
                @click="goPrevious()"
            >
              <v-icon>{{ 'mdi-chevron-left' }}</v-icon>
              Previous

            </v-btn>
          </v-col>
          <v-col cols="3" class="pl-2">

            <v-btn
                x-large
                depressed
                :tile="true"
                block
                color="#00CCBC"
                class="white--text action-button"
                style="text-align: right;"
                @click="validateAndGoNext"
            >Submit Claim
              <v-icon>{{ 'mdi-chevron-right' }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
    <!-- fab icon -->
    <v-btn
        v-scroll="onScroll"
        v-show="is_show_fav_icon && $vuetify.breakpoint.smAndDown"
        elevation="1"
        fab
        fixed
        bottom
        right
        color="blue darken-3"
        style="margin-bottom: 60px;margin-right: 16px;"
        @click="toTop">
      <img :src="icons.bottom_top_arrow" @click="toTop" style="max-width: 20px; max-height: 20px; ">
    </v-btn>
    <v-dialog
        v-model="errorDialog"
        persistent
        :width="$vuetify.breakpoint.smAndDown?'90%':429"
    >
      <v-card
          color="white"
          class="pt-4 ma-0"
          style="box-shadow: 3px 4px 5px #00000033;border: 2px solid #006D9E !important;border-radius: 10px; padding: 2px"
      >
        <v-icon style="float: right;padding-right: 16px" @click="errorDialog=!errorDialog">clear</v-icon>
        <v-card-text class="px-4 pt-10 pb-0 text-body-1 black--text text-center" >
          {{ errorCodeTitle }}
        </v-card-text>
        <v-card-text class="px-4 pb-4 text-body-1 black--text text-center" >
          ( ERROR CODE : {{this.errorCode}} )
        </v-card-text>
        <v-card-text class="px-4 pt-2 pb-8 text-body-1 red--text text-center">
          {{this.errorMessage}}
        </v-card-text>
        <div style="background: #F2FBFC 0% 0% no-repeat padding-box; height: 77px; padding-top: 5px !important;">
          <v-row justify="center" align-content="center" class="ma-0 pa-0">
            <v-col cols="4" align-self="center">
              <v-btn
                  color="#00CCBC"
                  class="ma-0 white--text text-body-1"
                  large
                  block
                  @click="errorDialog=!errorDialog">
                Ok
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import moment from 'moment';
import {mapGetters, mapMutations} from "vuex";

import FileUpload from "vue-upload-component";
import PremiumApi from "@/plugins/axios";

export default {
  components: {},
  data() {
    return {
      dialog: false,
      errorDialog: false,
      errorCode:'',
      errorCodeTitle:'',
      errorMessage: 'Currently our site is under maintenance, please come back to submit your claim in 8 hours.  If there is anything urgent, please contact deliverooclaims@marsh.com.',
      submissionId: '',
      postData: {},
      show_reporter_details: true,
      reporterRelationship: this.getReporterRelationshipInfo(),
      show_rider_details: true,
      show_incident_details: true,
      show_third_party: true,
      show_witness: true,
      show_injury_details: true,
      supportDocumentAttachments: [],
      medicalDocumentAttachments: [],
      show_supporting_document: true,
      show_other_insurance: true,
      show_medical_leave: true,
      show_transfer_detail: true,
      is_show_fav_icon: true,
      icons: {
        personal_accident_icon: require('@/assets/icons/icon_Image_00.png'),
        public_liability_icon: require('@/assets/icons/icon_Image_01.png'),
        right_arrow_icon: require('@/assets/icons/icon_select_arrow_01.png'),
        bottom_top_arrow: require('@/assets/icons/bottom_top_arrow.png'),
        date_icon: require('@/assets/icons/icon_Image_09.png'),
      },
      fromDateMenuMedicalStart: false,
      fromDateMenuMedicalEnd: false,
      fromDateMenuLightDutyStart: false,
      fromDateMenuFullyRecover: false,
      minDate: "2018-01-01",
      pickerDate: moment().format('YYYY[-]MM[-]DD'),
      maxDate: moment().format('YYYY[-]MM[-]DD'),
      maxlossOrDamageItemCount: 5,

      minSize: 1024,
      size: 1024 * 1024 * 10,
      maximum: 10,
      multiple: true,
      directory: false,
      drop: false,
      dropDirectory: false,
      addIndex: false,
      thread: 3,
      name: 'file',
      postAction: '/upload/post',
      putAction: '/upload/put',
      headers: {
        'X-Csrf-Token': 'xxxx',
      },
      data: {
        '_csrf_token': 'xxxxxx',
      },
      autoCompress: 1024 * 1024,
      uploadAuto: false,
      addData: {
        show: false,
        name: '',
        type: '',
        content: '',
      },
      editFile: {
        show: false,
        name: '',
      },

      reporterInfo: this.getReporterInfo(),
      riderInfo: this.getRiderInfo(),
      incidentInfo: this.getIncident(),
      wasAnyThirdPartyLiableInfo: this.getWasAnyThirdPartyLiable(),
      thirdPartyInfo: this.getThirdParty(),
      wereThereAnyWitnessInfo: this.getWereThereAnyWitness(),
      witnessInfo: this.getWitness(),
      injuryInfo: this.getInjury(),

      documentInfo: this.getDocumentDetails(),
      lossOrDamageItemInfo: this.getLossOrDamageItem(),
      otherInsuranceCover: this.getOtherInsuranceCovering(),
      otherInsuranceCoverageInfo: this.getOtherInsuranceCoverage(),
      pleaseTickMedicalLeaveInfo: this.getPleaseTickMedicalLeave(),
      medicalLeaveInfo: this.getMedicalLeave(),
      transferDetailInfo: this.getTransferDetail(),

      rules: {
        required: value => !!value || "This field is mandatory",
        numberrule: value => {
          const pattern = /^\d*\.?\d*$/;
          if (value == null || value == undefined) {
            return true;
          }
          return (
              pattern.test(
                  String(value)
                      .replace(/\,/g, "")
                      .replace(/\-/g, "")
              ) || "This field must have numeric value"
          );
        },
        emailValidation: [v => !!v || 'This field is mandatory\n',
          v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'],
      }
    }
  },
  computed: {
    IsAMERCoverageType() {
      return this.$store.state.incidenttype2 === "AMER";
    },
    IsPersonalDisabilityCoverageType() {
      return this.$store.state.incidenttype2 === "PD";
    },
    IsPersonalAccidentCoverageType() {
      return this.$store.state.incidenttype2 === "LDPA";
    },
    IsAccidentalDeathCoverageType() {
      return this.$store.state.incidenttype2 === "AD";
    },
    getCoverageText() {
      return this.$store.getters.incidentType2Options
          .filter(incidentType => incidentType.code === this.$store.getters.coverageType)
          .map(coverage => coverage.value).toString()
    },
    getPrefixPhoneNumber() {
      return 'Hong Kong' === this.$store.state.country ? '+852' : '+65'
    },
    GetCountryName() {
      return _.cloneDeep(this.$store.state.country);
    },
    getTitleForID() {
      if (this.$store.state.country === 'Singapore') {
        return 'National Registration ID card'
      }else {
        return 'Hong Kong ID number'
      }
    },
    getTitleForTotalClaimAmount() {
      if (this.$store.state.country === 'Singapore') {
        return 'SGD'
      }else {
        return 'HKD'
      }
    },
    getTitleForOtherInsuranceBenefitAmount() {
      if (this.$store.state.country === 'Singapore') {
        return 'Benefit amount (SGD)'
      }else {
        return 'Benefit amount (HKD)'
      }
    },
    getReporterIDNumber() {
      if (this.$store.state.country === 'Singapore') {
        return this.reporterInfo.hongkongid
      }else {
        return this.reporterInfo.hongkongid.replace(/.$/,'('+this.reporterInfo.hongkongid.slice(-1)+')')
      }
    },
    getRiderIDNumber() {
      if (this.$store.state.country === 'Singapore') {
        return this.riderInfo.hongkongid
      }else {
        return this.riderInfo.hongkongid.replace(/.$/,'('+this.riderInfo.hongkongid.slice(-1)+')')
      }
    },
    getCurrency (){
      if (this.$store.state.country === 'Singapore') {
        return 'SGD'
      }else {
        return 'HKD'
      }
    },
    GetYesNoOptions() {
      return _.cloneDeep(this.$store.getters.radioButtonOptions);
    },
    getBankOptions() {
      return _.cloneDeep(this.$store.getters.bankOptions);
    },
    getIncidentDetailsFileCode() {
      return _.cloneDeep(this.$store.getters.incidentDetailsPhotoCode);
    },
    getWitnessFileCode() {
      return _.cloneDeep(this.$store.getters.witnessStatementCode);
    },
    getIncidentType() {
      return _.cloneDeep(this.$store.getters.incidentType)
    },
    getCoverageType() {
      return _.cloneDeep(this.$store.getters.coverageType)
    },
    getIncidentPhotosComputed() {
      return _.cloneDeep(this.$store.getters.getIncidentPhotos)
    },
    getStatementProofComputed() {
      return _.cloneDeep(this.$store.state.witnessdetails.statementproof)
    },
    getSupportDocumentsAttachments() {
      return _.cloneDeep(this.$store.state.supportingdocuments.attachments)
    },
    getMedicalDocuments() {
      return _.cloneDeep(this.$store.state.medicalleave.medicaldocuments)
    },
    ...mapGetters([
      'witnessStatementCode',
      'coverageType',
      'incidentType2Options',
      'injuryLocationOptions',
      'injuryTypeOptions',
      'genderOptions',
      'prefferedContactOptions',
      'radioButtonOptions',
      'transportationModeOptions',
      'supportDocumentOptions',
      'bankOptions',

      'incidentDetails',
      'wasAnyThirdPartyLiable',
      'thirdPartyDetails',
      'wereThereAnyWitness',
      'witnessDetails',
      'injuryDetails',

      'supportDocumentDetails',
      'lossOrDamageItem',
      'lostOrDamageOptions',
      'otherInsuranceCovering',
      'otherInsuranceCoverage',
      'pleaseTickMedicalLeave',
      'medicalLeave',
      'transferDetail',
    ]),
  },
  methods: {

    ...mapMutations(
        [
          'saveSupportDocumentDetails',
          'saveLossOrDamageItem'
        ]
    ),
    setClaimData(data) {
      this.data = data
    },
    getReporterInfo() {
      return this.$store.getters.reporterDetails
    },
    getReporterRelationshipInfo() {
      return _.cloneDeep(this.$store.getters.getReporterRelationship)
    },
    getRiderInfo() {
      return this.$store.getters.riderDetails
    },
    getIncident() {
      return this.$store.getters.incidentDetails
    },
    getWasAnyThirdPartyLiable() {
      return this.$store.getters.wasAnyThirdPartyLiable
    },
    getThirdParty() {
      return this.$store.getters.thirdPartyDetails
    },
    getWereThereAnyWitness() {
      return this.$store.getters.wereThereAnyWitness
    },
    getWitness() {
      return this.$store.getters.witnessDetails
    },
    getInjury() {
      return this.$store.getters.injuryDetails
    },

    getDocumentDetails() {
      return this.$store.getters.supportDocumentDetails
    },
    getLossOrDamageItem() {
      return this.$store.getters.lossOrDamageItem
    },
    getDefaultSupportDocumentList() {
      return this.$store.getters.supportDocumentOptions.filter(i => i.type === this.$store.state.incidenttype2)
    },
    getDefaultMedicalDocumentList() {
      return this.$store.getters.medicalDocumentOptions.filter(i => i.type === this.$store.state.incidenttype2)
    },
    getOtherInsuranceCovering() {
      return this.$store.getters.otherInsuranceCovering
    },
    getOtherInsuranceCoverage() {
      return this.$store.getters.otherInsuranceCoverage
    },
    getPleaseTickMedicalLeave() {
      return this.$store.getters.pleaseTickMedicalLeave
    },
    getMedicalLeave() {
      return this.$store.getters.medicalLeave
    },
    getTransferDetail() {
      return this.$store.getters.transferDetail
    },

    submitClaim: function (postData) {
      let self = this
      PremiumApi.postClaim(postData, function (data) {
        let result = data.data.submissionid;
        if (result) {
          self.submissionId = result;
          self.$store.dispatch('saveSubmissionId', result)
          self.$router.push('/claims-notification');
        }
        if (data.request.status >= 400) {
          self.showErrorDialog(JSON.parse(data.request.response))
        }
        self.dialog = false;
      });
    },
    showErrorDialog(message) {
      this.errorDialog = true
      this.errorCode = message.code
      this.errorCodeTitle = message.message
      this.errorMessage = message.errordescription ? message.errordescription :this.errorMessage
    },
    validateAndGoNext: function () {
      this.dialog = true;
      setTimeout(() => {
        this.submitClaim(this.postData)
      }, 500);

    },
    checkRule: function (isRequiredField, value) {
      if (isRequiredField && value.length === 0) {
        return ['Required.'];
      }
    },
    initTopProgressStep: function () {
      this.$store.commit('changeStep', 5)
    },
    moment: function (e) {
      return moment();
    },
    toTop() {
      this.$vuetify.goTo(0, {container: document.documentElement})
    },
    onScroll(e) {
      if (typeof window === 'undefined') {
        return
      }
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.is_show_fav_icon = top > 10
    },
    setPostData() {
     try{

       let postData = {};
       postData.declaration = 'Y'
       postData.incidenttype1 = this.getIncidentType ? this.getIncidentType : 'PAIP'
       postData.incidenttype2 = this.getCoverageType
       postData.dateformat = 'dd/mm/yyyy'
       postData.country = this.$store.state.countryCode
       postData.riderdetails = _.cloneDeep(this.$store.state.riderdetails)
       postData.reporterdetails = this.$store.getters.getReporterRelationship === 'PAR' || this.$store.getters.getReporterRelationship === 'SPO'
       || this.$store.getters.getReporterRelationship === 'CHI'? _.cloneDeep(this.$store.state.reporterdetails) : null
       postData.reporterrelationship = this.$store.getters.getReporterRelationship

       postData.riderdetails.country = this.$store.state.countryCode
       if (this.$store.getters.getReporterRelationship === 'PAR' || this.$store.getters.getReporterRelationship === 'SPO'|| this.$store.getters.getReporterRelationship === 'CHI') {
         postData.reporterdetails.country = this.$store.state.countryCode;
       }

       postData.transferdetail = _.cloneDeep(this.$store.state.transferdetail)
       postData.lossordamageitem = this.IsPersonalAccidentCoverageType ? _.cloneDeep(this.$store.state.lossordamageitem) : null

       let code = this.getIncidentDetailsFileCode;

       let photos = this.getIncidentPhotosComputed.map(function (obj) {
         let rObj = {};
         rObj.code = code;
         rObj.filename = obj.name
            try{
              rObj.base64string = obj.base64.split(',')[1];
            }catch (e){
              rObj.base64string = '';
            }

         return rObj
       })
       postData.incidentdetails = JSON.parse(JSON.stringify(this.$store.state.incidentdetails))
       postData.incidentdetails.photos = photos
       postData.wasanythirdpartyliable = JSON.parse(JSON.stringify(this.$store.state.wasanythirdpartyliable))

       postData.thirdpartydetails = 'Y' === postData.wasanythirdpartyliable ? _.cloneDeep(this.$store.state.thirdpartydetails) : null
       if ('Y' === postData.wasanythirdpartyliable){
         postData.thirdpartydetails.country = this.$store.state.countryCode
       }

       postData.werethereanywitness = this.$store.state.werethereanywitness
       postData.witnessdetails = 'Y' === postData.werethereanywitness ? _.cloneDeep(this.$store.state.witnessdetails) : null
       if ('Y' === postData.werethereanywitness){
         postData.witnessdetails.country = this.$store.state.countryCode
       }

       if ('Y' === postData.werethereanywitness) {
         let witnessCode = this.getWitnessFileCode;
         let witnessStatement = this.getStatementProofComputed.map(function (obj) {
           let rObj = {};
           rObj.code = witnessCode;
           rObj.filename = obj.name
           let reader = new FileReader();
           let base64data = ''
           reader.readAsDataURL(obj.file);
           reader.onloadend = function () {
             base64data = reader.result;
             try{
               rObj.base64string = obj.base64.split(',')[1];
             }catch (e){
               rObj.base64string = '';
             }
           }
           return rObj
         })
         postData.witnessdetails.statementproof = witnessStatement;
       }

       postData.injurydetails = this.IsAMERCoverageType || this.IsPersonalDisabilityCoverageType ? JSON.parse(JSON.stringify(this.$store.state.injurydetails)) : null
       postData.supportingdocuments = JSON.parse(JSON.stringify(this.$store.state.supportingdocuments))

       if ('' === postData.supportingdocuments.totalclaimamount) {
         postData.supportingdocuments.totalclaimamount = 0;
       } else {
         postData.supportingdocuments.totalclaimamount = parseFloat(postData.supportingdocuments.totalclaimamount, 10);
       }

       let attachments = this.getSupportDocumentsAttachments.reduce((acc, item) => {
         if (typeof item.documents != 'undefined' && item.documents !== undefined && item.documents.length !== 0) {
           let code = item.code
           for (var i = 0, len = item.documents.length; i < len; i++) {
             let rObj = {};
             rObj.code = code;
             rObj.filename = item.documents[i].name
             rObj.base64string = item.documents[i].base64.split(',')[1];
             try{
               rObj.base64string = item.documents[i].base64.split(',')[1];
             }catch (e){
               rObj.base64string = '';
             }
             acc.push(rObj)
           }
         }
         return acc
       }, []);
       postData.supportingdocuments.attachments = attachments;
       postData.otherinsurancecovering = _.cloneDeep(this.$store.state.otherinsurancecovering)
       postData.otherinsurancecoverage = postData.otherinsurancecovering === 'Y' ? _.cloneDeep(this.$store.state.otherinsurancecoverage) : null
       if (postData.otherinsurancecoverage) {
         postData.otherinsurancecoverage.benefitamount = parseFloat(postData.otherinsurancecoverage.benefitamount, 10);
       }

       postData.pleasetickmedicalleave = this.IsAMERCoverageType ? _.cloneDeep(this.$store.state.pleasetickmedicalleave) : null
       postData.medicalleave = 'Y' === postData.pleasetickmedicalleave ? _.cloneDeep(this.$store.state.medicalleave) : null

       if ('Y' === postData.pleasetickmedicalleave) {

         let medicalDocuments = this.getMedicalDocuments.reduce((acc, item) => {
           if (typeof item.medicaldocuments != 'undefined' && item.medicaldocuments !== undefined && item.medicaldocuments.length !== 0) {
             let code = item.code
             for (var i = 0, len = item.medicaldocuments.length; i < len; i++) {
               let rObj = {};
               rObj.code = code;
               rObj.filename = item.medicaldocuments[i].name
               try{
                 rObj.base64string = item.medicaldocuments[i].base64.split(',')[1];
               }catch (e){
                 rObj.base64string=''
               }
               acc.push(rObj)
             }
           }
           return acc
         }, []);
         postData.medicalleave.medicaldocuments = medicalDocuments
       }

       this.postData = postData
     }catch (e){
       PremiumApi.postClaim(e.stack, function (data) {
       });

     }
    },
    alert(message) {
      alert(message)
    },
    deleteItem(list, item) {
      list.splice(list.findIndex(e => e.id === item.id), 1);
    },
    modify(element) {
      this.$router.push(element);
    },
    initPageTitle: function () {
      if (this.$route.query.modify) {
        this.$store.commit('changeMenuTitle', 'Modify Claim')
      } else {
        this.$store.commit('changeMenuTitle', 'Make a Claim')
      }

    },
    goPrevious() {
      this.$router.back()
    }
  },
  filters: {
    moment: function (date) {
      var result = moment(date).format('DD/MM/YY')
      if (result === 'Invalid date') {
        return ''
      } else {
        return moment(date).format('DD/MM/YY')
      }
    },
  },
  mounted() {
    this.initTopProgressStep()
    this.initPageTitle()
    this.setPostData()
    this.$vuetify.goTo(0, {container: document.documentElement,duration:0})
  },
  created() {
    if (this.documentInfo.attachments.length > 0) {
      this.supportDocumentAttachments = JSON.parse(JSON.stringify(this.documentInfo.attachments))
    } else {
      this.supportDocumentAttachments = JSON.parse(JSON.stringify(this.getDefaultSupportDocumentList()))
    }

    if (this.medicalLeaveInfo.medicaldocuments.length > 0) {
      this.medicalDocumentAttachments = JSON.parse(JSON.stringify(this.medicalLeave.medicaldocuments))
    } else {
      this.medicalDocumentAttachments = JSON.parse(JSON.stringify(this.getDefaultMedicalDocumentList()))
    }
  }


}
</script>
<style scoped>
.fileItemHolder {
  position: relative;
  text-align: center;
}

.file-remove-icon {
  position: absolute;
  top: 25px;
  margin-left: -25px;
  z-index: 1;
  text-align: center;
  background: #000000 no-repeat padding-box;
  zoom: 1;
  filter: alpha(opacity=50);
  opacity: 0.45;
  border-radius: 50%;
  padding: 4px;
}

* {
  text-transform: none !important;
}

.add-photo-button {
  display: flex !important;
}

.file-text-span {
  white-space: nowrap;
  min-width: 140px
}

.hide-element {
  visibility: hidden;
}

li div {
  position: relative;
  display: block;
  line-height: 27px;
  padding-right: 80px;
  color: #333;
}

li div span {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

li div img {
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 3px
}

.orderIcon {
  background: #00CCBC;
  border-radius: 3px;
  width: 26px;
  height: 26px;
  opacity: 1;
  text-align: center;
  color: #FFFFFF;
}

.blueIcon {
  background: #006D9E !important;
  border-radius: 3px;
  width: 26px;
  height: 26px;
  opacity: 1;
  text-align: center;
  color: #FFFFFF;
  padding: 5px;
}

.deleteButton {
  width: 20px;
  height: 20px;
  background-color: #FFFFFF;
  padding: 3px;
  vertical-align: middle;
}

.section-title {
  color: #006D9E;
  border-bottom: #006D9E 2px solid;
}

.section-content {
  color: #00A8C8;
}

.mobile-divider {
  height: 9px;
  background: #F1F1F1 0% 0% no-repeat padding-box;
  border: 1px solid #E1E1E1;
  opacity: 1;
}

.menu-divider-top {
  border-top: #47494E 1px solid;
  padding-top: 20px;
}

ul {
  list-style: disc;
}

.expanded-area-border {
  border-bottom: #47494E 1px solid;
  border-top: #47494E 1px solid;
  box-shadow: 0 0 0 1px black;
}

.expanded-area-border + div + .expanded-area-border {
  /*border-bottom: none;*/
}

div {
  word-break: keep-all;
}

content-item {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 2px 3px 3px #0000001A;
  border: 1px solid #E1E1E1;
  border-radius: 5px;
  opacity: 1;
}

.action-button {
  text-transform: none !important;
  font-size: 20px;
}

.section-title {
  color: #37424A;
  font-size: 24px;
}
</style>

