<template>
  <div class="my-body"  >
    <section class="banner-top hidden-xs">
      <div class="container">
        <div class="row">
          <div class="col-xs-6 banner-left">
            <br>
            <br>
            <h1 :lang="getLang" v-html="$t('lang.faq.topBanner')">
            </h1>
          </div>

          <div class="col-xs-6 banner-right faq-banner">

          </div>
        </div>
      </div>
    </section>

    <section class="banner-top-mobile hidden-lg hidden-sm hidden-md">
      <div class="">
        <div class="">
          <div class="bann-top-mobile-top faq-bann">

          </div>
          <div class="bann-bottom-mobile-bt col-md-12">
            <h1 :lang="getLang" v-html="$t('lang.faq.topBanner_M')">
            </h1>
          </div>
        </div>
      </div>
    </section>
    <!-- for mobile -->

    <section class="faq-section">
      <div class="container">
        <ul class="hidden-lg hidden-md hidden-sm r-inci-mobile">
          <li ><router-link :to="'/claims-process'" class="uppercase" :lang="getLang">{{$t('lang.common.reportAnIncident')}}</router-link></li>
        </ul>
        <div class="row">
          <div class="col-md-3 col-sm-7 col-faq-3">
            <h3 :lang="getLang" class="hidden-sm hidden-xs">{{$t('lang.faq.getHelpWith')}}</h3>
            <h2 :lang="getLang" class="hidden-lg hidden-md hidden-sm col-xs-12 text-center heading m-10">{{$t('lang.faq.frequentlyAsked')}}</h2>
            <hr class="hidden-sm hidden-xs">
            <ul class="hidden-xs">
              <li :lang="getLang" class="active" :class="{boldText: getLang == 'zh'}">{{$t('lang.faq.faqs')}}</li>
            </ul>
          </div>

          <div class="col-sm-5 hidden-lg hidden-md">
            <div id="imaginary_container" class="pull-right mt-20">

              <v-text-field
            outlined
            solo
            flat
            label="Search"
             v-model="term"
            prepend-inner-icon="fa-search"
             :lang="getLang" :placeholder="$t('lang.faq.search')"
             v-on:keyup.enter="$event.target.blur()"
          ></v-text-field>



            </div>
          </div>

          <div class="col-md-9 col-sm-12 col-nine">
            <div class="">
                <h2 :lang="getLang" class="pull-left hidden-xs hidden-sm">
                  {{$t('lang.faq.frequentlyAsked')}}
                </h2>
                
                <div id="imaginary_container" class="pull-right hidden-sm hidden-xs">
                    <div class="input-group stylish-input-group">
                           <v-text-field
            outlined
            solo
            flat
            label="Search"
             v-model="term"
            prepend-inner-icon="fa-search"
             :lang="getLang" :placeholder="$t('lang.faq.search')"
               v-on:keyup.enter="$event.target.blur()"
          ></v-text-field>

                    </div>                   
                </div>
                <div :lang="getLang" class="bannerText col-md-12 col-sm-12" style="margin: -15px 0 -15px -5px;">
                      {{$t('lang.faq.substituteRiderWarningBannerSG')}}
                </div>
            </div>
            <div class="clearfix"></div>
            <div class="accordion-faq">
              <vue-faq-accordion :lang="getLang" :items="faqItems" borderColor="#FFFFFFFF" activeColor="#006d9e" fontColor="#006d9e">
              </vue-faq-accordion>

            </div>
            <div :lang="getLang" v-html="$t('lang.faq.reminder')" style="margin-bottom: 20px" class="px-6">
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="footer-section hidden-lg">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-sm-7" :lang="getLang" v-html="$t('lang.common.arrangedInPartnership')">
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import VueFaqAccordion from 'vue-faq-accordion'

export default {
  components: {
    VueFaqAccordion
  },
  data () {
    return {
      term: ''

    }
  },
  props: [
  ],
  methods: {
  },
  computed: {
    faqItems () {
      var terms = this.term
      if (terms !== '') {
        return this.$t('lang.faq.faqQuestion').filter(function (item) {
          return item.title.indexOf(terms) > -1 || item.value.indexOf(terms) > -1
        })
      } else {
        return this.$t('lang.faq.faqQuestion')
      }
    },
    getLang () {
      return this.$store.state.selectedLanguageCode === 'hk' || this.$store.state.selectedLanguageCode === 'tw' ? 'zh' : 'en'
    }
  },
  mounted(){
    this.$vuetify.goTo(0, {container: document.documentElement,duration:0})
  }
}
</script>

<style>
</style>
